import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.scss';
import {Translate, Translator} from 'react-translated';
import Global from "../../Global";
import UserService from "../../UserService";
import PubSub from "pubsub-js";
import Api from "../../Api";
import ReactTooltip from "react-tooltip";
import Icon from "../../global/Icon/Icon";
import Button from "../../global/Button/Button";

export default class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            popupVisible: false,
            x: 0,
        };
        this.handlePopUpClick = this.handlePopUpClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.loadData()
    }

    loadData=()=> {
        this.getMe = PubSub.subscribe('update.me', (msg, link) => {
            Api.apiCall("get","getMe", {})
                .then(resp=>{
                    if (resp.status !== 200) {
                        // console.log(resp.status);
                    }
                    if ('error' in resp.data) {
                        // console.log(resp.data.error);
                    }else {
                        Global.me = resp.data;
                        this.forceUpdate();
                    }
                });
        });
    };

    handlePopUpClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        this.handlePopUpClick();
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        PubSub.unsubscribe(this.getMe);
    }

    logOut=()=>{
        UserService.logOut();
    };

    render() {
        return (
            <header>
                <div className="row align-justify align-middle">
                    <div className="column medium-flex-child-shrink">
                        {UserService.isAuth()?
                            <Link to={"/"} id="logo">
                                <img src={require('../../../assets/img/logo.svg')} alt="Rendline"/>
                            </Link>
                            :
                            <a href="https://rendline.com/" id="logo">
                                <img src={require('../../../assets/img/logo.svg')} alt="Rendline"/>
                            </a>
                        }
                    </div>
                    <div className="column medium-flex-child-shrink">
                        <div className="header-right flex-container align-middle align-right">
                            {/*<Link to="/choose-plan" className="header-link">*/}
                            {/*    <i>*/}
                            {/*        <img src={require("../../../assets/img/br-icon.svg")} alt=""/>*/}
                            {/*    </i>*/}
                            {/*    <span>*/}
                            {/*        <Translate text="Pricing" />*/}
                            {/*    </span>*/}
                            {/*</Link>*/}
                            {UserService.isAuth()?
                                <Fragment>
                                    {Global.me.balance>0?
                                        <div className="gift flex-container align-middle">
                                            <div className="gift-icon">
                                                <img src={require("../../../assets/img/gift.svg")} alt=""/>
                                            </div>
                                            <div className="gift-info">
                                                <div className="gift-title">
                                                    <span>
                                                        <Translate text="my bonus gift" />
                                                    </span>
                                                    <Translator>
                                                        {({ translate }) => (
                                                            <a  data-for='set-mat-3'
                                                                className="tooltip-wrap"
                                                                data-tip={translate({
                                                                    text: 'You have received a $100 gift card, which you can use by buying photo rendering packages. With each purchase you can use 10% of the chosen package price from the gift card. The gift card can be used within 60 days. ',
                                                                })}
                                                            >
                                                                <Icon type="help"/>
                                                            </a>
                                                        )}
                                                    </Translator>
                                                    <ReactTooltip globalEventOff='click' place="right" id='set-mat-3'/>
                                                </div>
                                                <div className="gift-percent">
                                                    ${Global.me.balance}
                                                    <span>
                                                        / 100$
                                                    </span>
                                                </div>
                                                <div className="gift-progress">
                                                    <div className="gift-progress-in" style={{width: Global.me.balance+'%'}}/>
                                                </div>
                                            </div>
                                        </div>:null
                                    }

                                    <div className="balance">
                                        <Link to="/choose-plan">
                                            <div className="balance-title">
                                                <Translate text="my balance" />
                                            </div>
                                            <div className="balance-items">
                                                <div className="balance-item">
                                                    <i>
                                                        <img src={require("../../../assets/img/photo-icon.svg")} alt=""/>
                                                    </i>
                                                    <span>
                                                    {Global.me.render_count}
                                                </span>
                                                </div>
                                                <div className="balance-item">
                                                    <i>
                                                        <img src={require("../../../assets/img/video-icon.svg")} alt=""/>
                                                    </i>
                                                    <span>
                                                        {Global.me.video_count}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Fragment>
                                :null
                            }
                            <a href="https://rendline.com/en/help" target="_blank" className="header-link" rel="noopener noreferrer">
                                <Icon type="learn"/>
                                <span>
                                    <Translate text="help" />
                                </span>
                            </a>
                            {!UserService.isAuth()?
                                <div className="btn-group">
                                    <Button type="button"
                                            theme="none-white"
                                            title="log in"
                                            link="/"
                                    />
                                    <Button type="button"
                                            theme="green"
                                            rounded={true}
                                            title="sign up"
                                            link="/signup"
                                    />
                                </div>
                            :
                                <div className="account-dropdown">
                                    <div className="account-drop"  onClick={this.handlePopUpClick}>
                                        <div className="account-name">
                                            {Global.me.name}
                                        </div>
                                        <Icon type="arrow-bottom"/>
                                    </div>
                                    {this.state.popupVisible && (
                                        <div  className="main-dropdown">
                                            <NavLink to="/my-projects" className="popupLink" activeClassName="active">
                                                <Icon type="files"/>
                                                <span>
                                                    <Translate text="my projects" />
                                                </span>
                                            </NavLink>
                                            <NavLink to="/invoices" className="popupLink" activeClassName="active">
                                                <i>
                                                    <img src={require("../../../assets/img/receipt.svg")} alt=""/>
                                                </i>
                                                <span>
                                                    <Translate text="invoices" />
                                                </span>
                                            </NavLink>
                                            <NavLink to="/profile/personal-information" className="popupLink" activeClassName="active">
                                                <Icon type="settings"/>
                                                <span>
                                                    <Translate text="settings" />
                                                </span>
                                            </NavLink>
                                            <a className="popupLink" onClick={this.logOut}>
                                                <Icon type="logout"/>
                                                <span>
                                                    <Translate text="logout" />
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}