import React, { Component } from 'react';
import './ChoosePlan.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Api from "../Api";
import { Translate } from 'react-translated';
import Global from "../Global";
import Loading from "../global/Loading/Loading";
import Button from "../global/Button/Button";

export default class ChoosePlan extends Component {
    constructor(props){
        super(props);
        this.state={
            plans:[],
            loading:false,
            tabIndex: 0
        };
        this.loadData();
    }

    specialUserPlans = {
        199: [{
            planId: 9,
            price: 30,
            name: "1 Model",
        }],
        208: [{
            planId: 30,
            price: 250,
            name: "5 Model",
        }],
        188: [{
            planId: 11,
            price: 86,
            name: "1 Model",
        }],
        189: [{
            planId: 31,
            price: 100,
            name: "Modeling",
        },{
            planId: 27,
            price: 20,
            name: "Sketch",
        }],
        318: [{
            planId: 15,
            price: 100,
            name: "36 Rendering 4 video",
        }],
        300: [{
            planId: 21,
            price: 33,
            name: "11 Renderings",
        }],
        317: [{
            planId: 18,
            price: 576,
            name: "192 Rendering",
        }],
        2: [{
            planId: 18,
            price: 576,
            name: "192 Rendering",
        }],
        326: [{
            planId: 22,
            price: 100,
            name: "4 RendlineXR",
        }],
		363: [{
            planId: 23,
            price: 460,
            name: "Special",
        }],
        368: [{
            planId: 25,
            price: 25,
            name: "Special Rendering",
        }],
        386: [{
            planId: 28,
            price: 365,
            name: "Special Plan",
        }],
        421: [{
            planId: 29,
            price: 40,
            name: "Special Plan",
        }],
    };

    loadData=()=>{
        Api.apiCall("get","getPlans",{}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
                let plans = [];
                let type=0;
                let index=-1;
                resp.data.forEach((item)=>{
                    if(type !== item.type){
                        type = item.type;
                        index++;
                        plans[index]={
                            data:[],
                        };
                    }
                    plans[index].data.push(item);
                });
               //alert(JSON.stringify(this.specialUserPlans));
                this.setState({
                    plans,
                    loading:true,
                    tabIndex: Object.keys(this.specialUserPlans).map(it => Number(it)).indexOf(Global.me.id) !== -1 ? 2 : 0,
                })
            }
        })
    };

    tabsChange =(tabIndex)=>{
        //alert(tabIndex)
        this.setState({
            tabIndex
        })
    };



    render(){
        const specialUserPlans = this.specialUserPlans;
        const specialUserIds = Object.keys(specialUserPlans).map(it => Number(it));
        return(
            <div className="row">
                <div className="column small-12 text-center">
                    <h1 className="page-title-center">
                        <Translate text="Choose a plan that works for you" />
                    </h1>
                    {/*<div className="pricing-description">*/}
                        {/*Receive your Rendline present! Buy a package from December 24-January 4 and get <b>10 more renderings.</b>*/}
                    {/*</div>*/}
                    {!this.state.loading?
                        <Loading/>:
                        <div className="plan-tabs">
                            <Tabs selectedIndex={this.state.tabIndex}  onSelect={this.tabsChange}>
                                <TabList>
                                    {
                                        this.state.plans.map((item,key)=>
                                            <Tab key={key}>
                                                {
                                                    key === 0?
                                                        <span>
                                                            <Translate text="photo renderings" />
                                                        </span>
                                                        :key === 1?
                                                        <span>
                                                            <Translate text="video renderings" />
                                                        </span>
                                                        :key === 2?
                                                            <span>
                                                                <Translate text="combo packs" />
                                                            </span>
                                                            :null
                                                }
                                            </Tab>
                                        )
                                    }

                                    {specialUserIds.indexOf(Global.me.id) !== -1 ?
                                        <Tab key={3}>
                                            <span>
                                                <Translate text="Special" />
                                            </span>
                                        </Tab>
                                    : null}
                                </TabList>
                            {
                                this.state.plans.map((item,keyx)=>
                                    <TabPanel key={keyx}>
                                        <div className="row">
                                            {
                                                item.data.map((itemx,key)=>
                                                    <div key={key} className="column small-3">
                                                        <div className="plan-block">
                                                            <div className="plan-icon">
                                                                {
                                                                    key === 0?
                                                                        <img src={require("../../assets/img/plan-mini.png")} alt=""/>
                                                                    :key === 1?
                                                                        <img src={require("../../assets/img/plan-basic.png")} alt=""/>
                                                                    :key === 2?
                                                                        <img src={require("../../assets/img/plan-pro.png")} alt=""/>
                                                                    :key === 3?
                                                                        <img src={require("../../assets/img/plan-proplus.png")} alt=""/>
                                                                    :null
                                                                }
                                                            </div>

                                                            <div className="plan-block-main">
                                                                <div className="plan-block-title">
                                                                    <Translate text={itemx.name} />
                                                                </div>
                                                                <div className="plan-block-desc">
                                                                    {itemx.desc}
                                                                </div>
                                                                <div className="plan-block-info">
                                                                    {(keyx === 0?
                                                                        (
                                                                            <span>
                                                                        {itemx.images +" "}<Translate text="renderings" />
                                                                      </span>)
                                                                        :keyx === 1?(
                                                                                <span>
                                                                        {itemx.videos +" "}<Translate text="videos" />
                                                                      </span>)
                                                                            :null)}
                                                                </div>
                                                                <div className="plan-block-price">
                                                                    <sup>
                                                                        $
                                                                    </sup>
                                                                    {itemx.price}
                                                                </div>
                                                                {/*<div className="saled-plan">*/}
                                                                {/*/!*${itemx.price/0.8}*!/*/}
                                                                {/*+10 free renderings*/}
                                                                {/*</div>*/}

                                                                <Button type="button"
                                                                        theme={key === 0 || key === 1?"white-back":key === 2?"primary-back":key === 3?"purple-back":""}
                                                                        rounded={true}
                                                                        title="choose plan"
                                                                        link={"billing-plan/"+itemx.id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </TabPanel>
                                )
                            }
                            {specialUserIds.indexOf(Global.me.id) !== -1 ? (
                                <TabPanel key={4}>
                                    <div className="row">
                                        {specialUserPlans[Global.me.id].map((item,key)=>
                                            <div  className="column small-3">
                                                <div className="plan-block">
                                                    <div className="plan-icon">
                                                        <img src={require("../../assets/img/plan-mini.png")} alt=""/>
                                                    </div>
                                                    <div className="plan-block-main">
                                                        <div className="plan-block-title">
                                                            Special Plan
                                                        </div>
                                                        <div className="plan-block-desc">

                                                        </div>
                                                        <div className="plan-block-info">
                                                            {item.name}
                                                        </div>
                                                        <div className="plan-block-price">
                                                            <sup>
                                                                $
                                                            </sup>
                                                            {item.price}
                                                        </div>
                                                        <Button type="button"
                                                                theme="white-back"
                                                                rounded={true}
                                                                title="choose plan"
                                                                link={"billing-plan/" + item.planId}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                            ) : null}
                        </Tabs>
                    </div>
                    }
                </div>
            </div>
        )
    }
}