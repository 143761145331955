import React, { Component } from 'react';
import axios from 'axios';
import UserService from './UserService';
import PubSub from 'pubsub-js';
class Api extends Component {
   // static apiUrl ="https://api.rendline.com/api/";
    //static apiUrl ="http://localhost/Rendline/public/api/";
	static apiUrl = "http://rend.mylibstudio.com/api/";
    static apiCall(method, callName, params = {},callback=()=>{}) {

        var apiUrl = this.apiUrl+ callName;
        var response; 
        var config={};
        if (UserService.isAuth()){
            if(callName === "downloadFile"){
                config={headers:{'Authorization': 'Bearer '+UserService.token,'Content-Type': 'multipart/form-data'},responseType: 'blob'};
            } else{
                config={headers:{'Authorization': 'Bearer '+UserService.token,'Content-Type': 'multipart/form-data'}};
            }

        }

        switch (method) {
            case 'get':
                response = axios.get(apiUrl, {
                    params: params,
                    headers:{'Authorization': 'Bearer '+UserService.token}
                });
                break;
            case 'post':
                if (callName !== "upload" && callName !== "requestModel"){
                    var data = new FormData();
                    for (var key in params) {
                        if (params.hasOwnProperty(key)) {
                            data.append(key, params[key]);
                        }
                    }
                } else{
                    var data = params;
                    config.onUploadProgress=(progressEvent)=>{
                        PubSub.publish("progress",progressEvent);
                    }
                }
                response = axios.post(apiUrl, data, config);
                break;
            case 'put':
                config.onUploadProgress=(progressEvent)=>{
                    // console.log(progressEvent.loaded+" / "+progressEvent.total)
                    callback(progressEvent);
                };
                var data = params;
                response = axios.put(apiUrl, data, config);
                break;
        }
        return response;
    }
}

export default Api;