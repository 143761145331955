import Api from "./Api";
import Global from "./Global";
import PubSub from "pubsub-js";
//import {setCookie} from "../helpers/cookie";
export default class UserService {

    static id = null;
    static token = null;

    static auth(id=null, token=null) {
        if (id && token) {
            this.id = id*1;
            this.token = token;

            localStorage.setItem('user', JSON.stringify({ id:id, token:token }));
            document.cookie = 'auth_token=' + token;

            return Api.apiCall("get","getMe", {})
                .then(resp=>{
                    if (resp.status !== 200) {
                        // console.log(resp.status);
                    }
                    if ('error' in resp.data) {
                        // console.log(resp.data.error);
                    }else {
                        Global.me = resp.data;
                    }
                    return resp;
                });
        }else {
            let cookieUser = JSON.parse(localStorage.getItem('user')) || {};
            if (cookieUser.id && cookieUser.token) {
                this.id = cookieUser.id*1;
                this.token = cookieUser.token;

                localStorage.setItem('user', JSON.stringify(cookieUser));
                document.cookie = 'auth_token=' + cookieUser.token;

                return Api.apiCall("get","getMe", {})
                    .then(resp=>{
                        if (resp.status !== 200) {
                            // console.log(resp.status);
                        }
                        if ('error' in resp.data) {
                            // console.log(resp.data.error);
                        }else {
                            Global.me = resp.data;
                            // console.log(Global.me);
                        }
                        return resp;
                    });
            }
            return new Promise((resolve)=>{return resolve(null);});
        }
    }

    static logOut() {
        localStorage.removeItem('user');
        this.id = null;
        this.token = null;
        Global.me = {};
        PubSub.publish("redirect.to","/");
    }
    static logOut_red() {
        localStorage.removeItem('user');
        this.id = null;
        this.token = null;
        Global.me = {};
    }

    static getToken(email=null, password=null) {
        return Api.apiCall("post","login", {email, password})
            .then(resp=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    // console.log(resp.data.error);
                }else {
                    return this.auth(resp.data.success, resp.data.token).then(()=>{
                        return {data:{success:true}};
                    });
                }
                return resp;
            });
    }

    static isAuth() {
        return (this.id && this.token) ? true : false;
    }

};