import React, { PureComponent } from 'react';
import './Unsigned.scss';
import { Switch, Route } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

export default class Unsigned extends PureComponent {
    render() {
        return (
            <Switch>
                <Route path={this.props.match.url+'login'} component={SignIn}/>
                <Route path={this.props.match.url+'signup/:email'} component={SignUp}/>
                <Route path={this.props.match.url+'signup'} component={SignUp}/>

                <Route path={this.props.match.url+'forgot-password/:token'} component={ForgotPassword}/>
                <Route path={this.props.match.url+'forgot-password'} component={ForgotPassword}/>
                <Route component={SignIn}/>
            </Switch>
        )
    }
}
