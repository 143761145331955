import React, { Component } from 'react';
import Api from "../Api";
import PubSub from 'pubsub-js';
import Global from '../Global';
import { Translate } from 'react-translated';
import Button from "../global/Button/Button";
import Field from "../global/Field/Field";

export default class ForgotPassword extends Component {
    constructor (props) {
    super(props);
        this.state = {
            email: '',
            password: '',
            password2: '',
            formErrors: {email: '',password: '',password2: ''},
            emailValid: false,
            passwordValid:false,
            password2Valid:false,
            formValid: false
        }
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, 
        () => { this.validateField(name, value) });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
        let password2Valid = this.state.password2Valid;

        switch(fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is invalid';
                break;
            case 'password':
                passwordValid = value.length >= 6 && value === this.state.password2;
                password2Valid = passwordValid;
                fieldValidationErrors.password = passwordValid ? '': value.length >= 6?'password not match':'is too short';
                fieldValidationErrors.password2 = password2Valid ? '': this.state.password2.length >= 6?'password not match':' is too short';
                break;
            case 'password2':
                password2Valid = value.length >= 6 && value === this.state.password;
                passwordValid = password2Valid;
                fieldValidationErrors.password2 = password2Valid ? '': value.length >= 6?'password not match':'is too short';
                fieldValidationErrors.password = passwordValid ? '': this.state.password.length >= 6?'password not match':'is too short';
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            password2Valid,
            passwordValid
        }, this.validateForm);
    }
      
    validateForm() {
        if (this.props.match.params.token){
            this.setState({
                formValid: this.state.password && this.state.password2,
            });
        } else{
            this.setState({
                formValid: this.state.emailValid
            });
        }

    }

    submit=(e)=>{
        e.preventDefault();
        if (this.props.match.params.token){
            Api.apiCall("post","newPass",{password_token:this.props.match.params.token,password:this.state.password}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    Global.successMessage = 'yourPasswordSuccessfullyChanged';
                    PubSub.publish("redirect.to","/success-message");
                }
            })
        } else{
            Api.apiCall("post","forgotPass",{email:this.state.email}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    Global.successMessage = 'yourPasswordRecoveryLinkHasBeen';
                    PubSub.publish("redirect.to","/success-message");
                }
            })
        }

    };

    render() {
        if (this.props.match.params.token){
            return (
                <div className="unsigned text-center">
                    <form className="unsigned-form" onSubmit={this.submit}>
                        <div className="unsigned-title">
                            <Translate text="New password" />
                        </div>

                        <Field label="New password"
                               type="password"
                               placeholder="Enter new password"
                               required={true}
                               value={this.state.password}
                               name="password"
                               onChange={(event) => this.handleUserInput(event)}
                               errorMsg={this.state.formErrors.password}
                               onEnter={this.submit}
                        />
                        <Field label="Confirm password"
                               type="password"
                               placeholder="Confirm password"
                               required={true}
                               value={this.state.password2}
                               name="password2"
                               onChange={(event) => this.handleUserInput(event)}
                               errorMsg={this.state.formErrors.password2}
                               onEnter={this.submit}
                        />
                        <Button type="submit"
                                theme="green"
                                title="submit"
                                disabled={!this.state.formValid}
                        />
                    </form>
                    <div className="unsigned-info">
                        <Button type="submit"
                                theme="dark"
                                iconLeft="arrow-left"
                                title="back"
                                link="/"
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="unsigned text-center">
                    <form className="unsigned-form" onSubmit={this.submit}>
                        <div className="unsigned-title">
                            <Translate text="forgot password" />
                        </div>
                        <Field label="E-mail"
                               type="mail"
                               placeholder="Enter your Email"
                               value={this.state.email}
                               name="email"
                               required={true}
                               onChange={(event) => this.handleUserInput(event)}
                               errorMsg={this.state.formErrors.email}
                        />
                        <Button type="submit"
                                theme="green"
                                title="submit"
                                responsive={true}
                                //disabled={!this.state.formValid}
                        />
                    </form>
                    <div className="unsigned-info">
                        <Button type="button"
                                theme="dark"
                                title="back"
                                iconLeft="arrow-left"
                                link="/"
                        />
                    </div>
                </div>
            )
        }
    }
}
