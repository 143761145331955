import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './ViewsCollection.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Global from '../../Global';
import PubSub from 'pubsub-js';
import { Translate } from 'react-translated';
import Icon from "../../global/Icon/Icon";
import Button from "../../global/Button/Button";
import Field from "../../global/Field/Field";

export default class ViewsCollection extends Component {
    constructor(props){
        super(props);
        let customViews = JSON.parse(JSON.stringify(Global.styleBoxes[this.props.styleBox].customViews)) || [];
        let views=[
            "RL_Back",
            "RL_Front",
            "RL_Left",
            "RL_Right",
            "RL_Top",
            "V1",
            "V1-1",
            "V1-2",
            "V1-3",
            "V1-4",
            "V1-5",
            "V2",
            "V2-1",
            "V2-2",
            "V2-3",
            "V2-4",
            "V2-5",
            "V3",
            "V3-1",
            "V3-2",
            "V3-3",
            "V3-4",
            "V3-5",
            "V4",
            "V4-1",
            "V4-2",
            "V4-3",
            "V4-4",
            "V4-5",
            "V5",
            "V5-1",
            "V5-2",
            "V5-3",
            "V5-4",
            "V5-5",
            "V6",
            "V6-1",
            "V6-2",
            "V6-3",
            "V6-4",
            "V7",
            "V7-1",
            "V7-2",
            "V7-3",
            "V7-4",
        ];
        if(Global.me.id === 178){
            views=[
                "Gemvy_1",
                "Gemvy_2",
                "Gemvy_3",
                "Gemvy_4",
                "Gemvy_5",
                "Gemvy_6",
                "Gemvy_7",
                "RL_Back",
                "RL_Front",
                "RL_Left",
                "RL_Right",
                "RL_Top",
                "V1",
                "V1-1",
                "V1-2",
                "V1-3",
                "V1-4",
                "V1-5",
                "V2",
                "V2-1",
                "V2-2",
                "V2-3",
                "V2-4",
                "V2-5",
                "V3",
                "V3-1",
                "V3-2",
                "V3-3",
                "V3-4",
                "V3-5",
                "V4",
                "V4-1",
                "V4-2",
                "V4-3",
                "V4-4",
                "V4-5",
                "V5",
                "V5-1",
                "V5-2",
                "V5-3",
                "V5-4",
                "V5-5",
                "V6",
                "V6-1",
                "V6-2",
                "V6-3",
                "V6-4",
                "V7",
                "V7-1",
                "V7-2",
                "V7-3",
                "V7-4",
            ];
        }
        if(Global.me.id === 189){
            views=[
                "Beldiamond_1",
                "Beldiamond_2",
                "Beldiamond_3",
                "Beldiamond_4",
                "Beldiamond_5",
                "Beldiamond_6",
                "Beldiamond_7",
                "RL_Back",
                "RL_Front",
                "RL_Left",
                "RL_Right",
                "RL_Top",
                "V1",
                "V1-1",
                "V1-2",
                "V1-3",
                "V1-4",
                "V1-5",
                "V2",
                "V2-1",
                "V2-2",
                "V2-3",
                "V2-4",
                "V2-5",
                "V3",
                "V3-1",
                "V3-2",
                "V3-3",
                "V3-4",
                "V3-5",
                "V4",
                "V4-1",
                "V4-2",
                "V4-3",
                "V4-4",
                "V4-5",
                "V5",
                "V5-1",
                "V5-2",
                "V5-3",
                "V5-4",
                "V5-5",
                "V6",
                "V6-1",
                "V6-2",
                "V6-3",
                "V6-4",
                "V7",
                "V7-1",
                "V7-2",
                "V7-3",
                "V7-4",
            ];
        }
        if(Global.me.id === 208){
            views=[
                "Droesser_Turned_Top",
                "Droesser_Front",
                "Droesser_Laying",
                "RL_Back",
                "RL_Front",
                "RL_Left",
                "RL_Right",
                "RL_Top",
                "V1",
                "V1-1",
                "V1-2",
                "V1-3",
                "V1-4",
                "V1-5",
                "V2",
                "V2-1",
                "V2-2",
                "V2-3",
                "V2-4",
                "V2-5",
                "V3",
                "V3-1",
                "V3-2",
                "V3-3",
                "V3-4",
                "V3-5",
                "V4",
                "V4-1",
                "V4-2",
                "V4-3",
                "V4-4",
                "V4-5",
                "V5",
                "V5-1",
                "V5-2",
                "V5-3",
                "V5-4",
                "V5-5",
                "V6",
                "V6-1",
                "V6-2",
                "V6-3",
                "V6-4",
                "V7",
                "V7-1",
                "V7-2",
                "V7-3",
                "V7-4",
            ];
        }
        this.state={
            customeName:"",
            views,
            customViews: customViews,
            selectedViews:Global.styleBoxes[this.props.styleBox].views || [],
            selectedCustomViews:Global.styleBoxes[this.props.styleBox].customViews || [],
            preview:"RL_Back",
        }
    }

    handleCheck=(e,type)=>{
        let check = e.target.checked;
        if (check){
            this.state[type].push(e.target.value);
        } else{
            this.state[type].map((item,index)=>{
                if (item === e.target.value){
                    this.state[type].splice(index, 1);
                }
            })
        }
        this.forceUpdate();
    };

    preview=(index)=>{
        this.setState({
            preview:index,
        })
    };

    addCustom=()=>{
        if (this.state.customeName.trim() !== ""){

            let customViews = this.state.customViews;
            customViews.push(this.state.customeName);
            this.setState({
                customViews,
                customName:"",
            })
        }
    };

    save=()=>{
        Global.styleBoxes[this.props.styleBox].views=this.state.selectedViews;
        Global.styleBoxes[this.props.styleBox].customViews=this.state.selectedCustomViews;
        PubSub.publish("image.count");
        this.props.close();
    };

    render() {
        let preview = require("../../../assets/rend/scen_1/folder_file1.3dm/"+this.state.preview+".jpg");
        return (
            <div className="flex-container viewscollection">
                <div className="item left">
                    <div className="views-slider">
                        <div className="viewscollection-title">
                            <Translate text="Views collection" />
                        </div>
                        <div className="viewscollection-desc">
                            <Translate text="Select preferred views" />
                        </div>
                        <div className="view-slider-item">
                            <div className="slider-image">
                                <img src={preview} alt=""/>
                            </div>
                            <div className="slider-item-title">
                                {this.state.preview}
                            </div>
                        </div>
                        <div className="viewscollection-actions flex-container align-justify align-middle">
                            <Button type="button"
                                    theme="dark"
                                    title="cancel"
                                    onClick={this.props.close}
                            />
                            <Button type="button"
                                    theme="purple"
                                    iconLeft="check"
                                    iconLeftSize={13}
                                    title="save new views"
                                    onClick={this.save}
                            />
                        </div>
                    </div>

                </div>
                <div className="item right">
                    <Tabs>
                        <TabList>
                            <Tab>
                                <Translate text="Default views" />
                            </Tab>
                            <Tab>
                                <Translate text="Individual views" />
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className="default_views">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMin="calc(100vh - 160px)"
                                >
                                    {this.state.views.map((i, x) =>
                                        <div key={x} className="select-view">
                                            <div className="check-view-item">
                                                <input onClick={()=>this.preview(i)} onChange={(e)=>this.handleCheck(e,'selectedViews')} checked={this.state.selectedViews.find((el)=>{return el === i;}) !== undefined?"true":""} type="checkbox" value={i} id={i}/>
                                                <label htmlFor={i} className="check-label">
                                                    <Icon type="check"/>
                                                </label>
                                                {false ? (
                                                    <img src={require("../../../assets/img/oo-"+x+".png")} alt=""/>
                                                ):(
                                                    <img src={require("../../../assets/rend/scen_1/folder_file1.3dm/"+i+".jpg")} alt=""/>
                                                )}

                                            </div>
                                            <div className="check-view-title">
                                                {i}
                                            </div>
                                        </div>
                                    )}
                                </Scrollbars>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="flex-container align-middle add-view-control">
                                <Field label="individual view name"
                                       theme={true}
                                       type="text"
                                       name="Untitled"
                                       onChange={(e)=>{this.setState({customeName:e.target.value})}}
                                       onEnter={this.addCustom}
                                />
                                <Button type="button"
                                        theme="green"
                                        iconLeft="plus"
                                        title="add custom views"
                                        onClick={this.addCustom}
                                />
                            </div>
                            <div className="default_views">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMin="calc(100vh - 230px)">

                                    {this.state.customViews.map((item,i)=>
                                        <div key={i} className="select-view">
                                            <div className="check-view-item">
                                                <img className="view-icon" src={require("../../../assets/img/view-icon.jpg")} alt=""/>
                                                <input className="custom-hide-inp" type="checkbox" value={item} onChange={(e)=>this.handleCheck(e, "selectedCustomViews")} checked={this.state.selectedCustomViews.find((el)=>{return el === item;}) !== undefined?"true":""} id={"customView-"+(i+1)}/>
                                                <label htmlFor={"customView-"+(i+1)} className="check-label checkable-custom">
                                                    <Icon type="check"/>
                                                </label>
                                            </div>
                                            <div className="check-view-title">
                                                {item}
                                            </div>
                                        </div>
                                    )}
                                </Scrollbars>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}
