import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Api from '../Api';
import PubSub from 'pubsub-js';
import Global from '../Global';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Translate } from 'react-translated';
import UserService from "../UserService";
import Button from "../global/Button/Button";
import Field from "../global/Field/Field";

export default class SignUp extends Component {
    constructor (props) {
        super(props);
        this.state = {
            password: '',
            name: '',
            formErrors: {email: '',name: '', password: ''},
            emailValid: false,
            passwordValid: false,
            nameValid: false,
            formValid: false,
            loading:'',
            modalIsOpen: false,
            email:this.props.match.params.email,
        };

        if(this.props.match.params.email){
            setTimeout(()=>{
                this.validateField("email",this.props.match.params.email)
            },1)
        }
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
                [name]: value
            },
            () => { this.validateField(name, value) });
    }

    validateEmail=(email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
        let nameValid = this.state.nameValid;

        switch(fieldName) {
            case 'email':
                emailValid = this.validateEmail(value);
                //console.log(emailValid);
                fieldValidationErrors.email = emailValid ? '' : 'is invalid';
                break;
            case 'password':
                passwordValid = value.length >= 6 ;
                fieldValidationErrors.password = passwordValid ? '': 'is too short';
                break;
            case 'name':
                nameValid = value.length >= 1;
                fieldValidationErrors.name = nameValid ? '': 'is required';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            nameValid,
            passwordValid: passwordValid,
        }, this.validateForm);
    }

    validateForm() {
        // console.log(this.state)
        this.setState({formValid: this.state.emailValid && this.state.passwordValid && this.state.nameValid});
    }

    register=(e)=>{
        e.preventDefault();
        this.setState({
            loading:true
        });
        Api.apiCall("post","register", {
            name:this.state.name,
            email:this.state.email,
            password:this.state.password,
        }).then(resp=>{
            this.setState({
                loading:false
            });
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                Global.successMessage = 'thankYouForChoosingRendLine.com!';
                PubSub.publish("redirect.to","/success-message");
            }
        });
    };

    googleSignup = (res)=>{
        Api.apiCall("post","registerSocial", {
            token:res.accessToken,
            type:1,
        }).then(resp=>{
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                UserService.auth(resp.data.success, resp.data.token).then(()=>{
                    PubSub.publish("redirect.to","/");
                });
            }
        });
    };

    facebookSignup=(res)=>{
        Api.apiCall("post","registerSocial", {
            token:res.accessToken,
            type:2,
        }).then(resp=>{
            if ('error' in resp.data) {
                //console.log(resp.data.error)
                PubSub.publish('toast.error',resp.data.error);
            }else {
                UserService.auth(resp.data.success, resp.data.token).then(()=>{
                    PubSub.publish("redirect.to","/");
                });
            }
        });
    };

    render() {
        return (
            <div className="premiumsignup">
                <div className="row">
                    <div className="column small-12 medium-7">
                        <div className="premium-title">
                            <Translate text="Enjoy the fusion of delight, quality and speed with us." />
                        </div>
                        <div className="premium-descrption">
                            <Translate text="Rendline is a unique automated platform for making jewelry rendering online. Feel the pleasure of being professional." />
                        </div>
                        <div className="features">
                            <div className="row">
                                <div className="column small-12 medium-7">
                                    <div className="features-item">
                                        <i className="feature-n"><img src={require("../../assets/img/1.svg")} alt=""/></i>
                                        <i className="feature-i"><img src={require("../../assets/img/select-scene-icon.svg")} alt=""/></i>
                                        <span>
                                            <Translate text="Select a Scene" />
                                        </span>
                                    </div>
                                </div>
                                <div className="column small-12 medium-5">
                                    <div className="features-item">
                                        <i className="feature-n"><img src={require("../../assets/img/2.svg")} alt=""/></i>
                                        <i className="feature-i"><img src={require("../../assets/img/upload-file-icon.svg")} alt=""/></i>
                                        <span>
                                            <Translate text="Upload a File" />
                                        </span>
                                    </div>
                                </div>
                                <div className="column small-12 medium-7">
                                    <div className="features-item">
                                        <i className="feature-n"><img src={require("../../assets/img/3.svg")} alt=""/></i>
                                        <i className="feature-i"><img src={require("../../assets/img/set-material-icon.svg")} alt=""/></i>
                                        <span>
                                            <Translate text="Set Materials & View" />
                                        </span>
                                    </div>
                                </div>
                                <div className="column small-12 medium-5">
                                    <div className="features-item">
                                        <i className="feature-n"><img src={require("../../assets/img/4.svg")} alt=""/></i>
                                        <i className="feature-i"><img src={require("../../assets/img/get-image-icon.svg")} alt=""/></i>
                                        <span>
                                            <Translate text="Get Images" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="content-wrapper sign-up">
                            <div className="form-box-title">
                                <Translate text="sign up to continue" />
                            </div>
                            <form className="unsigned-form" onSubmit={this.register}>
                                <Field label="E-mail"
                                       type="mail"
                                       placeholder="Enter your Email"
                                       required={true}
                                       value={this.state.email}
                                       name="email"
                                       onChange={(event) => this.handleUserInput(event)}
                                       errorMsg={this.state.formErrors.email}
                                       onEnter={this.register}
                                />
                                <Field label="Full name"
                                       type="text"
                                       placeholder="Enter your Full name"
                                       required={true}
                                       name="name"
                                       onChange={(event) => this.handleUserInput(event)}
                                       errorMsg={this.state.formErrors.name}
                                       onEnter={this.register}
                                />
                                <Field label="Password"
                                       type="password"
                                       placeholder="Enter your Password"
                                       required={true}
                                       value={this.state.password}
                                       name="password"
                                       onChange={(event) => this.handleUserInput(event)}
                                       errorMsg={this.state.formErrors.password}
                                       showPassword={true}
                                       onEnter={this.register}
                                />

                                <div className="little-desc">
                                    <Translate text="By clicking Sign Up, you agree to our" />
                                    <Link to="/terms" target="_blank">
                                        <Translate text="terms & conditions" />
                                    </Link>
                                </div>
                                <Button type="submit"
                                        theme="green"
                                        title="sign up"
                                        responsive={true}
                                        loading={this.state.loading}
                                        disabled={!this.state.formValid}
                                />
                            </form>
                            <div className="unsigned-info">
                                <div className="unsigned-txt">
                                    Already a member? <Link to="/">Log in</Link>
                                </div>
                                <div className="unsigned-btn-group">
                                    <span>Sign Up in with</span>
                                    <div>
                                        <FacebookLogin
                                            appId="1109237459282452"
                                            callback={this.facebookSignup}
                                            fields="name,email,picture"
                                            textButton={<Fragment><i className="icon icon-facebook"/><span>facebook</span></Fragment>}
                                            cssClass="btn btn-border-white btn-icon-left"
                                        />
                                        <GoogleLogin
                                            clientId="371289436494-fdv0nf2461d8ot4j39jv6q1544crs6hq.apps.googleusercontent.com"
                                            buttonText={<Fragment><i className="icon icon-g-plus"/><span>gmail</span></Fragment>}
                                            onSuccess={this.googleSignup}
                                            onFailure={(res)=>console.log(res)}
                                            className="btn btn-border-white btn-icon-left"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
