import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import {Translate} from "react-translated";

export default class linkTo extends Component {
    constructor(props) {super(props);
       this.state = {
           redirect: false ,
           link: '',
       };
       this.loadData()
    }

    loadData=()=> {
        this.subSubToken = PubSub.subscribe('redirect.to', (msg, link) => {
            this.setState({
                redirect: true,
                link: link,
            },()=>{
                this.state.redirect = false;
            });
        });
        this.toastError = PubSub.subscribe('toast.error', (msg, text) => {
            toast.error(<Translate text={text} />,{className:'error'})
        });
        this.toastSuccess = PubSub.subscribe('toast.success', (msg, text) => {
            toast.success(<Translate text={text} />,{className:'success'})
        });
        this.toastNotif = PubSub.subscribe('toast.notif', (msg, text) => {
            toast.warn(<Translate text={text} />,{className:'warn'})
        });
        this.toastLoad = PubSub.subscribe('toast.load', (msg, text) => {
            toast.info(<Translate text={text} />,{className:'warn loading-toast',autoClose: false, draggable: false, closeOnClick:false})
        });
        this.toastDismiss = PubSub.subscribe('toast.dismiss', (msg, text) => {
            toast.dismiss()
        });

    };

    componentWillUnmount() {
        PubSub.unsubscribe(this.subSubToken);
        PubSub.unsubscribe(this.toastError);
        PubSub.unsubscribe(this.toastSuccess);
        PubSub.unsubscribe(this.toastNotif);
        PubSub.unsubscribe(this.toastLoad);
        PubSub.unsubscribe(this.toastDismiss);
    }

    render() {
        if (this.state.redirect){
            return (<Redirect to={this.state.link} />);
        }
        return null;
    }
}
