import React, { Component } from 'react';
import './SetMaterialsViews.scss';
import StepTop from '../StepTop/StepTop';
import StyleBox from './StyleBox/StyleBox';
import CheckoutWrapper from './CheckoutWrapper/CheckoutWrapper';
import { Scrollbars } from 'react-custom-scrollbars';
import Global from '../Global';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Modal from 'react-modal';
import UserService from "../UserService";
import Api from "../Api";
import PubSub from 'pubsub-js';
import ReactTooltip from 'react-tooltip';
import { Translate, Translator } from 'react-translated';

import Guide from './Guide/Guide';
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";


export default class SetMaterialsViews extends Component {
   constructor(props){
       super(props);
       let files=[];
       let fileNames=[];
       if (Global.files.length){
           files = Global.files;
           for (let i=0;i<files.length;i++){
               fileNames.push({label:files[i].name,value:files[i].name});
           }
       }
       this.state={
           popupVisible:false,
           files,
           fileNames,
           styleBoxes:[],
           favStyleBoxes:[],
           modalIsOpen: false,
           styleCheck:[],
           percent:0,
           uploadedfiles:0,
           imageCount:0,
           balance:Global.me.render_count,
           beacon:false,
           disableOutsideClick: false,
           guideSelectedStepIndex: 0,
           guideEnded: !!JSON.parse(localStorage.getItem('guideEnded')),
           //guideEnded: true,
       };

       this.handlePopUpClick = this.handlePopUpClick.bind(this);
       this.handleOutsideClick = this.handleOutsideClick.bind(this);

       window.onbeforeunload = this.confirmExit;
       PubSub.subscribe('progress', (msg,progressEvent) => {
           let percent = progressEvent.loaded*100/progressEvent.total;
           let countUploaded = this.state.uploadedfiles;
           if(percent === 100){
               countUploaded = this.state.uploadedfiles+1;
           }
           this.setState({
               percent:percent,
               uploadedfiles:countUploaded,
           })
       });
       PubSub.subscribe('image.count', (msg) => {
           let count = 0;
           Global.styleBoxes.map((item)=>{
               count+=(this.props.match.params.type === "photo"?(item.views.length+item.customViews.length):1)*item.files.length
           });
           this.setState({imageCount:count})
       });
       PubSub.publish("image.count");
       this.getFavorites();
       this.uploadFiles();
   }
    runProject=()=>{
        let x=1;

        if (!Global.styleBoxes.length){
            PubSub.publish('toast.error','Please add a Stylebox');
            x = 0;
        } else {
            Global.styleBoxes.forEach((item)=>{
                if (!item.files.length){
                    PubSub.publish('toast.error','Please attach the files to the stylebox');
                    x = 0;
                }else if (!item.views.length && !item.customViews.length && this.props.match.params.type === "photo"){
                    PubSub.publish('toast.error','Please add views');
                    x = 0;
                }else {
                    item.layers.forEach((layer)=>{
                        if (layer.layerValue === ""){
                            PubSub.publish('toast.error','Please set materials for');
                            x = 0;
                        }
                    })
                }

            })
        }
        if (x && this.state.uploadedfiles !== this.state.files.length){
            PubSub.publish('toast.error','Please wait before upload all files');
            x = 0;
        }else if (x){
            Api.apiCall("post","projectRun",{project:this.props.match.params.id,styleboxes:JSON.stringify(Global.styleBoxes),imagesCount:this.state.imageCount,filesCount:this.state.files.length}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    // console.log(resp.data.error);
                }else {
                    Global.images = this.state.imageCount;
                    PubSub.publish('update.me');
                    PubSub.publish("redirect.to","/project/"+this.props.match.params.id+'/confirmation');
                    window.onbeforeunload = null;
                    // if(resp.data.pay){
                    //     PubSub.publish("redirect.to","/project/"+this.props.match.params.id+'/purchase');
                    // } else{
                    //
                    // }

                }
            })
        }
    };

    confirmExit=()=> {
        return "Are you sure you want to leave this page?";
    };
    handlePopUpClick(event) {
        if (!JSON.parse(localStorage.getItem('guideEnded')) && this.state.popupVisible) {
            return true;
        }

         // attach/remove event handler
        if (this.state.popupVisible) {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        else {
            document.addEventListener('click', this.handleOutsideClick, false);
        }

        if (!this.state.popupVisible) {
            this.incrementStepIndex(1);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        if (!!JSON.parse(localStorage.getItem('guideEnded')) && this.state.guideSelectedStepIndex < 2) {
            this.handlePopUpClick();
        }
    }

    getFavorites = () => {
        Api.apiCall("get","getFavorites",{}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
                this.setState({
                    favStyleBoxes:resp.data,
                })
            }
        })
    };

   openModal = () => {
        this.setState({
            modalIsOpen: true,
            styleCheck:[],
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };
    async uploadFiles(){
        if (Global.files.length){
            let files = Global.files;
            // console.log(files);
            for (let i=0;i<files.length;i++){
                var formData = new FormData();
                formData.append('file',files[i]);
                formData.append('project',this.props.match.params.id);
                formData.append('user',UserService.id);
               var resp = await Api.apiCall("post","upload",formData);
            }
        }
    };

    addStyleBox=()=>{
        this.setState({beacon:true});
        let count = Global.styleBoxes.length+1;
        let name="StyleBox "+count;
        Api.apiCall("post","styleBoxAdd",{name:name, projectId:this.props.match.params.id}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
                let styleBoxes = this.state.styleBoxes;
				let views = this.props.match.params.type === "video"?["view1"]:[];
                styleBoxes.push({name:name,fav:0,id:resp.data.id,selected:[],files:[],layers:[{layerName:"layer 01",layerValue:''}],views:views,customViews:[]});
                Global.styleBoxes.push({name:name,fav:0,id:resp.data.id,selected:[],files:[],layers:[{layerName:"layer 01",layerValue:''}],views:views,customViews:[]});
                this.setState({
                    toggleDropdown:!this.state.toggleDropdown
                })
                //PubSub.publish("redirect.to","/project/"+this.props.projectId+"/file-upload-step");
            }
            this.setState({
                popupVisible: false,
            });
            this.incrementStepIndex(2);
        })
    };
    addAllStyleBox=()=>{
        let count = this.state.styleBoxes.length+1;
        for (let i=0;i<this.state.fileNames.length;i++){
            Global.styleBoxes.push({name:this.state.fileNames[i].label.split(".")[0],fav:0,id:count,selected:[],files:[{...this.state.fileNames[i]}],layers:[{layerName:"layer 01",layerValue:''}],views:[],customViews:[]});
            count++;
        }
        this.forceUpdate();
    };

    handleCheck=(e)=>{
        let check = e.target.checked;
        if (check){
            this.state.styleCheck.push(e.target.value);
        } else{
            this.state.styleCheck.map((item,index)=>{
                if (item === e.target.value){
                    this.state.styleCheck.splice(index, 1);
                }
            })
        }
    };
    addFavorites=()=>{
        let filtered = this.state.favStyleBoxes.filter((item)=>{
            return this.state.styleCheck.filter((itemx)=>{return itemx === item.id}).length;
        });
        let data;
        filtered.map((item)=>{
            data = JSON.parse(item.data);
            data.files=[];
            data.customViews=[];
            if (!data.views){
                if(this.props.match.params.type === "video"){
                    data.views=['view1'];
                } else{
                    data.views=[];
                }
            }
            Global.styleBoxes.push(data);
            // console.log(Global.styleBoxes);
        });
        this.forceUpdate();
        /*for (let i=0;i<this.state.fileNames.length;i++){
            Global.styleBoxes.push({name:"StyleBox "+count,fav:0,id:count,selected:[{label:this.state.fileNames[i],value:this.state.fileNames[i]}],layers:[]});
            count++;
        }*/
        this.setState({
            modalIsOpen: false,
        })
    };

    update=()=>{
        this.forceUpdate();
    };

    incrementStepIndex = (guideSelectedStepIndex) => {
        this.setState({
            guideSelectedStepIndex,
        });
    };

    finishGuide = () => {
        this.setState({
            guideEnded: true,
        });
      localStorage.setItem('guideEnded', 'true');
    };

    handleFileSelect = () => {
        this.incrementStepIndex(3);
    };
    
    /*addTag=(index,tags)=>{
        this.state.styleBoxes[index].selected=tags;
    }*/

    render() {
        const {
            guideEnded,
        } = this.state;
        let steps = [
                {
                    target: '#addStyleBoxButton',
                    content: <Translate text="step-1" />,
                },
                {
                    target: '#addStyleBoxButton .main-dropdown .dropdown-item:nth-child(1)',
                    content: <Translate text="step-2" />,
                },
                {
                    target: '.tags-main .Select',
                    content: <Translate text="step-3" />,
                    contentPositionX: 'left',
                },
                {
                    target: '.materials .materials-select-wrapper',
                    content: <Translate text="step-4" />,
                },
                {
                    target: '.views .add-new-views',
                    content: <Translate text="step-5" />,
                },
            ];
        if(this.props.match.params.type === "video"){
            steps = [
                {
                    target: '#addStyleBoxButton',
                    content: <Translate text="step-1" />,
                },
                {
                    target: '#addStyleBoxButton .main-dropdown .dropdown-item:nth-child(1)',
                    content: <Translate text="step-2" />,
                },
                {
                    target: '.tags-main .Select',
                    content: <Translate text="step-3" />,
                    contentPositionX: 'left',
                },
                {
                    target: '.materials .materials-select-wrapper',
                    content: <Translate text="step-4" />,
                }
            ];
        }
        return (
            <div className="setmaterialsviews">
                <Guide
                    visible={!guideEnded}
                    selectedStep={this.state.guideSelectedStepIndex}
                    steps={steps}
                />
                <StepTop step="4" title="Set Materials & View" projectId={this.props.match.params.id} />
                <div className="row">
                    <div className="column small-12">
                        <div className="content-wrapper selectedfiles-wrapper">
                            <div className="row align-middle align-justify">
                                <div className="column">
                                    <div className="progress-files">
                                        <CircularProgressbar percentage={this.state.percent} strokeWidth="2"/>
                                        <div className="selectedfiles-icon">
                                            <div>
                                                {this.state.uploadedfiles}
                                                /
                                                {this.state.files.length}
                                             </div>
                                        </div>
                                    </div>
                                    <div className="selectedfiles-info">
                                        {this.state.percent === 100 && this.state.uploadedfiles === this.state.files.length?
                                            <div>
                                                <Translate text="All Files Uploaded" />
                                            </div>
                                            :
                                            <div>
                                                <Translate text="Uploading..." />
                                                {Math.round(this.state.percent)}%
                                                {/*<span>*/}
                                                   {/*{this.state.uploadedfiles}*/}
                                                    {/*/*/}
                                                    {/*{this.state.files.length}*/}
                                               {/*</span>*/}
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-justify view-top-control">
                            <div className="column small-7">

                                    <div className="dropdown" id={'addStyleBoxButton'}>
                                        <Button type="button"
                                                theme="green"
                                                iconLeft="plus"
                                                iconLeftSize={12}
                                                title="Add stylebox"
                                                iconRight="arrow-bottom"
                                                iconRightSize={7}
                                                onClick={(e)=>this.handlePopUpClick(e)}
                                        />
                                        {this.state.popupVisible && (
                                            <div className="main-dropdown">
                                                <div className="dropdown-item" onClick={this.addStyleBox}>
                                                    <Icon type="arrow-plus"/>
                                                    <span>
                                                        <Translate text="add new stylebox" />
                                                    </span>
                                                </div>
                                                {this.state.guideEnded?
                                                    <div className="dropdown-item" onClick={this.openModal}>
                                                        <Icon type="arrow-star"/>
                                                        <span>
                                                            <Translate text="add from favorites" />
                                                        </span>
                                                    </div>
                                                :null}
                                            </div>
                                        )}

                                </div>
                            </div>
                            <div className="column text-right">
                                <div className="box-tooltip">
                                    <Translator>
                                        {({ translate }) => (
                                            <a  data-for='set-mat'
                                                className="tooltip-wrap"
                                                data-tip={translate({
                                                    text: 'What is StyleBox? StyleBox is a necessary block',
                                                })}
                                            >
                                                <Icon type="help"/>
                                            </a>
                                        )}
                                    </Translator>
                                    <span className="help-box">
                                        <Translate text="what is stylebox?" />
                                    </span>
                                    <ReactTooltip id='set-mat' globalEventOff='click' place="left"/>
                                </div>
                            </div>
                        </div>
                        {!Global.styleBoxes.length ? (
                            <div className="no-stylebox text-center">
                                <img src={require("../../assets/img/no-stylebox.svg")} alt=""/>
                                <div className="no-stylebox-title">
                                    <Translate text="No StyleBoxes" />
                                </div>
                                <div className="no-stylebox-desc">
                                    <Translate text="StyleBox is a necessary block with the help of which you can set materials and views for one or more files." />
                                </div>
                            </div>
                        ):(
                            <div>
                                {Global.styleBoxes.map((item,key)=>
                                    <StyleBox
                                        type={this.props.match.params.type}
                                        fav={item.fav}
                                        key={key}
                                        update={this.update}
                                        index={key}
                                        styleBox={item}
                                        addTag={this.addTag}
                                        files={this.state.fileNames}
                                        handleFileSelect={this.handleFileSelect}
                                        incrementStepIndex={this.incrementStepIndex}
                                        finishGuide={this.finishGuide}
                                    />
                                )}
                            </div>
                        )}

                    </div>
                </div>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(18, 25, 45, 0.8)',
                            zIndex:'8'     
                        },
                        content: {
                            position:'absolute',
                            width:460,
                            left:'calc(50% - 230px)',
                            bottom:'initial',
                            top:'18%',
                            backgroundColor:'#1c243e',
                            border:'0',
                            padding:'0'
                        }
                    }}
                    className="modal"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={true}
                    contentLabel="Example Modal"
                    >
                    <div className="modal-top">
                        <div className="row align-middle align-justify">
                            <div className="column small-9">
                                <span className="modal-title">
                                    <Translate text="add from favorites" />
                                </span>
                            </div>
                            <div className="column text-right">
                                <Icon type="delete" onClick={this.closeModal}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-main">
                        {this.state.favStyleBoxes.length?(
                            <div >
                                <div className="favorites-content">
                                    <div className="row">
                                        <div className="column small-12">
                                            <Scrollbars 
                                                autoHeight
                                                autoHeightMax={300}>
                                                {this.state.favStyleBoxes.map((item,index) =>
                                                    <div key={index} className="fav-check">
                                                        <input value={item.id} onChange={this.handleCheck} type="checkbox" id={"fav-"+index}/>
                                                        <label htmlFor={"fav-"+index}>
                                                            {item.name}
                                                        </label>
                                                        {/*<div className="delete-fav">*/}
                                                            {/*<Icon type="delete"/>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                )}
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal-actions">
                                    <div className="column small-6">
                                        <Button type="button"
                                                theme="border-white"
                                                title="cancel"
                                                onClick={this.closeModal}
                                        />
                                    </div>
                                    <div className="column small-6">
                                        <Button type="button"
                                                theme="purple"
                                                title="add selected"
                                                onClick={this.addFavorites}
                                        />
                                    </div>
                                </div>
                            </div>
                        ):null}
                    </div>
                </Modal>
                <CheckoutWrapper id={this.props.match.params.id}
                                 type={this.props.match.params.type}
                                 runProject={this.runProject}
                                 filesCount={this.state.files.length}
                                 imageCount={this.state.imageCount}
                />
            </div>
        )
    }
}