import React, { PureComponent } from 'react';
import './Examples.scss';

export default class Examples extends PureComponent {
    render() {
        return (
            <div className="examples">
                <div className="text-center">
                    <div className="min-title">
                        our best
                    </div>
                    <div className="page-title" style={{marginTop:12}}>
                        Render Examples
                    </div>
                    <div className="examples-list">
                        <div className="row">
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                            <div className="column small-4">
                                <div className="example-item">
                                    <img src={require("../../assets/img/example-img.png")} alt=""/>
                                    <div className="name">
                                        Scene 1
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
