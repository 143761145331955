import React, { PureComponent } from 'react';
import './SelectScene.scss';
import StepTop from '../StepTop/StepTop';
import Global from '../Global';
import SceneItem from "./SceneItem";
import VideoSceneItem from "./VideoSceneItem";



export default class SelectScene extends PureComponent {
    render() {
        return (
            <div className="selectscene">
                <StepTop step={1} title="Select Scene" projectId={this.props.match.params.id}/>
                <div className="row scene-items">
                    {this.props.match.params.type === "video"?(
                        <div className="column small-12">
                            <div className="row">
                                {Global.me.id === 189?
                                    <VideoSceneItem key={4} scene={5} projectId={this.props.match.params.id}/>
                                    :null
                                }

                                    {[...Array(4)].map((x, i) =>
                                        <VideoSceneItem key={i} scene={i+1} projectId={this.props.match.params.id}/>
                                    )}
                                {Global.me.id === 166?
                                    <VideoSceneItem key={5} scene={6} projectId={this.props.match.params.id}/>
                                    :null
                                }
                            </div>
                        </div>
                    ):(
                        <div className="column small-12">
                            {Global.me.id ===178?
                                <SceneItem key={7} scene={8} projectId={this.props.match.params.id}/>
                            :Global.me.id === 189?
                                <SceneItem key={7} scene={9} projectId={this.props.match.params.id}/>
                            :Global.me.id === 208?
                                <SceneItem key={8} scene={10} projectId={this.props.match.params.id}/>
                            :null
                            }

                            <div>
                            {[...Array(7)].map((x, i) =>
                                <SceneItem key={i} scene={i+1} projectId={this.props.match.params.id}/>
                            )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
