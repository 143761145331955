import React, { PureComponent } from 'react';
import './Profile.scss';
import { Switch, Route, NavLink } from 'react-router-dom';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import ChangePassword from './ChangePassword/ChangePassword';
import { Translate } from 'react-translated';
import Icon from "../global/Icon/Icon";


export default class Profile extends PureComponent {
    render() {
        return (
            <div className="profile-page">
                <div className="row">
                    <div className="column small-8">
                        <div className="row">
                            <div className="column small-12">
                                <div className="page-title" style={{marginBottom:52}}>
                                    <Translate text="personal information" />
                                </div>
                            </div>
                            <div className="column">
                                <div className="content-wrapper profile-links">
                                    <NavLink to="/profile/personal-information" className="profile-link" activeClassName="active">
                                        <Icon type="user" size={15}/>
                                        <span>
                                            <Translate text="personal information" />
                                        </span>
                                    </NavLink>
                                    <NavLink to="/profile/change-password" className="profile-link" activeClassName="active">
                                        <Icon type="lock" size={17}/>
                                        <span>
                                            <Translate text="change password" />
                                        </span>
                                    </NavLink>
                                    {/* <NavLink to="/" className="profile-link" activeClassName="active">
                                        <i className="icon icon-lock" style={{fontSize:16}}></i>
                                        <span>
                                            Email addresses
                                        </span>
                                    </NavLink> */}
                                </div>
                            </div>
                            <div className="column">
                                <Switch>
                                    <Route path={this.props.match.url+'/personal-information'} component={PersonalInformation}/>
                                    <Route path={this.props.match.url+'/change-password'} component={ChangePassword}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
