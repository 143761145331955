import React, { PureComponent } from 'react';
import './StepTop.scss'
import Modal from 'react-modal';
import GeneralSetting from '../GeneralSetting/GeneralSetting';
import { Translate } from 'react-translated';
import Button from "../global/Button/Button";

export default class StepTop extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            modalIsOpen: false,
        }
    }
    toggleModal = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen,
        });
    };
    render() {
        return (
            <div className="row align-justify selectscene-top">
                <div className="column">
                    <div className="page-top-left">
                        <div className="min-title">
                            <Translate text="Create A New Project" />
                        </div>
                        <div className="page-title">
                            {this.props.step}. <Translate text={this.props.title} />
                        </div>
                    </div>
                </div>
                <div className="column small-6 text-right">
                    <Button type="button"
                            theme="dark"
                            iconLeft="settings"
                            title="general settings"
                            onClick={this.toggleModal}
                    />
                    <Modal
                        style={{
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(18, 25, 45, 0.8)',
                                zIndex:'8'     
                            },
                            content: {
                                width:460,
                                left:'calc(50% - 230px)',
                                padding:0,
                                bottom:'initial',
                                overflow:'unset',
                                top:'18%',
                                backgroundColor:'#1c243e',
                                border:'0'
                            }
                        }}
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.toggleModal}
                        ariaHideApp={false}
                        contentLabel="Example Modal"
                        >
                        <GeneralSetting id={this.props.projectId} componentType={true} funcToggleModal = {this.toggleModal}/>
                    </Modal>
                </div>
            </div>
        )
    }
}
