import React, { Component } from 'react';
import './GeneralSetting.scss';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import PubSub from 'pubsub-js';
import Api from '../Api';
import Global from '../Global';
import { Translate } from 'react-translated';
import Button from "../global/Button/Button";
import Field from "../global/Field/Field";

export default class GeneralSetting extends Component {
    constructor(props){
        super(props);
        let res =[
            {value:'3840x2160',label:'3840x2160'},
            {value:'2160x2160',label:'2160x2160'},
            {value:'1920x1080',label:'1920x1080'},
            {value:'1080x1080',label:'1080x1080'},
            {value:'1280x720',label:'1280x720'},
            {value:'720x720',label:'720x720'},
            {value:'854x480',label:'854x480'},
            {value:'480x480',label:'480x480'}
        ];
        let format =[
            {value:'jpg',label:'jpg'},
            {value:'png',label:'png'},
            {value:'jpg+png',label:'jpg+png'},
        ];

        let time =[
            {value:'10',label:'10 Sec.'},
            {value:'12',label:'12 Sec.'},
            {value:'15',label:'15 Sec.'},
        ];
        this.state={
            selectedOption: '',
            componentType: this.props.componentType,
            id:this.props.id || 0,
            name:"",
            imageResolution:'',
            format:format[0].value,
            background:'White Reflective',
            resolution:res[2].value,
            resolutions:res,
            formats:format,
            times:time,
            time:time[0].value,
            show:false,
            loading:false,
            type:this.props.type || "photo"
        };
        if (!this.props.componentType){
            Global.styleBoxes=[];
        }
    }
    componentDidMount(){
        if (this.props.id){
            this.loadData();
        }

    }
    loadData=()=>{
        Api.apiCall("get","getProject",{id:this.state.id}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
                this.setState({
                    name:resp.data.project.name,
                    resolution:resp.data.project.resolution,
                    format:resp.data.project.format,
                    time:resp.data.project.time,
                    background:resp.data.project.background,
                    show:true,
                    type:resp.data.project.type? "video" : "photo",
                })
            }
        })
    };
    handleChange = (val,name) => {
        let value = val.value;
        if (name === 'name'){
            //value = val.target.value.replace(/[^a-zA-Z\s, 0-9,_,(,),$,&,+,=,@,!]/g, "");
            value = val.target.value.replace(/([.*?^:'"(){}|[\]\/\\])/g, "");
        }
        let variable=this.state;
        variable[name] = value;
        this.setState({
            ...variable,
        })
    };

    saveSettings=(e)=>{
        e.preventDefault();
        /* Save Settings */
        this.setState({loading:true});
        if (this.props.componentType){
            /* Close Popup */
            Api.apiCall("get","projectName",{name:this.state.name}).then((respx)=>{
                if (respx.status !== 200) {
                    // console.log(respx.status);
                }
                if ('error' in respx.data) {
                    this.setState({loading:false});
                    PubSub.publish('toast.error',respx.data.error);
                }else {
                    let data = {
                        name:this.state.name,
                        resolution:this.state.resolution,
                        format:this.state.format,
                        time:this.state.time,
                        background:this.state.background,
                        id:this.state.id,
                    };
                    Api.apiCall("post","projectEdit",data).then((resp)=>{
                        this.setState({loading:false});
                        if (resp.status !== 200) {
                            // console.log(resp.status);
                        }
                        if ('error' in resp.data) {
                            PubSub.publish('toast.error',resp.data.error);
                        }else {
                            this.props.funcToggleModal()
                        }
                    })
                }
            })
        } else{
            Api.apiCall("get","projectName",{name:this.state.name}).then((respx)=>{
                // console.log(respx);
                if (respx.status !== 200) {
                    // console.log(respx.status);
                }
                if ('error' in respx.data) {
                    this.setState({loading:false});
                    PubSub.publish('toast.error',respx.data.error);
                }else {
                    let data = {
                        name:this.state.name,
                        resolution:this.state.resolution,
                        format:this.state.format,
                        time:this.state.time,
                        background:this.state.background,
                        type:this.state.type === "photo"?0:1
                    };
                    Api.apiCall("post","projectAdd",data).then((resp)=>{
                        this.setState({loading:false});
                        if (resp.status !== 200) {
                            // console.log(resp.status);
                        }
                        if ('error' in resp.data) {
                            PubSub.publish('toast.error',resp.data.error);
                        }else {
                            PubSub.publish("redirect.to","/project/"+this.state.type+"/"+resp.data.success+"/select-scene");
                        }
                    })
                }
            })
        }
    };

    render() {
        if (this.state.show || !this.props.componentType){
            return (
                <div className="generalsetting">
                    <form action="" onSubmit={this.saveSettings}>
                        <div className="row">
                            <div className="column small-12">
                                <div className="modal-field-item">
                                    <Field label="Project name"
                                           theme={true}
                                           type="text"
                                           placeholder="Enter Project Name"
                                           required={true}
                                           value={this.state.name}
                                           defaultValue={this.state.name}
                                           name="Untitled"
                                           onChange={(opt)=>this.handleChange(opt,"name")}
                                           onEnter={()=>{}}
                                    />
                                </div>
                                {Global.me.id !== 178?
                                    <div>
                                        <div className="modal-field-item">
                                            <label className="form-label text-left">
                                                <Translate text="resolution" />
                                            </label>
                                            <Select
                                                name="form-field-name"
                                                value={this.state.resolution}
                                                placeholder=""
                                                onChange={(opt)=>this.handleChange(opt,"resolution")}
                                                options={this.state.resolutions}
                                                searchable={false}
                                                clearable={false}
                                            />
                                        </div>
                                        <div className="modal-field-item">
                                            <label className="form-label text-left">
                                                {this.state.type ==="photo"?<Translate text="format" />:<Translate text="duration" />}
                                            </label>
                                            <Select
                                                name="form-field-name"
                                                value={this.state.type === "photo"?this.state.format:this.state.time}
                                                placeholder=""
                                                onChange={(opt)=>this.handleChange(opt,this.state.type === "photo"?"format":"time")}
                                                options={this.state.type === "photo"?this.state.formats:this.state.times}
                                                searchable={false}
                                                clearable={false}
                                            />
                                        </div>
                                        {this.state.type === "photo"?
                                            <div className="modal-field-item">
                                                <label className="form-label text-left">
                                                    <Translate text="Background" />
                                                </label>
                                                <Select
                                                    name="form-field-name"
                                                    value={this.state.background}
                                                    onChange={(opt)=>this.handleChange(opt,"background")}
                                                    placeholder=""
                                                    searchable={false}
                                                    clearable={false}
                                                    options={
                                                        [
                                                            {value:'White Reflective', label:'White Reflective'},
                                                            {value:'White to Black Reflective',label:'White to Black Reflective'},
                                                            {value:'White Reflective Soft', label:'White Reflective Soft'},
                                                            {value:'White Reflective Blur', label:'White Reflective Blur'},
                                                            {value:'White Matte', label:'White Matte'},
                                                            {value:'Green Reflective', label:'Green Reflective'},
                                                            {value:'Grey Matte', label:'Grey Matte'},
                                                            {value:'Grey Reflective', label:'Grey Reflective'},
                                                            {value:'Black Reflective', label:'Black Reflective'},
                                                            {value:'Black Mirror', label:'Black Mirror'},
                                                            {value:'Black Matte', label:'Black Matte'},
                                                            {value:'Aqua Reflective', label:'Aqua Reflective'},
                                                            {value:'Blue Reflective', label:'Blue Reflective'},
                                                            {value:'Green Reflective', label:'Green Reflective'},
                                                            {value:'Orange Reflective', label:'Orange Reflective'},
                                                            {value:'Purple Reflective', label:'Purple Reflective'},
                                                            {value:'Red Reflective', label:'Red Reflective'},
                                                            {value:'Yellow Reflective', label:'Yellow Reflective'},
                                                        ]
                                                    }
                                                />
                                            </div>
                                            :null}
                                    </div>:null
                                }
                            </div>
                            <div className="setting-actions column small-12">
                                {this.state.componentType ? (
                                    <div className="row align-spaced">
                                        <div className="column small-6">
                                            <Button type="button"
                                                    theme="border-white"
                                                    title="cancel"
                                                    responsive={true}
                                                    onClick={this.props.funcToggleModal}
                                            />
                                        </div>
                                        <div className="column small-6">
                                            <Button type="submit"
                                                    theme="purple"
                                                    title="save"
                                                    responsive={true}
                                                    loading={this.state.loading}
                                            />
                                        </div>
                                    </div>
                                ):(
                                    <div className="row align-spaced">
                                        <div className="column small-7">
                                            <Button type="submit"
                                                    theme="purple"
                                                    title="next"
                                                    iconRight="arrow-right"
                                                    iconRightSize={12}
                                                    responsive={true}
                                                    loading={this.state.loading}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            )
        } else{
            return null;
        }
    }
}
