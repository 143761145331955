import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MyProjects from './MyProjects/MyProjects';
import SelectScene from './SelectScene/SelectScene';
import UploadFileSteps from './UploadFileSteps/UploadFileSteps';
import SetMaterialsViews from './SetMaterialsViews/SetMaterialsViews';
import Step1 from './Step1/Step1';
import UserService from './UserService';
import UploadRulesStep from './UploadRulesStep/UploadRulesStep';
import Profile from './Profile/Profile';
import PurchaseConfirmation from './PurchaseConfirmation/PurchaseConfirmation';
import BillingPlan from './Billing/BillingPlan';
import Billing from './Billing/Billing';

export default class App extends Component {
    render() {
        if (!UserService.isAuth()){
            return (
                <Redirect to="/" />
            )
        }
        return (
            <Switch>
                <Route path='/my-projects' component={MyProjects}/>
                <Route path='/project/:type/:id/select-scene' component={SelectScene}/>
                <Route path='/project/:type/:id/file-upload-step' component={UploadFileSteps}/>
                <Route path='/project/:type/:id/set-materials-and-views' component={SetMaterialsViews}/>
                <Route path='/general-settings/:type' component={Step1}/>
                <Route path='/billing-plan/:id' component={BillingPlan}/>
                <Route path='/project/:type/:id/upload-rules-step' component={UploadRulesStep}/>
                <Route path='/profile' component={Profile}/>
                <Route path='/project/:id/purchase' component={Billing}/>
                <Route path='/project/:id/confirmation' component={PurchaseConfirmation}/>
                <Route component={MyProjects}/>
            </Switch>
        )
    }
}