import React, { Component } from 'react';

class Global extends Component {
    static files=[];
    static project={};
    static styleBoxes=[];
    static me={};
    static images=0;
    static successMessage='';
}

export default Global;