import React, { Component } from 'react';
import './SuccesMessage.scss';
import Global from '../Global';
import Api from '../Api';
import {Translate} from "react-translated";
import PubSub from "pubsub-js";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";

export default class SuccesMessage extends Component {

    constructor(props){
        super(props);
        let message = Global.successMessage;

        if (this.props.match.params.token && this.props.match.params.token !== "plan" && this.props.match.params.token !== "project"){
            Api.apiCall("get","active/"+this.props.match.params.token,{}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    this.setState({message:resp.data.error})
                }else {
                    let messagex = "Congratulations! Your profile has been confirmed and you can already enjoy our services!";
                    this.setState({messagex})
                }
            })
        } else{
            PubSub.publish('update.me');
            if(this.props.match.params.error){
                message = "Error purchase";
            } else{
                if(this.props.match.params.token === "plan"){
                    message = "Congratulations! Your Plan has activated";
                } else if(this.props.match.params.token === "project"){
                    message = "Congratulations! Your project was successfully confirmed and the ready rendered images/videos will be sent to you soon.";
                }
            }
        }
        this.state= {
            message: message,
        }
    }

    render() {
        return (
            <div className="succesmessage">
                <div className="row align-spaced">
                    <div className="column small-6 text-center">
                        <div className="content-wrapper text-center confirmation-block">
                            <div className="confirmation-info">
                                <div className="confirmation-main">
                                    <Icon type="check"/>
                                    <div className="title">
                                        <Translate text="Thank you!" />
                                    </div>
                                    <div className="description">
                                        <Translate text={this.state.message} />
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <Button type="button"
                                theme="green"
                                title={this.state.message === "modelThankyouDescrption" || (this.props.match.params.token && (this.props.match.params.token === "plan" || this.props.match.params.token === "project"))?"My Projects":"log in"}
                                link="/"
                        />
                    </div>
                </div>
            </div>
        )
    }
}
