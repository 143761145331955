import React, { PureComponent } from 'react';
import './Step1.scss';
import GeneralSetting from '../GeneralSetting/GeneralSetting';
import { Translate } from 'react-translated';

export default class Step1 extends PureComponent {
    render() {
        return (
            <div className="step1">
                <div className="general-settings">
                    <div className="general-top">
                        <div className="min-title">
                            <Translate text="Create A New Project" />
                        </div>
                        <div className="page-title-center">
                            <Translate text="general settings" />
                        </div>
                    </div>
                    <GeneralSetting type={this.props.match.params.type} componentType={false} />
                </div>
            </div>
        )
    }
}
