import React, { Component } from 'react';
import './PurchaseConfirmation.scss';
import Global from '../Global';
import PubSub from 'pubsub-js';
import { Translate } from 'react-translated';
import Api from "../Api";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";


export default class PurchaseConfirmation extends Component {
    constructor(props){
        super(props);
        this.state={
            project:{
                images:0,
            }
        }
    }
    componentDidMount(){
        Global.files=[];
        if(this.props.match.params.id){
            Api.apiCall("get","getProject",{id:this.props.match.params.id}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                    PubSub.publish("redirect.to","/my-projects");
                }
                if ('error' in resp.data) {
                    // console.log(resp.data.error);
                    PubSub.publish("redirect.to","/my-projects");
                }else {
                    this.setState({
                        project:resp.data,
                    });
                    if(!resp.data.images){
                        PubSub.publish("redirect.to","/my-projects");
                    }
                    //PubSub.publish("redirect.to","/project/"+this.props.projectId+"/file-upload-step");
                }
            })
        }
    }
    render() {
        return (
            <div className="purchaseconfirmation">
                <div className="row">
                    <div className="column small-12 text-center">
                        <div className="content-wrapper text-center confirmation-block">
                            <div className="confirmation-info">
                                <div className="confirmation-main">
                                    <Icon type="check"/>
                                    <div className="title">
                                        <Translate text="Thank you!" />
                                    </div>
                                    <div className="description">
                                        <Translate text="purchase-confirmation-description" />
                                    </div>
                                    <div className="renders-count">
                                        <span>
                                            <Translate text="Number of Renderings" />
                                        </span>
                                        <div>
                                            <Icon type="images"/>
                                            <span>
                                                {this.state.project.images}
                                            </span>

                                                {this.state.project.type?
                                                    <small>
                                                        <Translate text="video" />
                                                    </small>
                                                    :
                                                    <small>
                                                        jpg/png
                                                    </small>
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button type="button"
                                theme="dark"
                                iconLeft="arrow-left"
                                title="back to projects page"
                                link="/"
                        />
                    </div>
                </div>
            </div>
        )
    }
}