import React, {PureComponent} from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import './Field.scss';
import { Translate, Translator } from 'react-translated';


export default class Field extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            text: '',
            focused: false,
            type: this.props.type,
        };
    }

    showHide = () =>{
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        })
    };

    render(){
        const errorMsg = typeof this.props.errorMsg === 'string' ? this.props.errorMsg : null;

        return(
            <div className={`field ${this.props.theme ? 'field-bordered' : 'field-background'}`}>
                <div className="flex-container align-justify">
                    {this.props.label ? (
                        <label className="field-label">
                            <Translate text={this.props.label} />
                        </label>
                    ):null}
                    {this.props.suffix ? (
                        <Link to={this.props.suffix} className="field-link">
                            <Translate text="forgot password" />
                        </Link>
                    ) : null}
                </div>

                <div className="field-wrapper">
                    <Translator>
                        {({ translate }) => (
                            this.props.textArea ? (
                                <textarea
                                    className={`field-area ${errorMsg != null && !this.state.focused ? 'field-area-error': this.props.checkValid ? 'field-area-success':''}`}
                                    name={this.props.name}
                                    data-size={this.props.areaSize}
                                    cols={this.props.cols} rows={this.props.rows}
                                    placeholder={translate({
                                        text: this.props.placeholder ? this.props.placeholder : '',
                                    })}
                                    onChange={this.props.onChange}
                                    onFocus={() => this.setState({focused: true})}
                                    onBlur={() => this.setState({focused: false})}
                                    required={this.props.required}
                                    disabled={this.props.disabled}
                                    value={this.props.value}
                                    defaultValue={this.props.defaultValue}
                                    onKeyDown={(e)=>e.key==="Enter"?this.props.onEnter():null}
                                />
                                ):(
                                <input
                                    onChange={this.props.onChange}
                                    onFocus={() => this.setState({focused: true})}
                                    onBlur={() => this.setState({focused: false})}
                                    className={`field-input ${errorMsg != null && !this.state.focused ? 'field-input-error': this.props.checkValid ? 'field-input-success':''}`}
                                    type={this.state.type}
                                    placeholder={translate({
                                        text: this.props.placeholder ? this.props.placeholder : '',
                                    })}
                                    disabled={this.props.disabled}
                                    required={this.props.required}
                                    name={this.props.name}
                                    value={this.props.value}
                                    readOnly={this.props.readOnly}
                                    defaultValue={this.props.defaultValue}
                                    pattern={this.props.pattern}
                                    onKeyDown={(e)=>e.key==="Enter"?this.props.onEnter():null}
                                />)
                        )}
                    </Translator>
                    {this.props.showPassword ? (
                        <div className="show" onClick={()=>{this.showHide()}}>
                            <img src={require("../../../assets/img/visible.svg")} alt=""/>
                        </div>
                    ):null}
                    {errorMsg != null && !this.state.focused ?
                        <div className="field-error">
                            <Translate text={this.props.errorMsg ? this.props.errorMsg : ''} />
                        </div>
                        : null}
                </div>

                {this.props.formHint ? (
                    <div className="color-grey field-hint font-normal">
                        {this.props.formHint}
                    </div>
                ) : null}
            </div>
        )
    }
}

Field.propTypes = {
    theme: PropTypes.bool,
    label: PropTypes.string,
    suffix: PropTypes.string,
    placeholder: PropTypes.string,
    pattern: PropTypes.string,
    required: PropTypes.bool,
    textArea: PropTypes.bool,
    errorMsg: PropTypes.string,
    showPassword: PropTypes.bool,
    formHint: PropTypes.string,
    onEnter: PropTypes.func
};