import React, { Component } from 'react';
import './UploadRules.scss';
import ScrollableAnchor from 'react-scrollable-anchor';
//import {Navlink} from 'react-router-dom';
import {Translate} from "react-translated";

export default class UploadRules extends Component {

    constructor(props){
        super(props);
        this.state={
            select:"",
            checkFileType:this.props.match.params.type === "video"?true:false
        };
    }
    componentDidMount(){
        window.addEventListener('scroll', (event) => {
           if(this.state.select !== window.location.hash){
            this.setState({
                select:window.location.hash,
            })
           }
            
        });
    }

    render() {
        return (
            <div className="uploadrules">
                <div className="text-center">
                    <div className="min-title">
                        <Translate text="Create A New Project" />
                    </div>
                    <div className="page-title" style={{marginTop:12}}>
                        <Translate text="file upload rules" />
                    </div>
                </div>
                <div className="uploadrules-content">
                    {this.state.checkFileType ? (
                        <div className="row align-top">
                            <div className="column sidebar-wrapper">
                                <div className="content-wrapper anchor-sidebar">
                                    <div className="acrticle-sub-links">
                                        <div className={this.state.select === '#section1' || this.state.select === ''?'active_link anchor-link':"anchor-link"}>
                                            <a href='#section1'>
                                                1.&nbsp;
                                                <Translate text="Technical Information"/>
                                            </a>
                                        </div>
                                        <div className={this.state.select === '#section2'?'active_link anchor-link':"anchor-link"}>
                                            <a href='#section2'>
                                                2.&nbsp;
                                                <Translate text="Object Positions"/>
                                            </a>
                                        </div>
                                        <div className={this.state.select === '#section3'?'active_link anchor-link':" anchor-link"}>
                                            <a href='#section3'>
                                                3.&nbsp;
                                                <Translate text="Check for Hidden Objects"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="articles">
                                    <ScrollableAnchor id={'section1'}>
                                        <div className="article-section">
                                            <div className="article-title">
                                                1.&nbsp;
                                                <Translate text="Technical Information"/>
                                            </div>
                                            <div className="article-sub-desc">
                                                <Translate text="Obligatory Conditions for Using Rendline.com"/>
                                            </div>
                                            <div className="article-txt">
                                                <ul>
                                                    <li>
                                                        <Translate text="The 3D CAD file must be in the '.3dm' format"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="The file size should not exceed 100 mb"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="The file must not contain bad objects"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="Note that the program will render"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="All those layers which do not"/>
                                                        <br/>
                                                        <Translate text="The layers names are:"/>
                                                        <br/>
                                                        <Translate text="Layer"/> 01
                                                        <br/>
                                                        <Translate text="Layer"/> 02
                                                        <br/>
                                                        <Translate text="Layer"/> 03
                                                        <br/>
                                                        ...
                                                        <br/>
                                                        <Translate text="Layer"/> 100

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                    <ScrollableAnchor id={'section2'}>
                                        <div className="article-section">
                                            <div className="article-title">
                                                2.&nbsp;
                                                <Translate text="Object Positions"/>
                                            </div>
                                            <div className="article-txt">
                                                <Translate text="Please note that when making a 360º"/>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                    <ScrollableAnchor id={'section3'}>
                                        <div  className="article-section">
                                            <div className="article-title">
                                                3.&nbsp;
                                                <Translate text="Check for Hidden Objects"/>
                                            </div>
                                            <div className="article-txt">
                                                <Translate text="In order to see all hidden objects"/>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className="row align-top">
                            <div className="column sidebar-wrapper">
                                <div className="content-wrapper anchor-sidebar">
                                    <div className="acrticle-sub-links">
                                        <div className={this.state.select === '#section1' || this.state.select === ''?'active_link anchor-link':"anchor-link"}>
                                            <a href='#section1'>
                                                1.&nbsp;
                                                <Translate text="Technical Information"/>
                                            </a>
                                        </div>
                                        <div className={this.state.select === '#section2'?'active_link anchor-link':"anchor-link"}>
                                            <a href='#section2'>
                                                2.&nbsp;
                                                <Translate text="Object Positions"/>
                                            </a>
                                        </div>
                                        <div className={this.state.select === '#section3'?'active_link anchor-link':" anchor-link"}>
                                            <a href='#section3'>
                                                3.&nbsp;
                                                <Translate text="Individual Views"/>
                                            </a>
                                        </div>
                                        {/* <div className={this.state.select=='#section4'?'active_link anchor-link':"anchor-link"}>
                                         <a href='#section4'>
                                         4. Realistic render
                                         </a>
                                         </div> */}
                                        <div className={this.state.select === '#section4'?'active_link anchor-link':"anchor-link"}>
                                            <a href='#section4'>
                                                4.&nbsp;
                                                <Translate text="Check for Hidden Objects"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="articles">
                                    <ScrollableAnchor id={'section1'}>
                                        <div className="article-section">
                                            <div className="article-title">
                                                1.&nbsp;
                                                <Translate text="Technical Information"/>
                                            </div>
                                            <div className="article-sub-desc">
                                                <Translate text="Obligatory Conditions for Using Rendline.com"/>
                                            </div>
                                            <div className="article-txt">
                                                <ul>
                                                    <li>
                                                        <Translate text="The 3D CAD file must be in the '.3dm' format"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="The file size should not exceed 100 mb"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="The file must not contain bad objects"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="Note that the program will render"/>
                                                    </li>
                                                    <li>
                                                        <Translate text="All those layers which do not"/>
                                                        <br/>
                                                        <Translate text="The layers names are:"/>
                                                        <br/>
                                                        <Translate text="Layer"/> 01
                                                        <br/>
                                                        <Translate text="Layer"/> 02
                                                        <br/>
                                                        <Translate text="Layer"/> 03
                                                        <br/>
                                                        ...
                                                        <br/>
                                                        <Translate text="Layer"/> 100

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                    <ScrollableAnchor id={'section2'}>
                                        <div className="article-section">
                                            <div className="article-title">
                                                2.&nbsp;
                                                <Translate text="Object Positions"/>
                                            </div>
                                            <div className="article-txt">
                                                <Translate text="In order to achieve an accurate"/>
                                                <br/>
                                                <img src={require("../../assets/img/faq-img.png")} alt=""/>
                                                <br/>
                                                <Translate text="Picture"/> 1
                                                <br/>
                                                <br/>
                                                    <Translate text="If you do not wish to use our standard views"/>
                                                <br/>
                                                <Translate text="All views are created for rings"/>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                    <ScrollableAnchor id={'section3'}>
                                        <div  className="article-section">
                                            <div className="article-title">
                                                3.&nbsp;
                                                <Translate text="How to Create Individual Views for your Models"/>
                                            </div>
                                            <div className="article-txt">
                                                <Translate text="First of all, set your preferred view in"/>
                                                <br/>
                                                <Translate text="You can add any number of Views."/>
                                                <br/>
                                                <br/>
                                                <img src={require("../../assets/img/faq-img-5.jpg")} alt=""/>
                                                <br/>
                                                <Translate text="Picture"/> 2
                                                <br/>
                                                <br/>
                                                <img src={require("../../assets/img/faq-img-5.jpg")} alt=""/>
                                                <br/>
                                                <Translate text="Picture"/> 3
                                                <br/>
                                                <br/>
                                                <img src={require("../../assets/img/faq-img-6.jpg")} alt=""/>
                                                <br/>
                                                <Translate text="Picture"/> 4
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                    <ScrollableAnchor  id={'section4'}>
                                        <div className="article-section">
                                            <div className="article-title">
                                                4.&nbsp;
                                                <Translate text="Check for Hidden Objects"/>
                                            </div>
                                            <div className="article-txt">
                                                <Translate text="Picture"/> 4
                                                <Translate text="In order to see all hidden objects"/>
                                            </div>
                                        </div>
                                    </ScrollableAnchor>
                                </div>
                            </div>
                        </div>
                    )}
                </div>                
            </div>
        )
    }
}