import React from 'react';
import './Loading.scss';
import Icon from "../Icon/Icon";

const Loading = () => {
    return(
        <div className="loading_content">
            <Icon type="loading" className="loading"/>
        </div>
    )
};

export default Loading;