import React, { Component } from 'react';
import  Page from './Page';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-translated';
import Translation from './Translation';
//import PubSub from 'pubsub-js';
import './assets/css/main.scss';
import './assets/css/overwrites.scss';
import './assets/css/helpers.scss';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem('lang') || 'en',
        };
        this.loadData();
    }

    loadData= async ()=> {

        import('./components/LanguageStyleInjector')
            .then(module => module.default());
    };

    /*componentWillUnmount() {
        PubSub.unsubscribe(this.changeLang);
    }*/

    render() {
        return (
            <Provider language={this.state.lang} translation={Translation}>
                <Router >
                    <Switch>
                        <Route exact component = { Page }/>
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;