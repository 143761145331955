import React, { Component } from 'react';
import './Materials.scss';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import Global from '../../../Global';
import ReactTooltip from 'react-tooltip';
import {Translate, Translator} from 'react-translated';
import Icon from "../../../global/Icon/Icon";
import Button from "../../../global/Button/Button";

export class StyleBoxItem extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedOption: this.props.value.layerValue || '',
        }
    }

    onFocus=(event)=>{
        event.target.setAttribute("placeholder","")
        // console.log(event.target);
    };

    handleChange = (selectedOption) => {
        // console.log(Global.styleBoxes);
        this.setState({ selectedOption });
        if (selectedOption && selectedOption.value){
            Global.styleBoxes[this.props.styleBox].layers[this.props.index].layerValue=selectedOption.value;
        } else{
            Global.styleBoxes[this.props.styleBox].layers[this.props.index].layerValue="";
        }
        if(this.props.type === "photo"){
            this.props.incrementStepIndex(4);
        } else{
            this.props.finishGuide();
        }


    };
    render(){

        return(
            <div className="materials-select-wrapper">
                <label>
                    {this.props.value.layerName}
                </label>

                <Translator>
                    {({ translate }) => (
                        <Select
                            name="form-field-name"
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={this.props.options}
                            onFocus={this.onFocus}
                            placeholder={translate({
                                text: 'Select material',
                            })}
                        />

                    )}
                </Translator>

                {this.props.dataCount-1 === this.props.index && this.props.index !== 0  ? (
                    <div className="delete-material">
                        <Icon type="delete" onClick={() => this.props.removeItem(this.props.index)}/>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default class Materials extends Component {
    constructor(props){
        super(props);
        this.state={
            data:Global.styleBoxes[this.props.index].layers || [],
        };
        var materials = {
            label: 'Metals', options: [
                { label: 'Platinum', value: 'Platinum_rls190319hm' },
                { label: 'Silver', value: 'Silver_rls190319hm' },
                { label: 'White Gold', value: 'White Gold_rls190319hm' },
                { label: 'White Gold Matte', value: 'White Gold Matte_rls190319hm' },
                { label: 'Rose Gold', value: 'Rose Gold_rls190319hm' },
                { label: 'Rose Gold Matte', value: 'Rose Gold Matte_rls190319hm' },
                { label: 'Yellow Gold', value: 'Yellow Gold_rls190319hm' },
                { label: 'Yellow Gold Matte', value: 'Yellow Gold Matte_rls190319hm' },
                { label: 'Titanium', value: 'Titanium_rls190319hm' },
                { label: 'Black Metal', value: 'Black Metal_rls190319hm' },
                { label: 'Green Gold', value: 'Green Gold_rls190319hm' },
            ],
        };
        if(Global.me.id === 223){
            materials = {
                label: 'Metals', options: [
                    { label: 'Platinum', value: 'Platinum_rls190319hm' },
                    { label: 'Silver', value: 'Silver_rls190319hm' },
                    { label: 'White Gold', value: 'White Gold_rls190319hm' },
                    { label: 'White Gold Lanier', value: 'White Gold Lanier_rls190319hm' },
                    { label: 'White Gold Matte', value: 'White Gold Matte_rls190319hm' },
                    { label: 'Rose Gold', value: 'Rose Gold_rls190319hm' },
                    { label: 'Rose Gold Lanier', value: 'Rose Gold Lanier_rls190319hm' },
                    { label: 'Rose Gold Matte', value: 'Rose Gold Matte_rls190319hm' },
                    { label: 'Yellow Gold', value: 'Yellow Gold_rls190319hm' },
                    { label: 'Yellow Gold Lanier', value: 'Yellow Gold Lanier_rls190319hm' },
                    { label: 'Yellow Gold Matte', value: 'Yellow Gold Matte_rls190319hm' },
                    { label: 'Titanium', value: 'Titanium_rls190319hm' },
                    { label: 'Black Metal', value: 'Black Metal_rls190319hm' },
                    { label: 'Green Gold', value: 'Green Gold_rls190319hm' },
                ],
            }
        }
        if(Global.me.id === 478){
            materials = {
                label: 'Metals', options: [
                    { label: 'Rhodium', value: 'Rhodium_rls190319hm' },
                    { label: 'Matte Black', value: 'Matte Black_rls190319hm' },
                    { label: 'Stainless Steel', value: 'Stainless Steel_rls190319hm' },
                    { label: '14k Rose Gold', value: '14k Rose Gold_rls190319hm' },
                    { label: 'Gold', value: 'Gold_rls190319hm' },
                    { label: 'Platinum', value: 'Platinum_rls190319hm' },
                    { label: 'Silver', value: 'Silver_rls190319hm' },
                    { label: 'White Gold', value: 'White Gold_rls190319hm' },
                    { label: 'White Gold Lanier', value: 'White Gold Lanier_rls190319hm' },
                    { label: 'White Gold Matte', value: 'White Gold Matte_rls190319hm' },
                    { label: 'Rose Gold', value: 'Rose Gold_rls190319hm' },
                    { label: 'Rose Gold Lanier', value: 'Rose Gold Lanier_rls190319hm' },
                    { label: 'Rose Gold Matte', value: 'Rose Gold Matte_rls190319hm' },
                    { label: 'Yellow Gold', value: 'Yellow Gold_rls190319hm' },
                    { label: 'Yellow Gold Lanier', value: 'Yellow Gold Lanier_rls190319hm' },
                    { label: 'Yellow Gold Matte', value: 'Yellow Gold Matte_rls190319hm' },
                    { label: 'Titanium', value: 'Titanium_rls190319hm' },
                    { label: 'Black Metal', value: 'Black Metal_rls190319hm' },
                    { label: 'Green Gold', value: 'Green Gold_rls190319hm' },
                ],
            }
        }
        this.options = [
            materials,
            {
                label: 'Gemstones', options: [
                    { label: 'Alexandrite', value: 'Alexandrite_rls190319hm' },
                    { label: 'Amethyst', value: 'Amethyst_rls190319hm' },
                    { label: 'Aquamarine', value: 'Aquamarine_rls190319hm' },
                    { label: 'Black Diamond', value: 'Black Diamond_rls190319hm' },
                    { label: 'Citrine Madeira', value: 'Citrine Madeira_rls190319hm' },
                    { label: 'Citrine', value: 'Citrine_rls190319hm' },
                    { label: 'Diamond Blue', value: 'Diamond Blue_rls190319hm' },
                    { label: 'Diamond Champagne', value: 'Diamond Champagne_rls190319hm' },
                    { label: 'Diamond Yellow', value: 'Diamond Yellow_rls190319hm' },
                    { label: 'Diamond', value: 'Diamond_rls190319hm' },
                    { label: 'Emerald', value: 'Emerald_rls190319hm' },
                    { label: 'Iolite', value: 'Iolite_rls190319hm' },
                    { label: 'Malachite', value: 'Malachite_rls190319hm' },
                    { label: 'Morganite', value: 'Morganite_rls190319hm' },
                    { label: 'Onyx', value: 'Onyx_rls190319hm' },
                    { label: 'Opal Chatham', value: 'Opal Chatham_rls190319hm' },
                    { label: 'Opal White', value: 'Opal White_rls190319hm' },
                    { label: 'Peridot', value: 'Peridot_rls190319hm' },
                    { label: 'Quartz Green', value: 'Quartz Green_rls190319hm' },
                    { label: 'Quartz Lemon', value: 'Quartz Lemon_rls190319hm' },
                    { label: 'Quartz Smoky', value: 'Quartz Smoky_rls190319hm' },
                    { label: 'Ruby', value: 'Ruby_rls190319hm' },
                    { label: 'Ruby Madagascar', value: 'Ruby Madagascar_rls190319hm' },
                    { label: 'Sapphire Blue', value: 'Sapphire Blue_rls190319hm' },
                    { label: 'Sapphire BlueGreen', value: 'Sapphire BlueGreen_rls190319hm' },
                    { label: 'Sapphire Padparadscha', value: 'Sapphire Padparadscha_rls190319hm' },
                    { label: 'Sapphire Pink', value: 'Sapphire Pink_rls190319hm' },
                    { label: 'Sapphire Yellow', value: 'Sapphire Yellow_rls190319hm' },
                    { label: 'Tanzanite', value: 'Tanzanite_rls190319hm' },
                    { label: 'Topaz London Blue', value: 'Topaz London Blue_rls190319hm' },
                    { label: 'Topaz Passion Ice Blue', value: 'Topaz Passion Ice Blue_rls190319hm' },
                    { label: 'Topaz Sky Blue', value: 'Topaz Sky Blue_rls190319hm' },
                    { label: 'Topaz Swiss Blue', value: 'Topaz Swiss Blue_rls190319hm' },
                    { label: 'Tourmaline Blue Paraiba', value: 'Tourmaline Blue Paraiba_rls190319hm' },
                    { label: 'Tourmaline Bronze', value: 'Tourmaline Bronze_rls190319hm' },
                    { label: 'Tourmaline Chrome', value: 'Tourmaline Chrome_rls190319hm' },
                    { label: 'Tourmaline Earth', value: 'Tourmaline Earth_rls190319hm' },
                    { label: 'Tourmaline Golden', value: 'Tourmaline Golden_rls190319hm' },
                    { label: 'Tourmaline Green', value: 'Tourmaline Green_rls190319hm' },
                    { label: 'Tourmaline Olive', value: 'Tourmaline Olive_rls190319hm' },
                    { label: 'Jade', value: 'Jade_rls190319hm' },
                ],
            },
            {
                label: 'Pearls', options: [
                    { label: 'Pearl Akoya Black', value: 'Pearl Akoya Black_rls190319hm' },
                    { label: 'Pearl Golden', value: 'Pearl Golden_rls190319hm' },
                    { label: 'Pearl Lavender', value: 'Pearl Lavender_rls190319hm' },
                    { label: 'Pearl Peach', value: 'Pearl Peach_rls190319hm' },
                    { label: 'Pearl Pink', value: 'Pearl Pink_rls190319hm' },
                    { label: 'Pearl Tahitian Chocolate', value: 'Pearl Tahitian Chocolate_rls190319hm' },
                    { label: 'Pearl Tahitian', value: 'Pearl Tahitian_rls190319hm' },
                    { label: 'Pearl White', value: 'Pearl White_rls190319hm' },
                ],
            },
            {
                label: 'Glass', options: [
                    { label: 'Aqua Glass', value: 'Aqua Glass_rls190319hm' },
                    { label: 'Black Glass', value: 'Black Glass_rls190319hm' },
                    { label: 'Brown Glass', value: 'Brown Glass_rls190319hm' },
                    { label: 'Clear Glass', value: 'Clear Glass_rls190319hm' },
                    { label: 'Green Dark Glass', value: 'Green Dark Glass_rls190319hm' },
                    { label: 'Green Glass', value: 'Green Glass_rls190319hm' },
                    { label: 'Grey Glass', value: 'Grey Glass_rls190319hm' },
                    { label: 'Maroon Glass', value: 'Maroon Glass_rls190319hm' },
                    { label: 'Orange Glass', value: 'Orange Glass_rls190319hm' },
                    { label: 'Pink Glass', value: 'Pink Glass_rls190319hm' },
                    { label: 'Purple Glass', value: 'Purple Glass_rls190319hm' },
                    { label: 'Red Glass', value: 'Red Glass_rls190319hm' },
                    { label: 'Yellow Glass', value: 'Yellow Glass_rls190319hm' },
                ],
            },
            {
                label: 'Plastic', options: [
                    { label: 'Aqua Plastic', value: 'Aqua Plastic_rls190319hm' },
                    { label: 'Black Plastic', value: 'Black Plastic_rls190319hm' },
                    { label: 'Blue Plastic', value: 'Brown Glass_rls190319hm' },
                    { label: 'Green Plastic', value: 'Green Glass_rls190319hm' },
                    { label: 'Grey Plastic', value: 'Grey Glass_rls190319hm' },
                    { label: 'Maroon Plastic', value: 'Maroon Glass_rls190319hm' },
                    { label: 'Orange Plastic', value: 'Orange Glass_rls190319hm' },
                    { label: 'Pink Plastic', value: 'Pink Glass_rls190319hm' },
                    { label: 'Purple Plastic', value: 'Purple Glass_rls190319hm' },
                    { label: 'Red Plastic', value: 'Red Glass_rls190319hm' },
                    { label: 'White Plastic', value: 'White Glass_rls190319hm' },
                    { label: 'Yellow Plastic', value: 'Yellow Glass_rls190319hm' },
                ],
            },
        ];
    }
    remove = (index) => {
        let data = this.state.data;
        data.splice(index, 1);
        Global.styleBoxes[this.props.index].layers.splice(index, 1)
        this.setState({
            data,
        });
    };
    add = () => {
        let name = "layer 0"+(this.state.data.length+1);
        if (this.state.data.length+1>9){
            name = "layer "+(this.state.data.length+1);
        }
        Global.styleBoxes[this.props.index].layers[this.state.data.length] = {layerName:name,layerValue:''};
        let data = this.state.data;
        //data.push({layerName:"layer 0"+(this.state.data.length+1),layerValue:''});
        this.setState({
            data,
        }, () => {
           this.props.finishGuide();
        });
    };
    render() {
        return (
          <div className="materials">
              <div className="stylebox-subtitle">
                  <span>
                      <Translate text="materials" />
                  </span>
                  <Translator>
                      {({ translate }) => (
                          <a
                              data-for='set-mat-3'
                              className="tooltip-wrap"
                              data-tip={translate({
                                  text: 'In this block you can add one or some',
                              })}
                          >
                              <Icon type="help"/>
                          </a>
                      )}
                  </Translator>
                  <ReactTooltip globalEventOff='click' place="left" id='set-mat-3'/>
              </div>
              <div className="materials-col">
                  {this.state.data.map((item ,index) =>{
                      return (
                          <StyleBoxItem
                              key={index}
                              index={index}
                              finishGuide={this.props.finishGuide}
                              type={this.props.type}
                              value={item}
                              options={this.options}
                              styleBox={this.props.index}
                              dataCount={this.state.data.length}
                              removeItem={this.remove}
                              incrementStepIndex={this.props.incrementStepIndex}
                          />
                      )
                  })}
              </div>

              <div className="add-new-material">
                  <Button type="button"
                          theme="none"
                          iconLeft="plus"
                          iconLeftSize={11}
                          title="Add new layer"
                          onClick={this.add}
                  />
              </div>
          </div>
        )
    }
}
