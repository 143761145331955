import React, { PureComponent } from 'react';
import './RequestModel.scss';
import ReactTooltip from 'react-tooltip';
import {Translate, Translator} from "react-translated";
import UserService from "../UserService";
import Api from "../Api";
import PubSub from "pubsub-js";
import Global from "../Global";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";
import Field from "../global/Field/Field";
import VerticalSpace from "../global/VerticalSpace";


export default class RequestModel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectorFiles: [],
            error:"minimum 10 characters",
            text:"",
            loading:false,
        };
        this.getFiles = this.getFiles.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
    }

    getFiles (selectorFiles) {
        this.setState({
            selectorFiles: [...this.state.selectorFiles, ...selectorFiles]
        });
    };

    deleteSelected (item){
        for (let key in this.state.selectorFiles) {
            if (this.state.selectorFiles[key] === item){
                let updatedFiles = this.state.selectorFiles;
                updatedFiles.splice(key, 1);
                this.setState({
                    selectorFiles: updatedFiles
                });
            }
        }
    }

    handleUserInput (e) {
        const value = e.target.value;
        if(value.length < 10){
            this.setState({
                error:"minimum 10 characters",
                text:value,
            })
        } else{
            this.setState({
                error:"",
                text:value,
            })
        }
    }

    send = ()=>{
        if(this.state.error === "" && this.state.text!==""){
            this.setState({loading:true});
            var formData = new FormData();
            this.state.selectorFiles.map((item)=>{
                formData.append('files[]',item);
            });
            formData.append('text',this.state.text);
            formData.append('user',UserService.id);
            Api.apiCall("post","requestModel",formData).then((resp)=>{
                this.setState({loading:false});
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    Global.successMessage = "modelThankyouDescrption";
                    PubSub.publish("redirect.to","/success-message");
                    //PubSub.publish('toast.error',resp.data.success);
                }
            });
        } else if(this.state.value!==""){
            PubSub.publish('toast.error',"Please fill description field");
        }
    };

    render(){
        return(
            <div className="row align-center">
                <div className="column small-8">
                    <div className="flex-container request-model">
                        <div className="request-model-left">
                            <div className="request-model-title">
                                <Translate text="Request for a 3D model" />
                            </div>
                            <div className="request-model-desc">
                                <Translate text="Please, describe the model of a jewelry piece that you would like us to create and/or attach photos or files that will provide us with more detailed information." />
                            </div>
                            <Field label="description"
                                   textArea={true}
                                   name="text"
                                   onChange={(event) => this.handleUserInput(event)}
                                   placeholder="Describe the jewelry model..."
                                   errorMsg={this.state.error}
                                   onEnter={()=>{}}
                            />
                            <div className="field-item">
                                <div className="flex-container align-middle">
                                    <label>
                                        <Translate text="Attach files" />
                                    </label>
                                    <Translator>
                                        {({ translate }) => (
                                            <a
                                                data-for='attach-files'
                                                className="tooltip-wrap"
                                                data-tip={translate({
                                                    text: 'Types of files allowed: .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd, .3dm',
                                                })}
                                            >
                                                <Icon type="arrow-help"/>
                                            </a>
                                        )}
                                    </Translator>
                                    <ReactTooltip globalEventOff='click' place="right" id='attach-files'/>
                                </div>
                                {this.state.selectorFiles.length ? (
                                    <div className="field-input-custom">
                                        {this.state.selectorFiles.map((item)=>{
                                            return (
                                                <div className="attached-file" key={`${item.name}${Math.random()}`}>
                                                    <span className="Select-value-label">
                                                        {item.name}
                                                    </span>
                                                    <i className="Select-value-icon" onClick={()=> this.deleteSelected(item)}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ):null}
                                <div className="choose">
                                    <input type="file"
                                           id="choose-files"
                                           multiple
                                           accept=".3dm .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt"
                                           onChange={(e) => {
                                               this.getFiles(Object.values(e.target.files));
                                           }}
                                           onClick={e => {
                                               e.target.value = null
                                           }}
                                    />
                                    <label htmlFor="choose-files" className="choose-files">
                                        <Icon type="plus"/>
                                        <span>
                                            <Translate text="Choose files" />
                                        </span>
                                    </label>
                                </div>
                                <div className="flex-container align-right">
                                    <VerticalSpace size={22}/>
                                    <Button type="button"
                                            theme="purple"
                                            title="Send request"
                                            onClick={this.send}
                                            loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="request-model-right">
                            <img src={require("../../assets/img/img-98.svg")} alt=""/>
                            <div className="request-model-txt">
                                <Translate text="Professional modelers certified by Rendline on the basis of an examination on testing jewelry production knowledge, 3D-printing skills and creating files according to Rendline's standards will contact you within 24 hours." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}