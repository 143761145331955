import React from 'react';
import PropTypes from 'prop-types';

const VerticalSpace = (props) => {
    return(
        <div style={{height: props.size}}/>
    )
};

VerticalSpace.propTypes = {
    size: PropTypes.number.isRequired,
};

export default VerticalSpace;