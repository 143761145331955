import React, { Component } from 'react';
import './Billing.scss';
import Api from "../Api";
import { Translate } from 'react-translated';
import PubSub from "pubsub-js";
import Button from "../global/Button/Button";
var moment = require('moment');


export default class BillingPlan extends Component {
    constructor(props){
        super(props);
        this.state={
            plan:{
                name:"",
                images:0,
                videos:0,
                price:0,
            },
            type:false,
            loadingBtn:false,
            id:this.props.match.params.id
        };
        this.loadData();
    }
    checkType=(event)=>{
        this.setState({
            type:event.target.value,
        })
    };

    loadData =()=>{
        Api.apiCall("get","getPlanx/"+this.state.id,{}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                PubSub.publish("redirect.to","/choose-plan");
            }else {
                this.setState({
                    plan:resp.data,
                })
            }
        })
    };
    purchase = ()=>{
        if(this.state.type){
            this.setState({loadingBtn:true});
            Api.apiCall("post","purchasePlanx",{id:this.state.id,type:this.state.type}).then((resp)=>{
                if (resp.status !== 200) {
                    // console.log(resp.status);
                    this.setState({loadingBtn:false});
                }
                if ('error' in resp.data) {
                   this.setState({loadingBtn:false});
                }else {
                    window.location.replace('https://payments.ameriabank.am/forms/frm_paymentstype.aspx?lang=(Interface language)&paymentid='+resp.data.success)
                }
            })
        } else{
            PubSub.publish('toast.notif','Please select payment method');
        }

    };
    render(){
        return(
            <div className="row align-spaced">
                <div className="column small-10">
                    <div className="row align-center">
                        <div className="column billing-col" style={{paddingBottom:100}}>
                            <div className="text-center">
                                <div className="page-title">
                                    <Translate text="Purchase Confirmation" />
                                </div>
                            </div>
                            <div className="billing content-wrapper">
                                <div className="billing-main">
                                    <div className="billing-table">
                                        <div className="table-in">
                                            <div className="table-a">
                                                <div>
                                                    <Translate text="name" />
                                                </div>
                                                <div>
                                                    <Translate text="images" />
                                                </div>
                                                <div>
                                                    <Translate text="videos" />
                                                </div>
                                                <div>
                                                    <Translate text="total" />
                                                </div>
                                            </div>
                                            <div className="table-b">
                                                <div>
                                                    {this.state.plan.name}
                                                </div>
                                                <div>
                                                    {this.state.plan.images}
                                                </div>
                                                <div>
                                                    {this.state.plan.videos}
                                                </div>
                                                <div>
                                                    ${this.state.plan.price}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="promo-block flex-container align-middle align-justify">*/}
                                        {/*<div className="promo-block-text">*/}
                                            {/*<Translate text="Gift Card" />*/}
                                        {/*</div>*/}
                                        {/*<div className="promo-block-count">*/}
                                            {/*-$75*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    <div className="payment-method">
                                        <div className="payment-title">
                                            <Translate text="payment method" />
                                        </div>
                                        <div className="payment-types">
                                            <label className="payment-type-item">
                                                <input type="radio" name="payment" value="1" onChange={this.checkType}/>
                                                <span className="checkmark"/>
                                                <div className="label-main">
                                                    <i>
                                                        <img src={require("../../assets/img/credit-card.svg")} alt=""/>
                                                    </i>
                                                    <span>
                                                        <Translate text="credit & debit cards" />
                                                    </span>
                                                </div>
                                            </label>
                                            <label className="payment-type-item">
                                                <input type="radio" name="payment" value="2" onChange={this.checkType} />
                                                <span className="checkmark"/>
                                                <div className="label-main">
                                                    <img src={require("../../assets/img/paypal.png")} alt=""/>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="billing-total-info">
                                    <div className="billing-date">
                                        <i><img src={require("../../assets/img/date-icon.svg")} alt=""/></i>
                                        <span>
                                            {moment().format("D MMM YYYY")}
                                        </span>
                                    </div>
                                    <div className="total-billing-price">
                                        <div className="text">
                                            <Translate text="total price" />:
                                        </div>
                                        <div className="main">
                                            ${this.state.plan.price}
                                        </div>
                                        {/*<div className="price-per-image">*/}
                                            {/*$2/image*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="billing-controls">
                                <Button type="button"
                                        theme="border-white"
                                        iconLeft="arrow-left"
                                        title="back"
                                        link="/choose-plan"
                                />
                                <Button type="button"
                                        theme="purple"
                                        title="proceed to checkout"
                                        onClick={this.purchase}
                                        loading={this.state.loadingBtn}
                                        iconRight="arrow-right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}