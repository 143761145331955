import React, { PureComponent } from 'react';
import { Translate } from 'react-translated';
//import PubSub from 'pubsub-js';
import './Footer.scss';

const languages = [
    {
        code: 'arm',
        name: 'Հայերեն',
    },
    {
        code: 'ru',
        name: 'Русский',
    },
    {
        code: 'ch',
        name: 'Chinese',
    },
    {
        code: 'en',
        name: 'English',
    },
];

export default class Footer extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            popupVisible:false,
        };
        this.handlePopUpClick = this.handlePopUpClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }
    handlePopUpClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    changeLang = (lang)=>{
        localStorage.setItem('lang', lang);
        window.location.reload();
    };

    handleOutsideClick() {
        this.handlePopUpClick();
    }
    render() {
        const {
            popupVisible,
        } = this.state;

        const currentLanguageCode = localStorage.getItem('lang') || 'en';

          return (
              <footer>
                  <div className="row align-justify footer-main">
                      <div className="column">
                          <div id="footer-left">
                              <a href="https://rendline.com/" id="footer-logo">
                                  <img src={require('../../../assets/img/logo_mini.svg')} alt="Rendline" title="Rendline"/>
                              </a>
                              <div className="footerleftinfo">
                                  <div>
                                      © RendLine,
                                      {" "+ new Date().getFullYear()+" "}
                                      <Translate text="All rights reserved." />
                                  </div>
                                  <div className="flex-container">
                                      <a href="https://rendline.com/en/terms-and-privacy-conditions" id="terms" target="_blank" rel="noopener noreferrer">
                                          <Translate text="terms & conditions" />
                                      </a>
                                      <a href="https://rendline.com/en/about-us" id="about" target="_blank" rel="noopener noreferrer">
                                          <Translate text="about us" />
                                      </a>
                                      <div id="lang">
                                          <div id="main-lang" onClick={this.handlePopUpClick}>
                                              {languages.find(language => language.code === currentLanguageCode).name}
                                          </div>
                                          {popupVisible && (
                                              <div id="lang-drop">
                                                  {languages
                                                      .filter(language => language.code !== currentLanguageCode)
                                                      .map(language => {
                                                          return <div key={language.code} onClick={()=>this.changeLang(language.code)}>
                                                              {language.name}
                                                          </div>;
                                                      })
                                                  }
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="column medium-flex-child-shrink">
                          <div id="footer-socials" className="flex-container align-middle align-center">
                                <a href="https://www.facebook.com/RendLine-972678136203496/" title="Facebook" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../../../assets/img/facebook.svg')} alt="Facebook" title="Facebook"/>
                                </a>
                                <a href="https://www.instagram.com/rendlineofficial/" title="instagram" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../../../assets/img/instagram.svg')} alt="Instagram" title="Instagram"/>
                                </a>
                                <a href="https://www.pinterest.com/rendline0041/" title="Pinterest" target="_blank" rel="noopener noreferrer">
                                  <img src={require('../../../assets/img/pinterest.svg')} alt="Pinterest" title="Pinterest"/>
                                </a>
                          </div>
                      </div>
                      <div className="column text-right">
                          <div id="footer-right">
                              <Translate text="main-address" />
                              <div>
                                  info@rendline.com
                              </div>
                          </div>
                      </div>
                  </div>
              </footer>
          )
    }
}
