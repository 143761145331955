import React, {Component} from "react";
import Api from "../Api";
import PubSub from "pubsub-js";
import {Translate} from "react-translated";
import Button from "../global/Button/Button";
import Modal from "react-modal";
import Icon from "../global/Icon/Icon";

export default class VideoSceneItem extends Component {
    constructor(props){
        super(props);
        this.state={
            modalIsOpen: false,
        }
    }
    toggleModal = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen,
        });
    };
    addScene=(scene)=>{
        if (this.props.projectId){
            this.setState({loading:true});
            Api.apiCall("post","projectScene",{scene:"Scene_"+scene,id:this.props.projectId}).then((resp)=>{
                this.setState({loading:false});
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    PubSub.publish("redirect.to","/project/video/"+this.props.projectId+"/upload-rules-step");
                }
            })
        }
    };
    hoverOn = () =>{
        this.refs.vidRef.play();

    };
    hoverOff = () =>{
        this.refs.vidRef.currentTime = 0;
        this.refs.vidRef.pause();
    };
    render(){
        let videos=[
            "285246631",
            "285246588",
            "285479589",
            "285246621",
            "302271010",
            "321698332",
        ];
        return(
            <div className="column small-4">
                <div className="video-scene content-wrapper text-center">
                    <div className="sceneitem-title">
                        {this.props.scene === 5?
                            <span>
                                <Translate text="Beldiamond Scene" />
                            </span>
                            :this.props.scene === 6?
                                <span><Translate text="Scene"/> 5 </span>
                                :<span><Translate text="Scene"/> {this.props.scene} </span>
                        }
                    </div>
                    <div className={this.props.scene === 6?"sceneitem-img new-video":"sceneitem-img"} onClick={this.toggleModal} onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
                        {/* <img src={require("../../assets/img/scenes/scen_1/1.jpg")} alt=""/> */}
                        <video src={require("../../assets/videos/video"+this.props.scene+".mp4")} ref="vidRef"/>
                    </div>
                    <div className="select-btn-wrapper">
                        <Button type="button"
                                theme="purple"
                                iconLeft="check"
                                iconLeftSize={11}
                                title="select this scene"
                                onClick={()=>this.addScene(this.props.scene)}
                        />
                    </div>
                </div>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(18, 25, 45, 0.8)',
                            zIndex:'8'
                        },
                        content: {
                            width:700,
                            left:'calc(50% - 350px)',
                            padding:0,
                            bottom:'initial',
                            overflow:'initial',
                            top:'18%',
                            backgroundColor:'transparent',
                            border:'0'
                        }
                    }}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.toggleModal}
                    ariaHideApp={false}
                    contentLabel="example"
                >

                    <div className="video-main">
                        <Icon type="delete" className="close-video" onClick={this.toggleModal}/>
                        <iframe src={"https://player.vimeo.com/video/"+videos[this.props.scene-1]+"?autoplay=1&title=0&byline=0&portrait=0"} width="100%" height="420" frameBorder="0" allowFullScreen />
                    </div>
                </Modal>
            </div>

        )
    }
}