import React from 'react';
import PropTypes from 'prop-types';
import Floater from 'react-floater';

class Guide extends React.Component {
    state = {
        activeStep: {},
        activeStepIndex: 0,
        top: 0,
        left: 0,
        openTooltip: false,
    };

    componentDidMount() {
        const {
            steps,
            visible,
        } = this.props;

        if (steps.length && visible) {
            this.jump(0);
        }

        if (visible) {
            document.body.classList.add('disableScroll');
        }
    }

    clearAllGlobalStyles = () => {
        document.body.classList.remove('disableScroll');

        Array.from(document.querySelectorAll('.disablePointerEvents')).forEach(eachNode => {
            eachNode.classList.remove('disablePointerEvents');
        });
    };

    componentWillUnmount() {
        this.clearAllGlobalStyles();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.selectedStep !== nextProps.selectedStep && nextProps.visible) {
            this.setState({
                activeStepIndex: nextProps.selectedStep,
            });
            this.jump(nextProps.selectedStep);
        }

        if (this.props.visible !== nextProps.visible) {
            if (!nextProps.visible) {
                this.clearAllGlobalStyles();
            }
        }
    }

    disablePointerEvents = (node) => {
        Array.from(document.querySelectorAll('*')).forEach(eachNode => {
            eachNode.classList.add('disablePointerEvents');
        });

        Array.from(node.querySelectorAll('*')).forEach(eachNode => {
            eachNode.classList.remove('disablePointerEvents');
        });
        let activeNode = node;
        while (activeNode.parentNode.tagName !== 'HTML') {
            activeNode.classList.remove('disablePointerEvents');
            activeNode = activeNode.parentNode;
        }

        document.body.classList.remove('disablePointerEvents');
        document.getElementsByTagName('html')[0].classList.remove('disablePointerEvents');
    };

    getPositionForEffect = (node, step) => {
        const nodeClientRect = node.getBoundingClientRect();
        const {
            contentPositionX,
            contentPositionY,
        } = this.getStep(step);

        const position = {
            top: (nodeClientRect.top - document.body.scrollTop) + (node.offsetHeight / 2),
            left: (nodeClientRect.left - document.body.scrollLeft) + (node.clientWidth / 2),
        };

        if (typeof contentPositionX !== "undefined") {
            position.left = contentPositionX === "left" ? nodeClientRect.left : nodeClientRect.left + node.clientWidth;
        }

        return position;
    };

    setEffectPosition = (node, callback, step) => {
        setTimeout(() => {
            const effectPosition = this.getPositionForEffect(node, step);
            this.setState(effectPosition, () => {
                callback();
            });
        }, 300);
    };

    getStepNode = (step) => {
        const {
            steps,
        } = this.props;

        return steps.length > step && document.querySelector(steps[step].target);
    };

    tryGetStepNode = (step) => {
        return new Promise((resolve, reject) => {
            let allowTryCount = 50;
            let intervalId = setInterval(() => {
                const node = this.getStepNode(step);

                if (node) {
                    clearInterval(intervalId);
                    resolve(node);
                }
                else {
                    allowTryCount--;
                }

                if (!allowTryCount) {
                    clearInterval(intervalId);
                    reject('Can\'t find node');
                }
            }, 100);
        });
    };

    getStep = (step) => {
        const {
            steps,
        } = this.props;

        return steps[step];
    };

    setStepContent = (step) => {
        const stepData = this.getStep(step);

        this.setState({
            activeStep: stepData,
            activeStepIndex: step,
        });
    };

    jump = (step) => {
        return this.tryGetStepNode(step).then(node => {
            this.disablePointerEvents(node);
            node.scrollIntoView({behavior: 'smooth'});
            this.setState({
                openTooltip: false,
            });

            this.setEffectPosition(node, () => {
                setTimeout(() => {
                    this.setState({
                        openTooltip: true,
                    });
                    this.setStepContent(step);
                }, 300);
            }, step);
        });
    };

    render() {
        const {
            visible,
            steps,
        } = this.props;

        const {
            top,
            left,
            openTooltip,
            activeStepIndex,
        } = this.state;

        if (!visible) {
            return null;
        }

        const activeStep = steps[activeStepIndex];

        return (
            <div className={'Guide'}>
                {visible ? (
                    <div className="tour-wrapper">
                        <div className="tour-effect" id={'tour-effect'}
                             style={{
                                 top: top + 'px',
                                 left: left + 'px',
                             }}
                        >
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}>

                            </div>
                        </div>
                    </div>
                ) : null}

                {openTooltip ? (
                    <Floater
                        target="#tour-effect div"
                        content={activeStep.content || ""}
                        autoOpen={true}
                        placement={'auto'}
                    />
                ) : null}
            </div>
        );
    }
}

Guide.propTypes = {
    visible: PropTypes.bool,
    selectedStep: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.shape({
        target: PropTypes.string.isRequired,
        disableStandardIncrementBehaviour: PropTypes.bool,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        contentPositionX: PropTypes.oneOf(['left', 'right']),
        contentPositionY: PropTypes.oneOf(['top', 'bottom']),
        contentOffsetX: PropTypes.number,
        contentOffsetY: PropTypes.number,
    })).isRequired,
};

export default Guide;
