import React, { Component } from 'react';
//import { render } from 'react-dom';
import {Switch, Route} from 'react-router-dom';
import App from './components/App';
import Header from './components/Layout/Header/Header';
import Footer from './components/Layout/Footer/Footer';
import Unsigned from './components/Unsigned/Unsigned';
import UserService from './components/UserService';
import LinkTo from './components/linkTo';
import SuccesMessage from './components/SuccesMessage/SuccesMessage';
import { ToastContainer } from 'react-toastify';
import UploadRules from './components/UploadRules/UploadRules';
import Examples from './components/Examples/Examples';
import ChoosePlan from './components/ChoosePlan/ChoosePlan';
import Billing from './components/Billing/Billing';
import InvoiceList from './components/InvoiceList/InvoiceList';
//import Invoice from './components/Invoice/Invoice';
import RequestModel from "./components/RequestModel/RequestModel";


export default class Page extends Component {
    constructor(props){
        super(props);
        this.state={
            isAuth: false,
        }
        //console.log(this.props)
    }
    componentDidMount(){
        UserService.auth().then((resp)=>{
            this.setState({isAuth:true})
        })
    }

    render() {
        if (this.state.isAuth) {
            return (
                <div className="page">
                    <Header/>
                    <main>
                        <Switch>
                            <Route path='/success-message/:token/:error' component={SuccesMessage}/>
                            <Route path='/success-message/:token' component={SuccesMessage}/>
                            <Route path='/success-message' component={SuccesMessage}/>
                            <Route path='/examples' component={Examples}/>
                            <Route path='/choose-plan' component={ChoosePlan}/>
                            <Route path='/request-for-3d-model' component={RequestModel}/>
                            <Route path='/invoices' component={InvoiceList}/>
                            <Route path='/billing' component={Billing}/>
                            <Route path='/file-upload-rules' component={UploadRules}/>
                            {
                                UserService.isAuth()?
                                    <Route component={App}/>
                                    :
                                    <Route component={Unsigned}/>
                            }
                        </Switch>
                    </main>
                    <Footer/>
                    <LinkTo/>
                    <ToastContainer autoClose={3000} hideProgressBar={true} position="bottom-left" className="toast-message"/>
                </div>
            )
        } else{
            return null;
        }
    }
}
