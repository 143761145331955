import React, { Component } from 'react';
import './MyProjects.scss';
import {Link} from 'react-router-dom';
import Api from "../Api";
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Translate } from 'react-translated';
import Loading from '../global/Loading/Loading';
//import axios from 'axios';
import PubSub from "pubsub-js";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";
import MyProjectItem from "./MyProjectItem";


export default class MyProjects extends Component {

    constructor(props){
        super(props);
        this.state={
            modalOpen: false,
            projects:[],
            videoModal:false,
            loading:false,
            popupVisible:false,
            presentModal: false,
            tabIndex: 0,
            status:"all"
        };
        this.handlePopUpClick = this.handlePopUpClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.loadData();
    }

    toggleModalVideo = () => {
        this.setState({
            videoModal: !this.state.videoModal,
        });
    };

    togglePresentModal = () => {
        this.setState({
            presentModal: !this.state.presentModal,
        });
    };

    tabsChange =(tabIndex)=>{
        //alert(tabIndex)
        this.setState({
            tabIndex
        })
    };

    loadData=()=>{
        Api.apiCall("get","getProjects",{}).then((resp)=>{
            if (resp.status!==200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
               this.setState({
                   projects:resp.data,
                   count:resp.data.length,
                   loading:true
               })
                //PubSub.publish("redirect.to","/project/"+this.props.projectId+"/file-upload-step");
            }
        })
    };
    handlePopUpClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        this.handlePopUpClick();
    }

    openModal = (type, id) => {
        this.setState({
            modalOpen: true,
            type: type,
            id: id,
        });
    };

    closeModal = () => {
        this.setState({
            modalOpen: false,
        });
    };

    deleteProjectItem=()=>{
        if(this.state.type==='remove') {
            this.remove();
        } else if(this.state.type === 'cancel') {
            this.cancel();
        }
        this.closeModal();
    };
    remove = () => {
        //PubSub.publish("toast.load", "dfasf");
        Api.apiCall("post", "projectDelete", {projectId:this.state.id}).then((resp) => {
            PubSub.publish("toast.success", "Deleted");
            // let project=this.state.project;
            // project.run=0;
            // this.setState({
            //     project,
            // })
            this.loadData();
            PubSub.publish('update.me');
        })

    };
    cancel = () => {
        //PubSub.publish("toast.load", "dfasf");
        Api.apiCall("post", "projectCancel", {projectId:this.state.id}).then((resp) => {
            PubSub.publish("toast.success", "Canceled");
            // let project=this.state.project;
            // project.progress=1;
            // project.run=3;
            // this.setState({
            //     project,
            // })
           /* this.setState({
                projects:[],
            })*/
            this.loadData();
            PubSub.publish('update.me');
        })

    };

    tabClick=()=>{

    }

    render() {
        console.log(this.state.projects)
        return (
            <div className="myprojects">
                <div className="row myprojects-top">
                    <div className={this.state.projects.length ? "column small-12" :" column small-9"}>
                        <div className="row align-justify align-middle">
                            <div className="column">
                                <div className="page-top-left flex-container align-middle">
                                    <div className="page-title">
                                        <Icon type="folder"/>
                                        <Translate text="my projects" />
                                    </div>
                                    <span className="projects-count">
                                        {this.state.count}
                                    </span>
                                </div>
                            </div>
                            <div className="column text-right" style={{marginRight: this.state.projects.length ? 0 : 50}}>
                                {this.state.projects.length ? (
                                    <div className="dropdown-wrapper">
                                        <Button type="button"
                                                theme="green"
                                                iconLeft="plus"
                                                iconLeftSize={11}
                                                title="add project"
                                                iconRight="arrow-bottom"
                                                iconRightSize={7}
                                                onClick={this.handlePopUpClick}
                                        />
                                        {this.state.popupVisible && (
                                            <div className="main-dropdown">
                                                <Link to="/general-settings/photo" className="popupLink">
                                                    <i>
                                                        <img src={require("../../assets/img/photo-add.svg")} alt=""/>
                                                    </i>
                                                    <span>
                                                        <Translate text="Add Photo Rendering" />
                                                    </span>
                                                </Link>
                                                <Link to="/general-settings/video" className="popupLink">
                                                    <i>
                                                        <img src={require("../../assets/img/video-add.svg")} alt=""/>
                                                    </i>
                                                    <span>
                                                        <Translate text="Add video Rendering" />
                                                    </span>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                ):null}
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.loading ? (
                    <Loading/>
                ):(
                    <div>
                        {this.state.projects.length ? (
                            <div className="row">
                                <div className="column small-12 text-center">
                                    <div className="plan-tabs">
                                        <Tabs selectedIndex={this.state.tabIndex}  onSelect={this.tabsChange}>
                                            <TabList>
                                                <Tab >
                                                    <span>
                                                        All
                                                    </span>
                                                </Tab>
                                                <Tab>
                                                    <span>
                                                        not paid
                                                    </span>
                                                </Tab>
                                                <Tab>
                                                    <span>
                                                        in progress
                                                    </span>
                                                </Tab>
                                                <Tab>
                                                    <span>
                                                        completed
                                                    </span>
                                                </Tab>
                                                <Tab>
                                                    <span>
                                                        canceled
                                                    </span>
                                                </Tab>
                                            </TabList>
                                            <div className="column small-12">
                                                <div className="project-item-list">
                                                    {this.state.projects.map((item,i)=>{
                                                        if(this.state.tabIndex===0 || (this.state.tabIndex===3 && item.progress===1 && item.run===1) || (this.state.tabIndex===4 && item.run===3) || (this.state.tabIndex===2 && item.progress===0) || (this.state.tabIndex===1 && item.progress===1 && item.run===2)){
                                                            return <MyProjectItem key={i} project={item} openModal={this.openModal}/>
                                                        }
                                                    }
                                                    )}
                                                    <TabPanel>
                                                    </TabPanel>
                                                </div>
                                            </div>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className="column small-12">
                                    <div className="get-models-block" style={{alignItems:'center', height: this.state.projects.length ? 160 : 400, flexFlow: this.state.projects.length ? "row" : "column"}}>
                                        <div className="get-models-title">
                                            <Translate text="Don't you have a" />
                                            &nbsp;
                                            <b>
                                                <Translate text="3d model" />
                                            </b>
                                        </div>
                                        <Button theme="green-back"
                                                rounded={true}
                                                link="/request-for-3d-model"
                                                title="order now"
                                        />
                                    </div>
                                </div>
                            </div>
                        ):(
                            <div className="row align-justify">
                                <div className="column">
                                    <div className="flex-container align-justify">
                                        <Link to="/general-settings/photo" className="add-project-block text-center">
                                            <div className="add-project-icon">
                                                <img src={require("../../assets/img/photo-icon.png")} alt=""/>
                                            </div>
                                            <div className="add-project-title">
                                                <Translate text="Photo Rendering" />
                                            </div>
                                            <div className="add-project-desc">
                                                <Translate text="Give life to your 3D models. Select an appropriate scene, materials and views for your 3D model and get renderings of incredible quality." />
                                            </div>
                                            <Button theme="purple"
                                                    type="button"
                                                    title="add new project"
                                            />
                                        </Link>
                                        <Link to="/general-settings/video" className="add-project-block text-center">
                                            <div className="add-project-icon">
                                                <img src={require("../../assets/img/video-icon.png")} alt=""/>
                                            </div>
                                            <div className="add-project-title">
                                                <Translate text="Video Rendering" />
                                            </div>
                                            <div className="add-project-desc">
                                                <Translate text="Admire your product in the motion of 360. Select an appropriate scene and materials for your 3D model. Enjoy your high-quality video renderings." />
                                            </div>
                                            <Button theme="purple"
                                                    type="button"
                                                    title="add new project"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="column small-3" style={{marginLeft:50}}>
                                    <div className="get-models-block">
                                        <div className="get-models-title">
                                            <Translate text="Don't you have a" />
                                            &nbsp;
                                            <b>
                                                <Translate text="3d model" />
                                            </b>
                                        </div>
                                        <Button theme="green-back"
                                                rounded={true}
                                                responsive={true}
                                                link="/request-for-3d-model"
                                                title="order now"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                    <div className="row">
                        <div className={this.state.projects.length ? "column small-12 text-center" : "column small-9 text-center"}>
                            <div className="help-text" style={{marginRight:this.state.projects.length ? 0 : 50}}>
                                <Translate text="Need some help?" />
                                <span className="watch-video" onClick={this.toggleModalVideo}>
                                    <Translate text="Watch this video" />
                                </span>
                                {/*or visit our*/}
                                {/*<Link to="">*/}
                                {/*FAQ.*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>

                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(18, 25, 45, 0.8)',
                            zIndex:'8'
                        },
                        content: {
                            width:700,
                            left:'calc(50% - 350px)',
                            padding:0,
                            bottom:'initial',
                            overflow:'initial',
                            top:'18%',
                            backgroundColor:'transparent',
                            border:'0'
                        }
                    }}
                    isOpen={this.state.videoModal}
                    onRequestClose={this.toggleModalVideo}
                    ariaHideApp={false}
                    contentLabel="example"
                >

                    <div className="video-main">
                        <Icon type="delete" className="close-video" onClick={this.toggleModalVideo}/>
                        <iframe src="https://player.vimeo.com/video/269578373?title=0&byline=0&portrait=0" width="100%" height="420" frameBorder="0" webkitallowfullscreen="1" mozallowfullscreen="1" allowFullScreen/>
                    </div>
                </Modal>

                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(18, 25, 45, 0.8)',
                            zIndex:'8'
                        },
                        content: {
                            width:586,
                            left:'calc(50% - 293px)',
                            padding:0,
                            bottom:'initial',
                            overflow:'initial',
                            top:'18%',
                            backgroundColor:'white',
                            border:'0'
                        }
                    }}
                    isOpen={this.state.presentModal}
                    onRequestClose={this.togglePresentModal}
                    ariaHideApp={false}
                    contentLabel="example"
                >

                    <div className="modal-inner">
                        <div className="close-modal" onClick={()=>{this.togglePresentModal()}}>
                            <Icon type="delete"/>
                        </div>
                        <div className="sale-text">
                            Until March 5
                        </div>
                        <div className="sale">
                            <img src={require("../../assets/img/100-sale.png")} alt="100 dollar gift" title="100 dollar gift"/>
                        </div>
                        <div className="sale-title">
                            siruna axper ashotik
                        </div>
                        <div className="sale-description">
                            Sirun anush textik lorem ipsum ipsumik sirun pupush balik ipsumik
                        </div>
                        <i className="left-brilliant">
                            <img src={require("../../assets/img/left-brilliant.png")} alt="Brilliant" title="Brilliant"/>
                        </i>
                        <i className="right-brilliant">
                            <img src={require("../../assets/img/right-brilliant.png")} alt="Brilliant" title="Brilliant"/>
                        </i>
                    </div>
                </Modal>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            zIndex:'8'
                        },
                        content: {
                            width:458,
                            left:'calc(50% - 229px)',
                            bottom:'initial',
                            padding:0,
                            top:'30%',
                            overflow:'hidden',
                            border:0,
                            backgroundColor:'#1c243e'
                        }
                    }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                    <div className="modal-header">
                        <div className="modal-title">
                            <Translate text="StyleBox Message" />
                        </div>
                        <div className="close-modal" onClick={this.closeModal}>
                            <Icon type="delete"/>
                        </div>
                    </div>
                    <div className="modal-content">
                        {/*<Translate text="Are you sure you want to close the stylebox ?" />*/}
                        <Translate text={this.state.type === 'remove' ? 'Are you sure to delete this item?':'Are you sure to cancel this item?'} />
                    </div>
                    <div className="modal_actions">
                        <div className="row">
                            <div className="column small-6">
                                <Button type="button"
                                        theme="purple"
                                        title="no"
                                        responsive={true}
                                        onClick={this.closeModal}
                                />
                            </div>
                            <div className="column small-6">
                                <Button type="button"
                                        theme="dark"
                                        title="yes"
                                        responsive={true}
                                        onClick={this.deleteProjectItem}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
