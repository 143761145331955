import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import UserService from '../UserService';
import PubSub from 'pubsub-js';
import { Translate } from 'react-translated';
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Api from "../Api";
import Button from "../global/Button/Button";
import Field from "../global/Field/Field";


export default class SignIn extends Component {
    constructor (props) {
    super(props);
        this.state = {
            email: '',
            password: '',
            formErrors: {email: '', password: ''},
            emailValid: false,
            passwordValid: false,
            formValid: false,
            loading:false,
            errorText:'',
        };
        UserService.logOut();
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, 
        () => { this.validateField(name, value) });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
      
        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : 'is invalid';
            break;
          case 'password':
            passwordValid = value.length >= 6;
            fieldValidationErrors.password = passwordValid ? '': 'is too short';
            break;
          default:
            break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid
        }, this.validateForm);
    }
      
    validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.passwordValid});
    }

    login=(e)=>{
        e.preventDefault();
        this.setState({loading:true});
        UserService.getToken(this.state.email,this.state.password).then((resp)=>{
            this.setState({loading:false});
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                if (UserService.isAuth()){
                    PubSub.publish("redirect.to","/my-projects")
                }
            }

        })
    };

    googleSignup = (res)=>{
        Api.apiCall("post","registerSocial", {
            token:res.accessToken,
            type:1,
        }).then(resp=>{
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                UserService.auth(resp.data.success, resp.data.token).then(()=>{
                    PubSub.publish("redirect.to","/");
                });
            }
        });
    };

    facebookSignup=(res)=>{
        Api.apiCall("post","registerSocial", {
            token:res.accessToken,
            type:2,
        }).then(resp=>{
            if ('error' in resp.data) {
                //console.log(resp.data.error)
                PubSub.publish('toast.error',resp.data.error);
            }else {
                UserService.auth(resp.data.success, resp.data.token).then(()=>{
                    PubSub.publish("redirect.to","/");
                });
            }
        });
    };


    render() {
        return (
            <div className="unsigned text-center">
                <form className="unsigned-form" onSubmit={this.login}>
                    <div className="unsigned-title">
                        <Translate text="Hello and welcome back" />
                    </div>
                    <Field label="E-mail"
                           type="mail"
                           placeholder="Enter your Email"
                           required={true}
                           value={this.state.email}
                           name="email"
                           onChange={(event) => this.handleUserInput(event)}
                           errorMsg={this.state.formErrors.email}
                           onEnter={this.send}
                    />
                    <Field label="Password"
                           suffix="/forgot-password"
                           type="password"
                           placeholder="Enter your Password"
                           required={true}
                           value={this.state.password}
                           name="password"
                           onChange={(event) => this.handleUserInput(event)}
                           errorMsg={this.state.formErrors.password}
                           showPassword={true}
                           onEnter={this.send}
                    />
                    <Button type="submit"
                            theme="green"
                            title="log in"
                            responsive={true}
                            onClick={this.send}
                            loading={this.state.loading}
                            disabled={!this.state.formValid}
                    />
                </form>
                <div className="unsigned-info">
                    <div className="unsigned-txt">
                        <Translate text="Don’t have an account?" />
                        <Link to="/signup">
                            <Translate text="sign up" />
                        </Link>
                    </div>
                    <div className="unsigned-btn-group">
                        <span>Log in with</span>
                        <div>
                            <FacebookLogin
                                appId="1109237459282452"
                                callback={this.facebookSignup}
                                fields="name,email,picture"
                                textButton={<Fragment><i className="icon icon-facebook"/><span>facebook</span></Fragment>}
                                cssClass="btn btn-border-white btn-icon-left"
                            />
                            <GoogleLogin
                                clientId="371289436494-fdv0nf2461d8ot4j39jv6q1544crs6hq.apps.googleusercontent.com"
                                buttonText={<Fragment><i className="icon icon-g-plus"/><span>gmail</span></Fragment>}
                                onSuccess={this.googleSignup}
                                onFailure={(res)=>console.log(res)}
                                className="btn btn-border-white btn-icon-left"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
