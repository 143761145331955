import React, { Component } from 'react';
import Api from "../../Api";
import PubSub from 'pubsub-js';
import Button from "../../global/Button/Button";
import Field from "../../global/Field/Field";

export default class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOption: '',
            old_pass:'',
            password:'',
            password2:'',
            loading:false,
        }
    }

    handleChange = (val,name) => {
        let value = val.target.value;
        let variable=this.state;
        variable[name] = value;
        this.setState({
            ...variable,
        })
    };

    save=(e)=>{
        e.preventDefault();

        let data = {
            old_pass:this.state.old_pass,
            password:this.state.password,
        };
        if (this.state.password === this.state.password2){
            this.setState({
                loading:true
            });
            Api.apiCall("post","changePass",data).then((resp)=>{
                this.setState({
                    loading:false
                });
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    PubSub.publish('toast.success',"Changes successfully saved");
                }
            })
        } else{
            PubSub.publish('toast.error',"Passwords does not match");
        }

    };


    render() {
        return (
            <div className="content-wrapper change-profile">
                <form action="" onSubmit={this.save}>
                    <Field label="Current password"
                           theme={true}
                           type="password"
                           onChange={(opt)=>this.handleChange(opt,"old_pass")}
                           onEnter={this.save}
                    />

                    <Field label="New password"
                           theme={true}
                           type="password"
                           onChange={(opt)=>this.handleChange(opt,"password")}
                           onEnter={this.save}
                    />

                    <Field label="Confirm password"
                           theme={true}
                           type="password"
                           value={this.state.password2}
                           onChange={(opt)=>this.handleChange(opt,"password2")}
                           onEnter={this.save}
                    />

                    <Button type="submit"
                            theme="purple"
                            title="save"
                            responsive={true}
                            loading={this.state.loading}
                    />
                </form>
            </div>
        )
    }
}
