import React, {Component} from "react";
import PubSub from "pubsub-js";
import Api from "../Api";
import Icon from "../global/Icon/Icon";
import {Translate} from "react-translated";
import Global from "../Global";
import {Link} from "react-router-dom";

export default class MyProjectItem extends Component {
    constructor(props){
        super(props);
        this.state={
            project: {},
        }
    }

    download = ()=>{
        PubSub.publish("toast.load", "Wait for download to complete");
        Api.apiCall("post","downloadFile",{projectId:this.props.project.id}).then((resp)=>{
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.props.project.name.replace(/ /g, '_')+".zip");
            document.body.appendChild(link);
            link.click();
            PubSub.publish("toast.dismiss","");
        })
    };

    render(){
        this.state.project=this.props.project;

        let progress  ="";
        if (this.state.project.progress){
            progress  ="ended";
        }
        return(
            <div className={"project-item content-wrapper inprogress "+progress}>
                {this.state.project.progress !== 0?
                    <Icon type="delete" onClick={()=>this.props.openModal('remove', this.state.project.id)}/>
                    :
                    ''
                }

                <div className="row align-justify align-middle">
                    <div className="column small-5 text-left">
                        <div className="projectitem-name" >
                            {this.state.project.name}
                        </div>
                        <div className="projectitem-date">
                            {this.state.project.created_at.split(" ")[0]}
                        </div>
                    </div>
                    <div className="column small-7">
                        <div className="flex-middle align-right">
                            {this.state.project.progress === 1 && this.state.project.run === 1?
                                <div className="projectitem-status">
                                    <Translate text="ended" />
                                </div>
                                :this.state.project.progress === 1 && this.state.project.run === 2?
                                    <div className="projectitem-status">
                                        <Translate text="not paid" />
                                    </div>
                                    :
                                    <div className="projectitem-status">
                                        <Translate text="in progress" />
                                    </div>
                            }
                            <div className="projectitem-info">
                                <Translate text="Files" /><br/>
                                {this.state.project.id === 976?4:this.props.project.files}
                            </div>
                            <div className="projectitem-info">
                                {this.state.project.id === 976?<span><Translate text="videos" /> <br/>4<Translate text="images" /> <br/>36</span>:this.state.project.type?<span><Translate text="videos" /><br/>{this.state.project.images}</span>:<span><Translate text="images" /><br/>{this.state.project.images}</span>}
                            </div>
                            {this.state.project.progress === 1 && this.state.project.run === 2 && this.state.project.images>(this.state.project.type?Global.me.video_count:Global.me.render_count)?
                                <div className="projectitem-info">
                                    {(this.state.project.images-(this.state.project.type?Global.me.video_count:Global.me.render_count))*(this.state.project.type?18:3)}$
                                </div>:null
                            }
                            {this.state.project.progress ===1 && this.state.project.run === 2?
                                <div className="continue">
                                    <Link to={"/project/"+this.props.project.id+"/purchase"} className="link">
                                        <span>
                                            <Translate text="continue" />
                                        </span>
                                        <Icon type="arrow-right"/>
                                    </Link>
                                </div>
                                :this.state.project.progress === 1 && this.state.project.run === 1 && this.state.project.id>951?
                                    <div className="continue" onClick={this.download}>
                                        <div className="link" >
                                        <span>
                                            <Translate text="download" />
                                        </span>
                                        </div>
                                    </div>
                                    :this.state.project.progress === 0 && this.state.project.run > 0 ?
                                        <div className="continue" onClick={()=>this.props.openModal('cancel', this.state.project.id)}>
                                            <div className="link">
                                        <span>
                                            <Translate text="cancel" />
                                        </span>
                                            </div>
                                        </div>
                                        :this.state.project.progress === 1 && this.state.project.run === 3 ?
                                            <div className="continue">
                                                <div className="link">
                                                    <span>
                                                        <Translate text="canceled" />
                                                    </span>
                                                </div>
                                            </div>:null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}