import React, { Component } from 'react';
import './UploadFileSteps.scss';
import StepTop from '../StepTop/StepTop';
import Global from '../Global';
import PubSub from 'pubsub-js';
import {Translate} from "react-translated";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";

export class UploadFileContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            fileCount: 0,
            attention: true,
        }
    }

    handlechooseFiles = () => {
        let inputTag = document.getElementById("chooseFiles");
        let countFiles = document.getElementById("counter");
        let files = [];
        let count = 0;
        let x=0;
        for (let i = 0; i < inputTag.files.length; i++) {
            x = 0;
            for (let j=0;j<Global.files.length;j++){
                if (Global.files[j].name === inputTag.files[i].name && Global.files[j].size === inputTag.files[i].size){
                    x = 1;
                }
            }
            if (!x){

                if(inputTag.files[i].type === "application/3dm" || (inputTag.files[i].type === "" && inputTag.files[i].name.split(".")[inputTag.files[i].name.split(".").length-1] === "3dm") || (inputTag.files[i].type === "" && inputTag.files[i].name.split(".")[inputTag.files[i].name.split(".").length-1] === "jcd" && (Global.me.id === 357 || Global.me.id === 2))){
                    count++;
                    files.push(inputTag.files[i]);
                    /*let formData = new FormData();
                    formData.append('model',inputTag.files[i]);
                    fetch("http://localhost/Rendline/public/api/upload", {
                        method: 'POST', // or 'PUT'
                        body: formData,
                    }).then(response => {
                        console.log(response);
                        response.json().then(asdf => {
                            console.log(asdf);
                        })
                    });*/
                } else{
                    PubSub.publish('toast.notif',inputTag.files[i].name+" File does not match");
                }
            } else{
                PubSub.publish('toast.notif',inputTag.files[i].name+" File already exists");
            }

        }
        this.props.addFiles(files);
        this.setState({
            fileCount:Global.files.length,
        })
        // console.log(Global.files);

    };
    attention=(check)=>{
        this.setState({
            attention:true,
        })
    };

    cancelUploaded = () => {
        this.setState({
            uploaded: !this.state.uploaded,
            fileCount: 0
        });
        Global.files = [];
    };

    render() {
        return (
            <div className="row">
                <div className="column small-9">
                    <div className="uploadfilecontent align-middle upload-wraping text-center">
                        <div className="upload-main-content">
                            {!this.state.attention ? (
                                <div className="attention-wrapper">
                                    <Icon type="attention"/>
                                    <div className="upload-title">
                                        <Translate text="attention!" />
                                    </div>
                                    <div className="upload-description">
                                        <Translate text="Please read 'File Upload Rules' to learn about the file standards, otherwise the results of renderings can appear to be incorrect.!" />
                                    </div>

                                    <Button type="button"
                                            theme="none"
                                            title="file upload rules"
                                            link="/file-upload-rules"
                                    />

                                    <div className="privacy-check">
                                        <input type="checkbox" onChange={this.attention} id="privacy-checkbox"/>
                                        <label htmlFor="privacy-checkbox">
                                            <Translate text="I confirm that I have read Terms and Conditions of RendLine.com and accept them." />
                                        </label>
                                    </div>
                                </div>
                            ):this.state.fileCount ? (

                                    <div className="uploaded-wrap">
                                        <div className="uploaded-icon">
                                            <Icon type="files"/>
                                            <Icon type="check"/>
                                        </div>
                                        <div className="count-uploaded" id="count-uploaded">
                                            <Translate text="Selected" />&nbsp;
                                            <span id="counter">{this.state.fileCount}</span>&nbsp;
                                                <Translate text="files" />
                                            </div>
                                        <div className="uploaded-actions">
                                            <label htmlFor="chooseFiles" className="add-file">
                                                <Icon type="plus" size={10}/>
                                                <span>
                                                    <Translate text="add more file" />
                                                </span>
                                            </label>
                                            <div>
                                                <Button type="button"
                                                        theme="none-white"
                                                        className="cancel-file"
                                                        title="cancel"
                                                        onClick={this.cancelUploaded}
                                                />
                                            </div>
                                            <input type="file"
                                                   id="chooseFiles"
                                                   className="hide-inp"
                                                   multiple
                                                   onChange={this.handlechooseFiles}
                                                   accept={Global.me.id === 357?".3dm,.jcd":".3dm"}
                                            />
                                        </div>
                                    </div>
                                ):(
                                    <div className="upload-main">
                                        <Icon type="upload"/>
                                        <div className="upload-title">
                                            <Translate text="Select your files to Upload" />
                                        </div>
                                        <div className="upload-description">
                                            <Translate text="Select files or drag and drop" />
                                        </div>
                                        <div className="upload-file-att-info">
                                            <Translate text="* Maximal file size 100MB and maximal 3 files(Beta version)" />
                                             <br />
                                            <Translate text="Only *.3dm files can be uploaded" />
                                        </div>
                                        <input type="file"
                                               id="chooseFiles"
                                               className="chooseFiles"
                                               multiple
                                               onChange={this.handlechooseFiles}
                                               accept={Global.me.id === 357?".3dm,.jcd":".3dm"}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="column small-3">
                    <div className="get-models-block">
                        <div className="get-models-title">
                            <Translate text="Don't you have a" />
                            &nbsp;
                            <b>
                                <Translate text="3d model" />
                            </b>
                        </div>
                        {/*<i>*/}
                        {/*    <img src={require("../../assets/img/br-icon.svg")} alt=""/>*/}
                        {/*</i>*/}
                        <Button type="button"
                                theme="green-back"
                                rounded={true}
                                responsive={true}
                                title="order now"
                                link="/request-for-3d-model"
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default class UploadFileSteps extends Component {
    constructor(props){
        super(props);
        this.state = {
            fileCount: 0,
            attention: false,
            accept: 'btn-disabled',
            files: [],
        }
    }

    addFiles= (files)=>{
        if (files.length>0){
            let filesx = Global.files;
            filesx.push(...files);
            this.setState({
                files:filesx,
                accept:'',
            });
            Global.files = filesx;
        }
    };


    render() {
        return (
            <div className="uploadfilesteps">
                <StepTop step="3" title="file upload" projectId={this.props.match.params.id}/>
                <UploadFileContent addFiles={this.addFiles} />
                
                <div className="row upload-step-actions">
                    <div className="column small-9">
                        <div className="row align-spaced align-middle">
                            <div className="column">
                                <Button type="button"
                                        theme="dark"
                                        iconLeft="arrow-left"
                                        iconLeftSize={12}
                                        title="back"
                                        link={"/project/"+this.props.match.params.type+"/"+this.props.match.params.id+"/select-scene"}
                                />
                            </div>
                            <Button type="button"
                                    theme="none-white"
                                    iconLeft="arrow"
                                    iconLeftSize={20}
                                    title="file upload rules"
                                    link={"/file-upload-rules"}
                            />

                            <div className="column text-right">
                                <Button type="button"
                                        theme="purple"
                                        title="next"
                                        iconRight="arrow-right"
                                        iconRightSize={12}
                                        link={"/project/"+this.props.match.params.type+"/"+this.props.match.params.id+"/set-materials-and-views"}
                                        disabled={this.state.accept}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
