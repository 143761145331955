import React, { Component } from 'react';
import './Views.scss';
import Modal from 'react-modal';
import ViewsCollection from '../../ViewsCollection/ViewsCollection';
import Global from '../../../Global';
import PubSub from 'pubsub-js';
import ReactTooltip from 'react-tooltip';
import {Translate, Translator} from 'react-translated';
import Icon from "../../../global/Icon/Icon";
import Button from "../../../global/Button/Button";

export default class Views extends Component {
    constructor(props){
        super(props);
        this.state={
            modalIsOpen: false,
            minModal:false,
            view:"",
            type:"",
            viewImageModal: false
        }
    }
    openMinModal = (view,type) => {

        this.setState({
            minModal:true,
            view,
            type
        });
    };
    deleteView=()=>{
        let views = Global.styleBoxes[this.props.index][this.state.type];
        let index = views.indexOf(this.state.view);
        views.splice(index, 1);
        Global.styleBoxes[this.props.index][this.state.type] = views;
        this.forceUpdate();
        this.closeModal();
        PubSub.publish("image.count");
    };
    openModal = () => {
        this.setState({
            modalIsOpen: true,
        }, () => {
            this.props.finishGuide();
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            minModal:false,
            viewImageModal: false
        });
    };

    toggleViewImageModal = () => {
        this.setState({
            viewImageModal: !this.state.viewImageModal
        })
    };

    render() {
        return (
            <div className="views">
                <div className="stylebox-subtitle">
                    <span>
                        <Translate text="views" />
                    </span>
                    <Translator>
                        {({ translate }) => (
                            <a
                                data-for='set-mat-2'
                                className="tooltip-wrap"
                                data-tip={translate({
                                    text: 'In this block you add views in which you would like to render all the models in the StyleBox.',
                                })}
                            >
                                <Icon type="help"/>
                            </a>
                        )}
                    </Translator>
                    <ReactTooltip globalEventOff='click' place="left" id='set-mat-2'/>
                </div>
                <div className="main-views">
                    <div className="col-view">
                        <div className="add-new-views" onClick={this.openModal}>
                            <div className="add-new">
                                <Icon type="plus"/>
                            </div>
                            <div className="title">
                                <Translate text="add new" />
                            </div>
                        </div>
                    </div>
                    {Global.styleBoxes[this.props.index].views.map((item,key)=>
                        <div key={key} className="col-view">
                            <div className="added-view-item" onClick={this.toggleViewImageModal}>
                                <div className="added-img">
                                    <img src={require("../../../../assets/rend/scen_1/folder_file1.3dm/"+item+".jpg")} alt=""/>
                                    <div className="delete-view" onClick={()=>this.openMinModal(item,"views")}>
                                        <Icon type="delete" title="Delete this View"/>
                                    </div>
                                </div>
                                <div className="title">
                                    {item}
                                </div>
                            </div>
                        </div>
                    )}
                    {Global.styleBoxes[this.props.index].customViews.map((item,key)=>
                        <div key={key} className="col-view">
                            <div className="added-view-item">
                                <div className="added-img">
                                    <img src={require("../../../../assets/img/view-icon.svg")} alt=""/>
                                    <div className="delete-view" onClick={()=>this.openMinModal(item,"customViews")}>
                                        <Icon type="settings"/>
                                    </div>
                                </div>
                                <div className="title">
                                    {item}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            zIndex: '8'
                        },
                        content: {
                            width: 600,
                            left: 'calc(50% - 300px)',
                            bottom:'initial',
                            padding: 0,
                            top: '15%',
                            overflow: 'hidden',
                            border: 0,
                            backgroundColor: '#1c243e'
                        }
                    }}
                    isOpen={this.state.viewImageModal}
                    onRequestClose={this.toggleViewImageModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                    <div className="modal-header">
                        <div className="modal-title">
                            Chosen view
                        </div>
                        <div className="close-modal" onClick={this.toggleViewImageModal}>
                            <Icon type="delete"/>
                        </div>
                    </div>

                    <img src={require("../../../../assets/rend/scen_1/folder_file1.3dm/Beldiamond_1.jpg")} alt=""/>
                </Modal>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            zIndex: '8'
                        },
                        content: {
                            width: 458,
                            left: 'calc(50% - 229px)',
                            bottom:'initial',
                            padding: 0,
                            top: '30%',
                            overflow: 'hidden',
                            border: 0,
                            backgroundColor: '#1c243e'
                        }
                    }}
                    isOpen={this.state.minModal}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                    <div className="modal-header">
                        <div className="modal-title">
                            Views Message
                        </div>
                        <div className="close-modal" onClick={this.closeModal}>
                            <Icon type="delete"/>
                        </div>
                    </div>
                    <div className="modal-content">
                        <Translate text="Are you sure you want to delete this View ?" />
                    </div>
                    <div className="modal_actions">
                        <div className="row">
                            <div className="column small-6">
                                <Button type="button"
                                        theme="dark"
                                        responsive={true}
                                        title="no"
                                        onClick={this.closeModal}
                                />
                            </div>
                            <div className="column small-6">
                                <Button type="button"
                                        theme="purple"
                                        responsive={true}
                                        title="yes"
                                        onClick={this.deleteView}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    style={{
                        overlay: {
                            zIndex:'8'
                        },
                        content: {
                            left: 0,
                            right: 0,
                            top: 0,
                            border: '0',
                            borderRadius: '0',
                            bottom: 0,
                            padding: 0,
                            overflow: "hidden"
                        }
                    }}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal">

                    <ViewsCollection styleBox={this.props.index} close={this.closeModal}/>
                </Modal>
            </div>
        )
    }
}
