import React, { Component } from 'react';
import './InvoiceList.scss';
import { Translate } from 'react-translated';
import Api from "../Api";
import Loading from "../global/Loading/Loading";

export default class InvoiceList extends Component {

    constructor(props){
        super(props);
        this.state={
            invoices:[],
            loading:false
        };
        this.loadData();
    }
    loadData=()=>{
        Api.apiCall("get","getInvoices",{}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                // console.log(resp.data.error);
            }else {
                this.setState({
                    invoices:resp.data,
                    count:resp.data.length,
                    loading:true
                })
                //PubSub.publish("redirect.to","/project/"+this.props.projectId+"/file-upload-step");
            }
        })
    };

    render(){
        return(
            <div className="row invoice-list">
                <div className="column small-12">
                    <div className="page-title-center text-center">
                        <Translate text="invoices" />
                    </div>
                </div>
                <div className="column small-12">
                    <div className="invoice-table content-wrapper">
                        <div className="invoices-head">
                            <div className="head-main">
                                <div>
                                    <Translate text="id" />
                                </div>
                                <div>
                                    <Translate text="date" />
                                </div>
                                <div>
                                    <Translate text="Amount" />
                                </div>
                                <div></div>
                            </div>
                        </div>
                        {!this.state.loading?
                            <div className="invoice-body min-height">
                                <div className="invoice-load">
                                    <Loading/>
                                </div>
                            </div>:
                            <div className="invoices-body">
                                {this.state.invoices.map((item,i)=>
                                    <div key={i} className="body-item">
                                        <div>
                                            {i+1}
                                        </div>
                                        <div>
                                            {item.donation_start.split(" ")[0]}
                                        </div>
                                        <div>
                                            ${Math.round(item.amount/item.rate)}
                                        </div>
                                        <div>
                                            <a href={'http://app.rendline.com/invoice/' + item.id} target="blank">
                                                <Translate text="view PDF" />
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}