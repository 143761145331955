import React, { Component } from 'react';
//import Select from 'react-select-plus';
//import 'react-select-plus/dist/react-select-plus.css';
import Global from "../../Global";
import Api from "../../Api";
import PubSub from 'pubsub-js';
import Button from "../../global/Button/Button";
import Field from "../../global/Field/Field";

export default class PersonalInformation extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOption: '',
            name: Global.me.name,
            companyName: Global.me.company,
            companyAddress: Global.me.companyAddress,
            vatNumber: Global.me.companyVAT,
            loading: false,
        }
    }
    
    /*handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Selected: ${selectedOption.label}`);
    }*/

    handleChange = (val,name) => {
        let value = val.target.value;
        let variable=this.state;
        variable[name] = value;
        this.setState({
            ...variable,
        })
    };

    save=(e)=>{
        e.preventDefault();
        this.setState({loading:true});
        let data = {
            name:this.state.name,
            company:this.state.companyName,
            companyAddress:this.state.companyAddress,
            companyVAT:this.state.vatNumber,
        };
        Api.apiCall("post","editUser",data).then((resp)=>{
            this.setState({loading:false});
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                PubSub.publish('toast.success', "Changes successfully saved");
                Global.me = resp.data[0]
            }
        })
    };

    render() {
        const { selectedOption } = this.state;
  	    const value = selectedOption && selectedOption.value;

  	    console.log(this.state)
        console.log(this.state.companyName?this.state.companyName:" ")
        return (
            <div className="content-wrapper change-profile">
                <form action=""  onSubmit={this.save}>

                    <Field label="name surname"
                           theme={true}
                           type="text"
                           value={this.state.name?this.state.name: " "}
                           onChange={(opt)=>this.handleChange(opt,"name")}
                           onEnter={this.save}
                    />
                    <Field label="Company Name"
                           theme={true}
                           type="text"
                           value={this.state.companyName?this.state.companyName:" "}
                           //defaultValue={this.state.companyName || ""}
                           onChange={(opt)=>this.handleChange(opt,"companyName")}
                           onEnter={this.save}
                    />
                    <Field label="Company Address"
                           theme={true}
                           type="text"
                           value={this.state.companyAddress?this.state.companyAddress:" "}
                           //defaultValue={this.state.companyAddress || ""}
                           onChange={(opt)=>this.handleChange(opt,"companyAddress")}
                           onEnter={this.save}
                    />
                    <Field label="VAT Number"
                           theme={true}
                           type="text"
                           value={this.state.vatNumber?this.state.vatNumber:" "}
                           //defaultValue={this.state.vatNumber || ""}
                           onChange={(opt)=>this.handleChange(opt,"vatNumber")}
                           onEnter={this.save}
                    />
                    {/*<div className="modal-field-item">*/}
                        {/*<label className="form-label text-left">*/}
                            {/*Country*/}
                        {/*</label>*/}
                        {/*<Select*/}
                            {/*name="form-field-name"*/}
                            {/*value={value}*/}
                            {/*options={[*/}
                            {/*{ value: 'one', label: 'One' },*/}
                            {/*{ value: 'two', label: 'Two' },*/}
                            {/*]}*/}
                        {/*/>*/}
                    {/*</div>*/}
                    <Button type="submit"
                            theme="purple"
                            title="save"
                            responsive={true}
                            loading={this.state.loading}
                    />
                </form>
            </div>
        )
    }
}
