import React, { Component } from 'react';
import './StyleBox.scss';
// import ReactTags from 'react-tag-autocomplete';
import Global from '../../Global';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import Modal from 'react-modal';
import { Collapse } from 'react-collapse';
import Materials from './Materials/Materials';
import Views from './Views/Views';
import Api from "../../Api";
import PubSub from 'pubsub-js';
import ReactTooltip from 'react-tooltip';
import {Translate, Translator} from 'react-translated';
import Icon from "../../global/Icon/Icon";
import Button from "../../global/Button/Button";

export default class StyleBox extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isOpened: true,
            modalOpen: false,
            tags: this.props.files,
            fav:this.props.fav,
            /*tags: [
                { label: 'Light Purple', value: 'light_purple' },
                { label: 'Medium Purple', value: 'medium_purple' },
                { label: 'Dark Purple', value: 'dark_purple' }
            ],*/
            selectedOptions: this.props.styleBox.files || [],
            files:this.props.files,
            name:this.props.styleBox.name,
            videoStyleBox:this.props.type === "video"?1:0,
        }
		
    }
	
    openModal = () => {
        this.setState({
            modalOpen: true,
        });
    };

    deleteStylebox=()=>{
        let styleBoxes = Global.styleBoxes;
        styleBoxes.splice(this.props.index, 1);
        Global.styleBoxes = styleBoxes;
        this.props.update();
        this.closeModal();
        PubSub.publish("image.count");
    };

    closeModal = () => {
        this.setState({
            modalOpen: false,
        });
    };
    // handleDelete (i) {
    //     const tags = this.state.tags.slice(0)
    //     tags.splice(i, 1)
    //     this.setState({ tags })
    // }

    // handleAddition (tag) {
    //     const tags = [].concat(this.state.tags, tag)
    //     this.setState({ tags });
    //     this.props.addTag(this.props.key,tags);
    // }
    handleChange = (selectedOptions) => {
         this.setState({ selectedOptions });
        Global.styleBoxes[this.props.index].files=selectedOptions;
        PubSub.publish("image.count");
        this.props.handleFileSelect();
         //this.props.addTag(this.props.index,selectedOptions);
        // console.log(`Selected: ${selectedOption.label}`);
    };
    attach=()=>{
        this.setState({ selectedOptions:this.state.files });
        Global.styleBoxes[this.props.index].files=this.state.files;
        PubSub.publish("image.count");
    };
    favorite=()=>{
        let data = Global.styleBoxes[this.props.index];
        Api.apiCall("post","setStyleboxFavorite",{stylebox:this.props.styleBox.id,fav:this.state.fav,data:JSON.stringify(data)}).then((resp)=>{
            if (resp.status !== 200) {
                // console.log(resp.status);
            }
            if ('error' in resp.data) {
                PubSub.publish('toast.error',resp.data.error);
            }else {
                this.setState({
                    fav:!this.state.fav,
                })
                // console.log(resp);
            }
        })
    };
    toggleCollapse = () => {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    };
    componentWillReceiveProps(nextProps){
        this.setState({
            selectedOptions:nextProps.styleBox.files,
            name:nextProps.styleBox.name,
        })
    }

    changeTitle=(val)=>{
        Global.styleBoxes[this.props.index].name = val.target.value;
        this.setState({
            name: val.target.value.replace(/([.*?^:'"(){}|[\]\/\\])/g, ""),
        });
    };


    render() {
        const {isOpened} = this.state;
        let name= this.state.name;
        let tags = this.state.tags;

        // const { steps, run } = this.state;

        return (
            <div className="stylebox content-wrapper">
                <div className="stylebox-top">
                    <Icon type="delete" onClick={this.openModal} title="Delete this stylebox"/>
                    <div className="row align-middle align-justify">
                        <div className="column">
                            <div className="stylebox-title form-input">
                                <input type="text" id="styleboxName" value={name} onChange={this.changeTitle} />
                                <Icon type="edit"/>
                            </div>
                        </div>
                        <div className="column text-right">
                            <div className="flex-container align-right align-middle">
                                <Translator>
                                    {({ translate }) => (
                                        <div
                                            onClick={this.favorite}
                                            data-for='set-mat-1'
                                            className="tooltip-wrap"
                                            data-tip={translate({
                                                text: 'You can save the StyleBox and then use it again in other projects.',
                                            })}
                                        >
                                            <div className={this.state.fav?"fav-style active-fav":"fav-style"}>
                                                <Icon type="star"/>
                                            </div>
                                        </div>
                                    )}
                                </Translator>
                                <div className="toggle-stylebox-btn" onClick={this.toggleCollapse}>
                                    <Icon type="arrow-bottom"/>
                                </div>
                            </div>

                            <ReactTooltip place="left" id='set-mat-1'/>
                        </div>
                    </div>
                </div>
                <Collapse isOpened={isOpened}>
                    <div className="main-stylebox">
                        <div className="tags-wrapper">
                            <div className="stylebox-subtitle">
                                <span>
                                    <Translate text="files" />
                                </span>
                                <Translator>
                                    {({ translate }) => (
                                        <a
                                            className="tooltip-wrap"
                                            data-tip={translate({
                                                text: 'In this block you can add one or some',
                                            })}
                                        >

                                            <Icon type="help"/>
                                        </a>
                                    )}
                                </Translator>
                                <ReactTooltip globalEventOff='click' place="left"/>
                                <Button type="button"
                                        theme="none"
                                        title="attach all files"
                                        onClick={this.attach}
                                />
                            </div>
                            <div className="tags-main">
                                {/* <ReactTags
                                    tags={tags}
                                    suggestions={this.state.files}
                                    handleDelete={this.handleDelete.bind(this)}
                                    handleAddition={this.handleAddition.bind(this)} /> */}

                                <Select
                                    name="form-field-name"
                                    value={this.state.selectedOptions}
                                    multi
                                    placeholder={null}
                                    onChange={this.handleChange}
                                    options={tags}
                                />
                            </div>
                        </div>
                        <div className="stylebox-styling">
                            {Global.me.id?
                                <Materials
                                    type={this.props.type}
                                    index={this.props.index}
                                    incrementStepIndex={this.props.incrementStepIndex}
                                    finishGuide={this.props.finishGuide}
                                />:null
                            }


                            {!this.state.videoStyleBox ?(
                                <Views
                                    index={this.props.index}
                                    finishGuide={this.props.finishGuide}
                                />
                            ):null}
                        </div>
                    </div>
                </Collapse>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            zIndex:'8'
                        },
                        content: {
                            width:458,
                            left:'calc(50% - 229px)',
                            bottom:'initial',
                            padding:0,
                            top:'30%',
                            overflow:'hidden',
                            border:0,
                            backgroundColor:'#1c243e'
                        }
                    }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                >
                    <div className="modal-header">
                        <div className="modal-title">
                            <Translate text="StyleBox Message" />
                        </div>
                        <div className="close-modal" onClick={this.closeModal}>
                            <Icon type="delete"/>
                        </div>
                    </div>
                    <div className="modal-content">
                        <Translate text="Are you sure you want to close the stylebox ?" />
                    </div>
                    <div className="modal_actions">
                        <div className="row">
                            <div className="column small-6">
                                <Button type="button"
                                        theme="purple"
                                        responsive={true}
                                        title="no"
                                        onClick={this.closeModal}
                                />
                            </div>
                            <div className="column small-6">
                                <Button type="button"
                                        theme="dark"
                                        responsive={true}
                                        title="yes"
                                        onClick={this.deleteStylebox}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}