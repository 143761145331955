import React, { PureComponent } from 'react';
import './CheckoutWrapper.scss';
import PubSub from 'pubsub-js';
import { Translate } from 'react-translated';
import Global from '../../Global';
import Icon from "../../global/Icon/Icon";
import Button from "../../global/Button/Button";

export default class CheckoutWrapper extends PureComponent {
    index=0;
    back=()=>{
        if (this.index === 0){
            this.index=1;
            PubSub.publish('toast.notif',"Your uploaded files will be lost");
        } else{
            PubSub.publish('redirect.to',"/project/"+this.props.type+"/"+this.props.id+"/file-upload-step");
        }
    };
    render() {
        let peyImage = this.props.imageCount - (this.props.type === "photo"?Global.me.render_count:Global.me.video_count);
        //console.log(peyImage);
        return (
            <div className="checkout-wrapper">
                <div className="row">
                    <div className="column small-12">
                        <div className="checkout-main">
                            <div className="row align-justify align-middle">
                                <div className="column medium-flex-child-shrink">
                                    <div className="checkout-item flex-container align-middle">
                                        <Button type="button"
                                                theme="border"
                                                iconLeft="arrow-left"
                                                title="back"
                                                onClick={this.back}
                                        />
                                        <div className="upload-fils_info">
                                            <div>
                                                <Icon type="files"/>
                                                <span>
                                                    {this.props.filesCount}
                                                </span>
                                            </div>
                                            <div>
                                                <Icon type="images"/>
                                                <span>
                                                    {this.props.imageCount}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="price-info">
                                            <span>
                                                <Translate text="Your Balance" />
                                            </span>
                                            {this.props.type === "photo"?Global.me.render_count:Global.me.video_count}
                                        </div>
                                        <div className="price-info" style={{color: "#7d47dd"}}>
                                            <span>
                                                {(this.props.type === "photo"?<Translate text="paid images" />:<Translate text="paid videos" />)}
                                            </span>
                                            {peyImage< 0?0:peyImage}
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="checkout-item align-middle flex-container align-right text-right">
                                        <div className="price-per-picture">
                                            <span>
                                                ${(this.props.type === "photo" ?3:18)}
                                            </span>
                                            <Translate text="per"/>
                                            &nbsp;
                                            {(this.props.type === "photo"?
                                                <Translate text="picture" />
                                                :
                                                <Translate text="video" />
                                            )}
                                        </div>
                                        <div className="price-info">
                                            <span>
                                                <Translate text="total price" />
                                            </span>
                                            ${(peyImage>0?peyImage:0)*(this.props.type === "photo" ?3:18)}
                                        </div>
                                        <Button type="button"
                                                theme="purple"
                                                title="Next to Rendering"
                                                iconRight="arrow-right"
                                                onClick={this.props.runProject}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
