﻿import React from "react";

export default {
    /*header*/
    'learn': {
        en: 'Learn',
        arm: 'Սովորել',
        ru:'Обучение',
        ch:'学习'
    },
    'help': {
        en: 'help',
        arm: 'ՕԳՆՈՒԹՅԱՆ',
        ru:'ПОМОЩЬ',
        ch:'规则'
    },
    'renderings left': {
        en: 'Balance',
        arm: 'Բալանս',
        ru: 'Баланс',
        ch:' 均衡'
    },
    'log in': {
        en: 'Log in',
        arm: 'Մուտք',
        ru:'Вход',
        ch:'入口'
    },
    'sign up': {
        en: 'Sign up',
        arm: 'Գրանցվել',
        ru:'Регистрация',
        ch:'登记'
    },
    'my projects':{
        en: 'My projects',
        arm: 'Իմ պրոեկտները',
        ru:'Мои проекты',
        ch:'我的项目'
    },
    'personal information':{
        en: 'Personal Information',
        arm: 'Անձնական տվյալներ',
        ru:'Личная информация',
        ch:'个人信息'
    },
    'change password':{
        en: 'Change password',
        arm: 'փոխել գաղտնաբառը',
        ru:'Изменить пароль',
        ch:'变动口令'
    },
    'logout':{
        en: 'Logout',
        arm: 'Ելք',
        ru:'Выход',
        ch:'出口'
    },

    /*header*/


    /*footer*/
    'All rights reserved.':{
        en: 'All rights reserved.',
        arm: 'Բոլոր իրավունքները պաշտպանված են:',
        ru:'Все права защищены.',
        ch:'保留所有权利'
    },
    'about us':{
        en: 'About us',
        arm: 'Մեր մասին',
        ru:'О нас',
        ch:'关于我们'
    },
    'terms & conditions':{
        en: 'Terms & conditions',
        arm: 'Պայմաններ և դրույթներ',
        ru:'Положения и условия',
        ch:'条款和条件'
    },

    /**/

    'I confirm that I have read File Upload Rules of RendLine.com and accept them.':{
        en: 'I confirm, that my files comply with "File Upload Rules" standards.',
        arm: 'Հաստատում եմ , որ իմ ֆայլերը համապատասղանում են «Ֆայլերի Վերբեռնման Կանոներ»-ին:',
        ru:'Я подтверждаю, что мои файлы соответствуют стандартам «Правил загрузки файлов».',
        ch:'我确认我的文件符合”上传规则”的标准'
    },
    'attention!':{
        en: 'attention',
        arm: 'ուշադրություն',
        ru:'внимание',
        ch:'注意力'
    },
    'Please read "File Upload Rules" to learn about the file standards, otherwise the results of renderings can appear to be incorrect.!':{
        en: 'Please read "File Upload Rules" to learn about the file standards, otherwise the results of renderings can appear to be incorrect.',
        arm: 'Խնդրում ենք կարդացեք «Ֆայլերի Վերբեռնման Կանոններ» -ը , հակառակ դեպքում ռենդերինգի վերջնական տեսքը կարող է լինել սպասելիքներին անհամապատասխան:',
        ru:'Пожалуйста, прочитайте «Правила загрузки файлов», чтобы ознакомиться с требуемыми стандартами, в противном случае результаты рендеринга могут не соответствовать ожиданиям.',
        ch:'请阅读“文件上载规则”以熟悉所需的标准，否则渲染结果可能无法满足预期.'
    },
    'back':{
        en: 'back',
        arm: 'հետ',
        ru:'назад',
        ch:'向后'
    },
    'next':{
        en: 'next',
        arm: 'հաջորդը',
        ru:'следующий',
        ch:'下一个'
    },
    'Create A New Project':{
        en: 'Create A New Project',
        arm: 'Ստեղծել նոր պրոեկտ',
        ru:'Создать новый проект',
        ch:'创造新项目'
    },
    'file upload rules':{
        en: 'File Upload Rules',
        arm: 'Ֆայլի Վերբեռնման Կանոններ',
        ru:'Правила загрузки файлов',
        ch:'文件上传规则'
    },
    'Selected':{
        en: 'Selected',
        arm: 'Ընտրված',
        ru:'Выбранный',
        ch:'选'
    },
    'files':{
        en: 'files',
        arm: 'ֆայլեր',
        ru:'файлы',
        ch:'文件'
    },
    'add more file':{
        en: 'add more file',
        arm: 'ավելացնել ֆայլեր',
        ru:'добавить файлы',
        ch:'添加文件'
    },
    "I confirm that I have read Terms and Conditions of RendLine.com and accept them.":{
        en: 'I confirm, that my files comply with "File Upload Rules" standards.',
        arm: 'Հաստատում եմ, որ իմ ֆայլերը համապատասխանում են «Ֆայլերի Վերբեռնման Կանոններ»-ին:',
        ru:'Я подтверждаю, что мои файлы соответствуют стандартам «Правил загрузки файлов».',
        ch:'我确认我的文件符合“文件上传规则”的标准。'
    },
    'cancel':{
        en: 'cancel',
        arm: 'չեղարկել',
        ru:'отмена',
        ch:'取消'
    },
    'Select your files to Upload':{
        en: 'Select your files to upload',
        arm: 'Ընտրեք ձեր ֆայլերը վերբեռնման համար',
        ru:'Выберите файлы для загрузки',
        ch:'选择文件对上载'
    },
    'Select files or drag and drop':{
        en: 'Select files or drag and drop',
        arm: 'Ընտրեք ֆայլեր կամ քաշեք և թողեք',
        ru:'Выберите файлы или перетащите их сюда',
        ch:'选择文件或吧它们拖到这里'
    },
    '* Maximal file size 100MB and maximal 3 files(Beta version)':{
        en: '* Maximum file size is 400MB',
        arm: '* Առավելագույն ֆայլի չափը` 400MB',
        ru:'* Максимальный размер файла - 400 МБ',
        ch:'最大文件大小为400 MB'
    },
    'Only *.3dm files can be uploaded':{
        en: 'Only *.3dm files can be uploaded',
        arm: 'Միայն * .3dm ֆայլեր կարող են վերբեռնվել',
        ru:'Только файлы * .3dm могут быть загружены',
        ch:'只文件'
    },
    'Thank you!':{
        en: 'Thank you!',
        arm: 'Շնորհակալություն',
        ru:'Спасибо!',
        ch:'谢谢'
    },
    /*message*/

    "Congratulations and welcome to RendLine! You have activated your premium account and you can already start enjoying the advantages that you are given In case of any questions you are welcome to contact us by the following email: info@rendline.com":{
        en: 'Congratulations and welcome to Rendline! You have activated your premium account and you can already start enjoying the advantages granted to you.  In case of any questions you are welcome to contact us by the following email: info@rendline.com',
        arm: 'Շնորհավորում ենք։ Դուք ակտիվացրել եք ձեր պրեմիում ակաունտը և դուք արդեն կարող եք վայելել ձեզ ընձեռնված առավելությունները: Հարցերի դեպքում խնդրում ենք կապ հաստատել հետևյալ էլ․ հասցեով ՝  info@rendline.com',
        ru:'Поздравляем и приветствуем Вас. Вы активизировали Ваш премиум аккаунт и можете начать пользоваться предоставленными Вам преимуществами. При возникновении вопросов просим связаться с нами по следующему электронному адресу: info@rendline.com',
        ch:'恭喜和欢迎。您已激活高级帐户，可以开始使用提供给您的福利。如果您有任何疑问，请通过以下电子邮件地址与我们联系:info@rendline.com'
    },
    /**/

    'general settings':{
        en: 'general settings',
        arm: 'հիմնական կարգավորումներ',
        ru:'основные настройки',
        ch:'基本设在'
    },
    'Rendline is a unique automated platform for making jewelry rendering online. Feel the pleasure of being professional.':{
        en: 'Rendline is a unique automated platform for making jewelry rendering online. Feel the pleasure of being professional.',
        arm: 'Rendline-ը, յուրահատուկ ավտոմատացված օնլայն հարթակ է,ոսկյա զարդերի ռենդերինգ ստանալու համար: Զգացեք պրոֆեսիոնալ լինելու հաճույքը։',
        ru:'Rendline - это уникальная автоматизированная платформа для получения рендерингов ювелирных изделий онлайн. Почувствуйте удовольствие профессионализма.',
        ch:'Rendline 这是一个独特的在线珠宝渲染自动化平台。感受到专业的乐乐趣。'
    },
    'Select a Scene':{
        en: 'Select a Scene',
        arm: 'Ընտրեք սցենա',
        ru:'Выберите сцену',
        ch:'选择舞台'
    },
    'Upload a File':{
        en: 'Upload a File',
        arm: 'Վերբեռնեք ֆայլ',
        ru:'Загрузите файл',
        ch:'上传文件'
    },
    'Set Materials & View':{
        en: 'Choose Materials & Views',
        arm: 'Ընտրել մատերիալներ և անկյուններ',
        ru:'Выберите материалы и ракурсы',
        ch:'选择角度和材料'
    },
    'Get Images':{
        en: 'Get Images',
        arm: 'Ստացեք նկարներ',
        ru:'Получите изображения',
        ch:'得到图像'
    },
    'E-mail':{
        en: 'E-mail',
        arm: 'Էլ.հասցե',
        ru:'Эл. адрес',
        ch:'电子邮件'
    },
    'Full name':{
        en: 'Full name',
        arm: 'Անուն',
        ru:'Полное имя',
        ch:'全名'
    },
    'Password':{
        en: 'Password',
        arm: 'Գաղտնաբառ',
        ru:'Пароль',
        ch:'密码'
    },
    'By clicking Sign Up, you agree to our':{
        en: 'By clicking Sign Up, you agree to our',
        arm: 'Սեղմելով գրանցվել, Դուք ընդունում եք մեր',
        ru:'Нажимая «Регистрироваться», Вы соглашаетесь с нашими',
        ch:'点击”注册” 及表示您同意我们的意见。'
    },
    'Enjoy the fusion of delight, quality and speed with us.':{
        en: 'Enjoy the fusion of delight, quality and speed with us.',
        arm: 'Վայելեք որակի եւ արագության համադրության հաճույքը մեզ հետ:',
        ru:'Наслаждайтесь сочетанием качества и скорости с нами.',
        ch:'享受质量和速度的完美结合'
    },
    'Company Name':{
        en: 'Company name',
        arm: 'Ընկերության անվանումը',
        ru:'Название компании',
        ch:'公司的名称'
    },
    'Company Address':{
        en: 'Company Address',
        arm: 'Ընկերության հասցեն',
        ru:'Адрес компании',
        ch:'公司地址'
    },
    'VAT Number':{
        en: 'VAT Number',
        arm: 'ԱԱՀ-ի համարը',
        ru:'Номер НДС',
        ch:'增值税号'
    },
    'Confirm Password':{
        en: 'Confirm password',
        arm: 'Հաստատել գաղտնաբառը',
        ru:'Подтвердить пароль',
        ch:'证实密码'
    },
    'Enter your Company name':{
        en: 'Enter your company name',
        arm: 'Մուտքագրեք ձեր ընկերության անունը',
        ru:'Введите название Вашей компании',
        ch:'输入您公司的名称'
    },
    'forgot password':{
        en: 'forgot password',
        arm: 'Մոռացե՞լ եք գաղտնաբառը',
        ru:'Забыли пароль?',
        ch:'忘记了密码'
    },
    'Hello and welcome back':{
        en: 'Hello and welcome back',
        arm: 'Բարի վերադարձ',
        ru:'Добро пожаловать',
        ch:'欢迎'
    },
    'get started video':{
        en: 'Watch video',
        arm: 'Նայել տեսանյութը',
        ru:'Смотреть видео',
        ch:'看视频'
    },
    'just sign up':{
        en: 'just sign up',
        arm: 'պարզապես գրանցվեք',
        ru:'просто зарегистрируйтесь',
        ch:'只是注册'
    },
    'welcome to rendline premium!':{
        en: "welcome to \n rendline <b>premium!</b>",
        arm: 'պարզապես գրանցվեք',
        ru:'просто зарегистрируйтесь',
        ch:'只是注册'
    },
    'premium-description':{
        en: "You have been granted an exceptional opportunity to become our Premium Customer. You will be given the chance to get a product that will meet all your business standards․ You will receive the following advantages:\n" +
        "                            -order scenes,\n" +
        "                            -get preferred views,\n" +
        "                            -add new materials,",
        arm: 'Ձեզ ընձեռնվել է բացառիկ հնարավորություն դառնալու Պրեմիում Օգտատեր։ Դուք կստանաք հնարավորություն ստեղծել  Ձեր բիզնես ստանդարտներին համապատասխան պրոդուկտ: Դուք կստանաք հետևյալ առավելությունները․\n' +
        '                             -պատվիրել սցենաներ,\n' +
        '                             -ստանալ նախընտրելի անկյուններ,\n' +
        '                             -ավելացնել նոր մատերիալներ, ',
        ru:'Вам была предоставлена исключительная возможность стать нашим Премиум Пользователем. Вы получите возможность получить продукт, который будет соответствовать Вашим бизнес стандартам. Вы получите следующие преимущества:\n' +
        '                             -Заказать сцену, \n' +
        '                             -Получить предпочитаемые ракурсы, \n' +
        '                             -добавить новые материалы, ',
        ch:'您获得了成为我们的高级用户的绝佳机会。您将能够获得符合您业务标准的产品。您将获得以下福利:/n+\n' +
            '                                       -订购一个场景,/n+\n' +
            '                                      - 获得首选角度,/n+\n' +
            '                                     -添加新材料\n'
    },
    'New password':{
        en: 'New password',
        arm: 'Նոր գաղտնաբառ',
        ru:'Новый пароль',
        ch:'新秘密'
    },
    'submit':{
        en: 'submit',
        arm: 'հաստատել',
        ru:'подтвердить',
        ch:'提交'
    },
    'Request reset link':{
        en: 'Request reset link',
        arm: 'Գաղտնաբառը վերականգնելու հղում',
        ru:'Ссылка для восстановления пароля',
        ch:'密码恢复链接'
    },
    'All Files Uploaded':{
        en: 'All files are uploaded',
        arm: 'Բոլոր ֆայլերը վերբեռնվել են',
        ru:'Все файлы загружены',
        ch:'所有文件上传了'
    },
    'Uploading...':{
        en: 'Uploading...',
        arm: 'Վերբեռնում ...',
        ru:'Загрузка ...',
        ch:'装载'
    },
    'Add stylebox':{
        en: 'add stylebox',
        arm: 'ավելացնել սթայլբոքս',
        ru:'добавить стайлбокс',
        ch:'添加stylebox'
    },
    'add new stylebox':{
        en: 'add new stylebox',
        arm: 'ավելացնել նոր սթայլբոքս',
        ru:'добавить новый стайлбокс',
        ch:'添加新 stylebox'
    },
    'add from favorites':{
        en: 'add from favorites',
        arm: 'ավելացնել ֆավորիտներից',
        ru:'Выбрать из фаворитов',
        ch:'选择从 宠儿'
    },
    'or':{
        en: 'or',
        arm: 'կամ',
        ru:'или',
        ch:'或者'
    },
    'create styleboxes for all files':{
        en: 'create styleboxes for all files',
        arm: 'ստեղծել սթայլբոքսներ բոլոր ֆայլերի համար',
        ru:'создать стайлбоксы для всех файлов',
        ch:'为所styleboxes 有文件创建'
    },
    'what is stylebox?':{
        en: 'what is stylebox?',
        arm: 'Ի՞նչ է սթայլբոքսը:',
        ru:'что такое стайлбокс?',
        ch:'stylebox 是什么？'
    },
    'No StyleBoxes':{
        en: 'No styleboxes',
        arm: 'չկան սթայլբոքսեր',
        ru:'Нет стайлбоксов',
        ch:'没有styleboxes'
    },
    'StyleBox is a necessary block with the help of which you can set materials and views for one or more files.':{
        en: 'Stylebox is a necessary block with the help of which you can set materials and views for one or more files.',
        arm: 'Սթայլբոքսը բլոկ է, որի օգնությամբ դուք կարող եք ընտրել մատերիալներ ու անկյուններ՝ մի կամ մի քանի ֆայլերի համար։',
        ru:'Стайлбокс - это необходимый блок, с помощью которого Вы сможете выбрать материалы и ракурсы для одного или более файлов.',
        ch:'这是一个必要的块，您可以使用它来为一个或多个文件选择材料和视图。'
    },
    'add selected':{
        en: 'add selected',
        arm: 'ավելացնել',
        ru:'добавить',
        ch:'添加选中'
    },
    'Views collection':{
        en: 'Views collection',
        arm: 'Անկյուններ',
        ru:'Ракурсы',
        ch:'缩影'
    },
    'save new views':{
        en: 'save new views',
        arm: 'հիշել նոր անկյունները',
        ru:'сохранить новые ракурсы',
        ch:'选择首选角度'
    },
    'Default views':{
        en: 'Default views',
        arm: 'Հիմնական անկյուններ',
        ru:'Ракурсы по умолчанию',
        ch:'缩影 默认情况下'
    },
    'Individual views':{
        en: 'Individual views',
        arm: 'Անհատական անկյուններ',
        ru:'Индивидуальные ракурсы',
        ch:'个人缩影'
    },
    'add custom views':{
        en: 'add custom views',
        arm: 'սեփական անկյունները',
        ru:'пользовательские ракурсы',
        ch:'添加自定义视图'
    },
    'individual view name':{
        en: 'individual view name',
        arm: 'անհատական անկյան անվանումը',
        ru:'Название индивидуального ракурса',
        ch:'个人观点名称'
    },
    /*about page text*/
    'about-text-full':{
        en: 'For the owners of online jewelry shops who want to sell a piece of jewelry before producing it, Rendline is an automated online jewelry rendering service which eliminates the need for rendering specialists and unlike rendering masters who carry out the rendering process manually, our service provides high-quality presentable renderings much faster and cheaper. Due to the simplified interface of the website our clients can experience the stunning feeling of being professional even with minimal computer skills. Join us to be a part of the rendering revolution.',
        arm: 'Rendline-ը ավտոմատացված օնլայն զարդերի ռենդերինգի ծառայություն է, որը բացառում է ռենդերինգի մասնագետների կարիքը, և ի տարբերություն նրանց՝ ովքեր ռենդերինգի ամբողջ պրոցեսը իրականացնում են ձեռքով, մեր ծառայությունը տալիս է հնարավորություն ստանալ բարձրորակ ռենդերինգներ ավելի արագ և մատչելի։ Վեբ-կայքի պարզեցված ինտերֆեյսի շնորհիվ մեր հաճախորդները կարող են վայելել պրոֆեսիոնալ լինելու հիանալի զգացողությունը նույնիսկ համակարգչային հմտությունների մինիմալ իմացությամբ։ Միացեք մեզ և դարձեք ռենդերինգի հեղափոխության մի մասը։',
        ru:'Rendline представляет собой автоматизированную онлайн систему для получения рендерингов ювелирных изделий. Она исключает необходимость вовлечения специалистов по рендерингу, которые выполняют весь процесс рендеринга вручную и даёт возможность получить высококачественные презентабельные рендеринги намного быстрее и выгоднее в цене. Благодаря упрощенному интерфейсу веб-сайта наши клиенты могут испытать потрясающее чувство профессионализма даже при минимальных навыках работы с компьютером.  Присоединяйтесь к нам и становитесь частью революции рендеринга.',
        ch:'是一个自动在线系统，用于接收珠宝效果图。它消除了让手动执行整个渲染过程的渲染器的需要，并且可以更快，更有利可图地获得高质量，可呈现的渲染。由于简化的网站界面，我们的客户即使用最少的计算机技能也可以体验到惊人的专业感。加入我们，成为渲染革命的一部分。'
    },

    /**/
    'Frequently Asked Questions':{
        en: 'Frequently Asked Questions',
        arm: 'Հաճախ տրվող հարցեր',
        ru:'Часто задаваемые вопросы',
        ch:'常见问题'
    },
    'save':{
        en: 'save',
        arm: 'հիշել',
        ru:'сохранить',
        ch:'保全'
    },
    'Project name':{
        en: 'Project name',
        arm: 'Պրոեկտի անվանում',
        ru:'Название проекта',
        ch:'项目的名称'
    },
    'resolution':{
        en: 'resolution',
        arm: 'կետայնություն',
        ru:'резолюция',
        ch:'决议['
    },
    'ended':{
        en: 'Completed',
        arm: 'Կատարված',
        ru:'Завершён',
        ch:'结束'
    },
    'in progress':{
        en: 'in progress',
        arm: 'ընթացքի մեջ',
        ru:'выполняется',
        ch:'落实'
    },
    'Files':{
        en: 'Files',
        arm: 'ֆայլ',
        ru:'Файл',
        ch:'文件'
    },
    'add project':{
        en: 'add project',
        arm: 'ավելացնել պրոեկտ',
        ru:'добавить проект',
        ch:'项目添加'
    },
    'Add Photo Rendering':{
        en: 'Add Photo Rendering',
        arm: 'Ավելացնել ֆոտո ռենդերինգ',
        ru:'Добавить фото-рендеринг',
        ch:'添加Rendering的照片'
    },
    'Add video Rendering':{
        en: 'Add video Rendering',
        arm: 'Ավելացնել վիդեո Ռենդերինգ',
        ru:'Добавить видео-рендеринг',
        ch:'添加视频渲染器'
    },
    'Photo Rendering':{
        en: 'Photo Rendering',
        arm: 'Ֆոտո Ռենդերիգ',
        ru:'Фото-Рендеринг',
        ch:'照片渲染'
    },
    'add new project':{
        en: 'add new project',
        arm: 'ավելացնել նոր պրոեկտ',
        ru:'добавить новый проект',
        ch:'添加新项目'
    },
    'Video Rendering':{
        en: 'Video Rendering',
        arm: 'Վիդեո Ռենդերինգ',
        ru:'Видео-Рендеринг',
        ch:'视频渲染'
    },
    'Need some help?':{
        en: 'Need some help?',
        arm: 'Հարկավո՞ր է օգնություն',
        ru:'Нужна помощь?',
        ch:'需要帮助'
    },
    'Watch this video':{
        en: 'Watch this video',
        arm: 'Դիտել տեսանյութը',
        ru:'Посмотреть видео',
        ch:'观看视频'
    },
    'Give life to your 3D models. Select an appropriate scene, materials and views for your 3D model and get renderings of incredible quality.':{
        en: 'Give life to your 3D models. Select an appropriate scene, materials and views for your 3D model and get renderings of incredible quality.',
        arm: 'Կյանքի կոչեք ձեր եռաչափ մոդելները, ընտրեք ցանկացած կարգավորում և ստացեք անկրկնելի նկարներ;',
        ru:'Придайте жизнь своим 3D моделям. Выберите подходящие настройки и получите рендеринги невероятного качества.',
        ch:'为3D模型赋予生命力。 选择正确的设置并获得令人难以置信的高质量渲染。'
    },
    'Admire your product in the motion of 360. Select an appropriate scene and materials for your 3D model. Enjoy your high-quality video renderings.':{
        en: 'Admire your product in the motion of 360. Select an appropriate scene and materials for your 3D model. Enjoy your high-quality video renderings.',
        arm: 'Հիացեք ձեր զարդի 360 աստիճան պտույտով։ Ընտրեք համապատասխան կարգավորումները և վայելեք բարձրորակ վիդեո ռենդերինգները։',
        ru:'Любуйтесь Вашим продуктом в движении 360°. Выберите подходящую сцену, материалы и наслаждайтесь высококачественным видео-рендерингом.',
        ch:'360°全方位享受您的产品。选择合适的场景，素材并享受高品质的视频渲染，。'
    },
    'free renderings':{
        en: 'free renderings',
        arm: 'անվճար ռենդերինգներ',
        ru:'бесплатный рендеринг',
        ch:'免费的渲染'
    },
    'Next to Rendering':{
        en: 'Next to rendering',
        arm: 'Սկսել ռենդերինգը',
        ru:'Начать рендеринг',
        ch:'开始渲染'
    },
    'attach all files':{
        en: 'attach all files',
        arm: 'կցել բոլոր ֆայլերը',
        ru:'прикрепить все файлы',
        ch:'附上所有文件'
    },
    'Are you sure you want to close the stylebox ?':{
        en: 'Are you sure you want to close the stylebox ?',
        arm: 'Վստա՞հ եք, որ ուզում եք փակել։',
        ru:'Вы действительно хотите закрыть стайлбокс?',
        ch:'你真的想结束吗stylebox？'
    },
    'StyleBox Message':{
        en: 'Attention',
        arm: 'Ուշադրություն',
        ru:'Внимание',
        ch:'注意'
    },
    'no':{
        en: 'no',
        arm: 'ոչ',
        ru:'нет',
        ch:'不是'
    },
    'yes':{
        en: 'yes',
        arm: 'այո',
        ru:'да',
        ch:'是'
    },
    'views':{
        en: 'views',
        arm: 'անկյուններ',
        ru:'ракурсы',
        ch:'缩影'
    },
    'add new':{
        en: 'add new',
        arm: 'ավելացնել',
        ru:'Добавить',
        ch:'添加'
    },
    'Are you sure you want to delete this View ?':{
        en: 'Are you sure you want to delete this View ?',
        arm: 'Վստա՞հ եք, որ ցանկանում եք ջնջել',
        ru:'Вы действительно хотите удалить данный ракурс?',
        ch:'你真的想删除这个视图吗？'
    },
    'Add new layer':{
        en: 'Add new layer',
        arm: 'Ավելացնել նոր լեյեր',
        ru:'Добавить новый слой',
        ch:'添加新层'
    },
    'materials':{
        en: 'materials',
        arm: 'մատերիալներ',
        ru:'материалы',
        ch:'资料'
    },
    'select this scene':{
        en: 'select this scene',
        arm: 'ընտրել այս սցենան',
        ru:'выбрать эту сцену',
        ch:'选择这个场景'
    },
    'see all views':{
        en: 'see all views',
        arm: 'տեսնել բոլոր անկյունները',
        ru:'посмотреть все ракурсы',
        ch:'查看所有角度'
    },
    'Number of Renderings':{
        en: 'Number of renderings',
        arm: 'Ռենդերինգի քանակ',
        ru:'Количество рендерингов',
        ch:'渲染数量'
    },
    'back to projects page':{
        en: 'back to projects page',
        arm: 'Վերադառնալ պրոեկտների բաժին',
        ru:'вернуться к странице проектов',
        ch:'回到项目页面'
    },
    'purchase-confirmation-description':{
        en: 'Your application is accepted and in line. The results will be sent to your email address within 48 hours. \n' +
        '                                        \n' +
        '                                        The status of your project is always available on the "Project Page" of your profile.',
        arm: 'Ձեր հարցումն ընդունված է։ Արդյունքները կուղարկվեն Ձեր էլեկտրոնային հասցեին 48 ժամվա ընթացքում։ \n' +
        '                                        \n' +
        '                                        Ձեր պրոեկտի կարգավիճակը միշտ հասանելի է Ձեր պրոֆիլի Պրոեկտների բաժնում։',
        ru:'Ваша заявка принята и находится в очереди. Результат будет отправлен на Ваш электронный адрес в течение 48 часов.\n' +
        '                                         \n' +
        '                                         Статус вашего проекта всегда доступен на странице «мои проекты» Вашего профиля.',
        ch:'您的申请已被接受并且在队列中。结果将在48小时内发送到您的电子邮件地址。'
    },
    'Company':{
        en: 'Company',
        arm: 'Ընկերություն',
        ru:'Компания',
        ch:'公司'
    },
    'name surname':{
        en: 'name and surname',
        arm: 'անուն ազգանուն',
        ru:'Имя и фамилия',
        ch:'姓名'
    },

    /*last*/

    'paid':{
        en: 'paid',
        arm: 'վճարված',
        ru:'оплаченный',
        ch:'还清的'
    },
    "paid images":{
        en: 'to be paid',
        arm: 'վճարվող',
        ru:'к оплате',
        ch:'付款'
    },
    "paid videos":{
        en: 'to be paid',
        arm: 'վճարվող',
        ru:'к оплате',
        ch:'付款'
    },
    'payment method':{
        en: 'payment method',
        arm: 'վճարման եղանակ',
        ru:'способ оплаты',
        ch:'付款方式'
    },
    'credit & debit cards':{
        en: 'Credit & debit cards',
        arm: 'Վարկային եւ դեբետային քարտեր',
        ru:'Кредитные и дебетовые карты',
        ch:'信用卡和借记卡'
    },
    'mini':{
        en: 'mini',
        arm: 'մինի',
        ru:'мини',
        ch:'迷你'
    },
    'total price':{
        en: 'Total price',
        arm: 'Ընդամենը',
        ru:'Всего',
        ch:'总价'
    },
    'sign up to continue':{
        en: 'sign up to continue',
        arm: 'Գրանցվեք շարունակելու համար',
        ru:'Зарегистрируйтесь, чтобы продолжить',
        ch:'注册为了继续'
    },
    'is invalid':{
        en: 'invalid',
        arm: 'անվավեր',
        ru:'не соответствует',
        ch:'不匹配'
    },
    'is too short':{
        en: 'too short',
        arm: 'կարճ է',
        ru:'коротко',
        ch:'短'
    },
    'is required':{
        en: 'required',
        arm: 'պարտադիր',
        ru:'обязательно',
        ch:'一定'
    },
    'Enter your Email':{
        en: 'Enter your Email',
        arm: 'Մուտքագրեք ձեր էլ․հասցեն ',
        ru:'Введите Ваш адрес электронной почты',
        ch:'输入您的电子邮件地址'
    },
    'Enter your Full name':{
        en: 'Enter your full name',
        arm: 'Մուտքագրեք ձեր անունը',
        ru:'Введите Ваше полное имя',
        ch:'输入您的全名'
    },
    'Enter your Password':{
        en: 'Enter your password',
        arm: 'Մուտքագրեք գաղտնաբառը',
        ru:'Введите Ваш пароль',
        ch:'输入您的密码'
    },
    'Enter new password':{
        en: 'Enter new password',
        arm: 'Մուտքագրեք նոր գաղտնաբառ',
        ru:'Введите новый пароль',
        ch:'输入您的新的密码'
    },
    'password not match':{
        en: 'passwords do not match',
        arm: 'չեն համապատասխանում',
        ru:'пароли не совпадают',
        ch:'密码不匹配'
    },
    'Error purchase':{
        en: 'Payment error',
        arm: 'Վճարումը չի կատարվել',
        ru:'Ошибка платежа',
        ch:'付款错误'
    },
    'Congratulations! Your Plan has activated':{
        en: 'Congratulations! Your package is active',
        arm: 'Շնորհավորում ենք: Ձեր փաթեթը ակտիվ է',
        ru:'Поздравляем! Ваш пакет активен',
        ch:'恭喜！ 您的包裹是有效的。'
    },
    'Congratulations! Your project was successfully confirmed and the ready rendered images/videos will be sent to you soon.':{
        en: 'Congratulations! Your project was successfully confirmed and the ready rendered images/videos will be sent to you soon.',
        arm: 'Շնորհավորում ենք: Պրոեկտը ընդունված է, շուտով դուք կստանաք նկարները։ / վիդեոները:',
        ru:'Поздравляем! Ваш проект подтвержден, скоро Вы получите готовые изображения / видео.',
        ch:'恭喜！ 您的项目已经确认，很快您就会收到现成的图片视频。'
    },
    'Please wait before upload all files':{
        en: 'Please wait until all files upload',
        arm: 'Խնդրում ենք սպասել մինչև բոլոր ֆայլերը կվերբեռնվեն',
        ru:'Пожалуйста подождите, пока все файлы будут загружены',
        ch:'请等待所有文件上传。'
    },
    'my balance':{
        en: 'my balance',
        arm: 'իմ բալանսը',
        ru:'мой баланс',
        ch:'我的余额'
    },
    'Your Balance':{
        en: 'Your balance',
        arm: 'Ձեր բալանսը',
        ru:'Ваш баланс',
        ch:'您的余额'
    },
    'Gemvy Scene':{
        en: 'Gemvy',
        arm: 'Gemvy',
        ru:'Gemvy',
        ch:'Gemvy'
    },
    'Scene':{
        en: 'Scene',
        arm: 'Սցենա',
        ru:'Сцена',
        ch:'舞蹈'
    },
    'video':{
        en: 'video',
        arm: 'Վիդեո',
        ru:'видео',
        ch:'舞台'
    },
    'Current password':{
        en: 'Current password',
        arm: 'Ընթացիկ գաղտնաբառը',
        ru:'Текущий пароль',
        ch:'当前密码'
    },
    'continue':{
        en: 'continue',
        arm: 'շարունակել',
        ru:'Продолжить',
        ch:'继续'
    },
    'Enter Project Name':{
        en: 'Enter Project Name',
        arm: 'Մուտքագրեք պրոեկտի անունը',
        ru:'Введите название проекта',
        ch:'输入项目名称'
    },

    /*assana bugs*/

    'not paid':{
        en: 'not paid',
        arm: 'վճարված չէ',
        ru:'не оплачено',
        ch:'没有报酬'
    },
    image:{
        en: 'image',
        arm: 'նկար',
        ru:'изображение',
        ch:'图片'
    },
    'images':{
        en: 'images',
        arm: 'նկարներ',
        ru:'изображения',
        ch:'图画'
    },
    'videos':{
        en: 'videos',
        arm: 'տեսանյութեր',
        ru:'видео',
        ch:'视频'
    },
    'format':{
        en: 'format',
        arm: 'ֆորմատ',
        ru:'формат',
        ch:'格式'
    },
    'duration':{
        en: 'duration',
        arm: 'տեւողություն',
        ru:'продолжительность',
        ch:'持续时间'
    },
    'Select Scene':{
        en: 'Select Scene',
        arm: 'Ընտրեք տեսարան',
        ru:'Выберите сцену',
        ch:'选择场景'
    },
    'file upload':{
        en: 'File Upload',
        arm: 'ֆայլի վերբեռնումը',
        ru:'Загрузка файла',
        ch:'文件上传'
    },
    'per':{
        en: 'per',
        arm: 'մեկ',
        ru:'за',
        ch:'每'
    },
    'picture':{
        en: 'picture',
        arm: 'նկարը',
        ru:'картина',
        ch:'图片'
    },
    'Purchase Confirmation':{
        en: 'Purchase Confirmation',
        arm: 'Գնման հաստատում',
        ru:'Подтверждение покупки',
        ch:'确认证明'
    },
    'current balance':{
        en: 'current balance',
        arm: 'ընթացիկ բալանս',
        ru:'баланс',
        ch:'平衡'
    },
    'total':{
        en: 'total',
        arm: 'Գումարը',
        ru:'Всего',
        ch:'總額'
    },
    'proceed to checkout':{
        en: 'proceed to checkout',
        arm: 'հաստատել',
        ru:'оформить заказ',
        ch:'下订单'
    },
    'confirm project':{
        en: 'confirm project',
        arm: 'հաստատել պրոեկտը',
        ru:'подтвердить проект',
        ch:'确认项目'
    },
    'more packages':{
        en: 'more packages',
        arm: 'ավելին',
        ru:'больше пакетов',
        ch:'更多套餐'
    },
    'renderings':{
        en: 'renderings',
        arm: 'ռենդերինգներ',
        ru:'рендеринги',
        ch:'渲染'
    },
    'recommended for you':{
        en: 'recommended for you',
        arm: 'Հատուկ Ձեզ համար',
        ru:'Рекомендации',
        ch:'特别适合你'
    },
    'Pricing':{
        en: 'Pricing',
        arm: 'Գնացուցակ',
        ru:'Цены',
        ch:'价格'
    },
    'Choose a plan that works for you':{
        en: 'Choose a plan that works for you',
        arm: 'Ընտրեք համապատասխան փաթեթ',
        ru:'Выберите предпочитаемый пакет',
        ch:'选择您喜欢的包裹'
    },
    'photo renderings':{
        en: 'photo renderings',
        arm: 'ֆոտոռենդերինգ',
        ru:'фото-рендеринг',
        ch:'照片渲染'
    },
    'video renderings':{
        en: 'video renderings',
        arm: 'վիդեոռենդերինգ',
        ru:'видео-рендеринг',
        ch:'视频渲染'
    },
    'combo packs':{
        en: 'combo packs',
        arm: 'կոմբո փաթեթ',
        ru:'комбо-пакеты',
        ch:'组合包'
    },
    'choose plan':{
        en: 'choose plan',
        arm: 'ընտրել փաթեթ',
        ru:'выбрать план',
        ch:'选择包裹'
    },
    'Mini':{
        en: 'Mini',
        arm: 'Մինի',
        ru:'Мини',
        ch:'迷你'
    },
    'Basic':{
        en: 'Basic',
        arm: 'Հիմնական',
        ru:'Основной',
        ch:'主要的'
    },
    'Pro':{
        en: 'Pro',
        arm: 'Պրո',
        ru:'Про',
        ch:'关于'
    },
    'Premium':{
        en: 'Premium',
        arm: 'Պրեմիում',
        ru:'премиум',
        ch:'额外费用'
    },

    /*file upload rules full text*/

    'Technical Information':{
        en: 'Technical Information',
        arm: 'Տեխնիկական տեղեկատվություն',
        ru:'Техническая информация',
        ch:'技术信息'
    },
    'Object Positions':{
        en: 'Object Positions',
        arm: 'Օբյեկտի դիրքերը',
        ru:'Позиции объектов',
        ch:'对象位置'
    },
    'Individual Views':{
        en: 'Individual Views',
        arm: 'Անհատական ռակուրսներ',
        ru:'Индивидуальные ракурсы',
        ch:'个人'
    },
    'Check for Hidden Objects':{
        en: 'Check for Hidden Objects',
        arm: 'Ստուգել “Hidden Objects”-ների առկայությունը',
        ru:'Проверка на наличие скрытых объектов',
        ch:'检查隐藏的对象'
    },
    'Obligatory Conditions for Using Rendline.com':{
        en: 'Obligatory Conditions for Using Rendline.com',
        arm: 'Rendline.com- ի օգտագործման պարտադիր պայմաններ',
        ru:'Обязательные условия использования Rendline.com',
        ch:'强制性使用条款Rendline.com'
    },
    "The 3D CAD file must be in the '.3dm' format":{
        en: 'The 3D CAD file must be in the \'.3dm\' format',
        arm: '3D CAD ֆայլը պետք է լինի \'.3dm\' ֆորմատով',
        ru:'3D CAD файл должен быть в формате .3dm',
        ch:'文件必须采用格式3dm'
    },
    "The file size should not exceed 100 mb":{
        en: 'The file size should not exceed 100 mb',
        arm: 'Ֆայլի չափը չպետք է գերազանցի 100 մբ',
        ru:'Размер файла не должен превышать 100 мб',
        ch:'文件大小不应超过100 MB'
    },
    "The file must not contain bad objects":{
        en: 'The file must not contain bad objects',
        arm: 'Ֆայլը չպետք է պարունակի “bad objects”-ներ',
        ru:'Файл не должен содержать “bad objects”',
        ch:'文件不该包含“bad objects”'
    },
    "Note that the program will render":{
        en: 'Note that the program will render all the hidden objects as well. For this very reason it is recommended that the file does not contain any hidden objects (see Point 4)',
        arm: 'Ուշադրություն դարձրեք, որ ծրագիրը նաև կցուցադրի բոլոր “hidden objects”-ները: Այդ պատճառով խորհուրդ է տրվում, որ ֆայլը չպարունակի որևէ “hidden objects” (Նկար 4)',
        ru:'Обратите внимание на то, что программа также отобразит все скрытые объекты. По этой причине рекомендуется, чтобы файл не содержал никаких скрытых объектов (см. Пункт 4)',
        ch:'请注意，该程序还将显示所有隐藏的对象。 因此，建议文件不包含任何隐藏对象。'
    },
    "All those layers which do not":{
        en: 'All those layers which do not correspond to our standard layer names will be deleted, removing all existing in them objects. Therefore, we strongly recommend to save all necessary objects with standard names of RendLine.com.',
        arm: 'Բոլոր այն layer-ները, որոնք չեն համապատասխանում մեր ստանդարտ layer-ների անվանումներին, կհեռացվեն՝ ջնջելով նրանում առկա բոլոր օբյեկտները: Խորհուրդ է տրվում պահպանել բոլոր անհրաժեշտ օբյեկտները Rendline.com-ի ստանդարտ անվանումներին համապատասխան:',
        ru:'Все те слои, которые не соответствуют нашим стандартным именам слоев, будут удалены, также удалив все существующие в них объекты. Поэтому мы настоятельно рекомендуем сохранять все необходимые объекты со стандартными именами Rendline.com.',
        ch:'将删除与我们的标准图层名称不对应的所有图层，同时删除其中存在的所有对象。 因此，我们强烈建议使用标准名称保存所有必需的对象Rendline.com.'
    },
    "The layers names are:":{
        en: 'The layers names are',
        arm: 'Layer-ների անվանումները`',
        ru:'Имена слоев должны быть:',
        ch:'图层名称必须是'
    },
    "Layer":{
        en: 'Layer',
        arm: 'Շերտ',
        ru:'Слой',
        ch:'一层'
    },
    "Please note that when making a 360º":{
        en: 'Please note that when making a 360º video rendering the camera rotates around the 0.0 axis. In order to achieve an accurate result on Rendline.com it is needed that the object is placed in the preferred position on the 0.0 point.',
        arm: 'Ուշադրություն դարձրեք, որ 360º վիդեոռենդերինգ ստեղծելիս տեսախցիկը պտտվում է 0․0 առանցքի շուրջ: Rendline.com-ում ճշգրիտ արդյունք ստանալու համար անհրաժեշտ է տեղադրել օբյեկտը նախընտրած դիրքով՝ 0․0 կետում։',
        ru:'Обратите внимание на то, что при создании  360º-ого видео-рендеринга, камера вращается вокруг оси 0.0. Для достижения точного результата на Rendline.com необходимо, чтобы объект был помещен в предпочтительное положение на точке 0.0.',
        ch:'请注意，在创建360º视频渲染时，相机会围绕0.0轴旋转。 要在Rendline.com上获得准确的结果，必须将对象放置在该点上的首选位置'
    },
    "In order to see all hidden objects":{
        en: 'In order to see all hidden objects write “_Show” in the Command Line and press Enter. All the hidden objects will come into sight. In case of not possessing hidden models, in the Command Line of the program you will see the following sentence: “No objects are hidden.”',
        arm: 'Բոլոր : Բոլոր “hidden object”-ները տեսնելու համար “Command Line”-ում գրեք «_Show» և սեղմեք «Enter» կոճակը: Բոլոր “hidden object”-ները կդառնան տեսանելի։ “Hidden object”-ների բացակայության դեպքում ծրագրի “Command Line”-ում Դուք կտեսնենք հետևյալ նախադասությունը․ “No objects are hidden.”։',
        ru:'Для того, чтобы увидеть все скрытые объекты, напишите «_Show» в командной строке и нажмите «Enter». Все скрытые объекты станут видны. В случае отсутствия скрытых моделей в командной строке программы Вы увидите следующее предложение: «Нет скрытых объектов».',
        ch:'要查看所有隐藏的对象，请在命令行中键入“_Show”并按“Enter”。 所有隐藏的对象都是可见的。 如果程序命令行中没有隐藏模型，您将看到以下句子：“没有隐藏的对象。'
    },
    "In order to achieve an accurate":{
        en: 'In order to achieve an accurate result on Rendline.com it is needed that the object is placed in the corresponding standard position (see Picture 1). In case of not corresponding to Picture 1, the displayed website views will not match with the achieved result.',
        arm: 'Rendline.com-ում ճշգրիտ արդյունքի հասնելու համար անհրաժեշտ է օբյեկտը տեղադրել համապատասխան ստանդարտ դիրքով (Նկար 1): Նկար 1-ին չհամապատասխանելու դեպքում՝ կայքում ցուցադրվող ռակուրսները չեն համընկնի ստացած արդյունքի հետ:',
        ru:'Для достижения точного результата на Rendline.com необходимо поместить объект в соответствующее стандартное положение (см. Рисунок 1). В случае несоответствия Рисунку 1 отображаемые на веб-сайте ракурсы не будут совпадать с полученным результатом.',
        ch:'要在Rendline.com上获得准确的结果，必须将对象放在适当的标准位置（参见图1）。 如果图1中出现差异，网站上显示的视图将与结果不符。'
    },
    "Picture":{
        en: 'picture',
        arm: 'նկարը',
        ru:'изображение',
        ch:'图片'
    },
    "If you do not wish to use our standard views":{
        en: 'If you do not wish to use our standard views, you can create your own ones. In order to do so see Point 3 (How to Create Individual Views for your Models)',
        arm: 'Եթե չեք ցանկանում օգտագործել մեր ստանդարտ ռակուրսները, Դուք կարող եք ստեղծել ձեր անհատականները: Ավելին իմանալու համար նայեք Կետ 3-ը (Ինչպե՞ս ստեղծել անհատական ռակուրսներ Ձեր մոդելների համար)',
        ru:'Если Вы не желаете использовать наши стандартные ракурсы, Вы можете создать свои индивидуальные. Для этого смотрите Пункт 3 (Как создать индивидуальные ракурсы для моделей)',
        ch:'如果您不想使用我们的标准视图，您可以创建自己的标准视图。 参见第3项（如何为模型创建单独的视图）'
    },
    "All views are created for rings":{
        en: 'All views are created for rings. If you wish to render earrings, bracelets, and other jewelry pieces or items, then we would recommend you to use the views created by you. For this reason see Point 3 (How to Create Individual Views for your Models)',
        arm: 'Բոլոր ռակուրսները ներկայացված են մատանիների համար: Եթե ցանկանում եք ստանալ օղերի, ձեռնաշղթաների և այլ ոսկերչական զարդերի ռենդերինգներ, խորհուրդ ենք տալիս օգտագործել ձեր կողմից ստեղծված ռակուրսները: Ավելին իմանալու համար նայեք Կետ 3-ը (Ինչպե՞ս ստեղծել անհատական ռակուրսներ Ձեր մոդելների համար)։',
        ru:'Все ракурсы представлены для колец. Если Вы хотите получить рендеринг серег, браслетов или других ювелирных изделий, в этом случае мы рекомендуем Вам использовать созданные Вами ракурсы. Для этого смотрите Пункт 3 (Как создать индивидуальные ракурсы для моделей)',
        ch:'所有角度都显示为环。 如果您想渲染耳环，手镯或其他珠宝，在这种情况下，我们建议您使用您创建的视图。 参见第3项（如何为模型创建单独的视图）'
    },
    "How to Create Individual Views for your Models":{
        en: 'How to Create Individual Views for your Models',
        arm: 'Ինչպե՞ս ստեղծել անհատական ռակուրսներ Ձեր մոդելների համար',
        ru:'Как создать индивидуальные ракурсы для моделей',
        ch:'如何为模型创建单独的视图'
    },
    "First of all, set your preferred view in":{
        en: 'First of all, set your preferred view in Rhinoceros manually. Then write “_NamedView ” in the Command Line and press Enter. Click the Save button in the open window, write your preferred name and confirm. In the future this name should be used in the “Individual View’ section of Rendline.com (see Pictures 2,3,4)',
        arm: 'Նախ Rhinoceros-ում կարգավոր եք Ձեզ համար նախընտրելի ռակուրսը: Այնուհետև “Command Line”-ում գրեք “_NamedView” և սեղմեք “Enter”: Բացված պատուհանում սեղմեք “Save” կոճակը, գրեք անվանումը և հաստատեք: Հետագայում այն պետք է օգտագործվի Rendline.com-ի “Individual View” բաժնում (Նկար 2,3,4)',
        ru:'В первую очередь вручную установите предпочтительный для Вас ракурс в Rhinoceros. Затем напишите «_NamedView» в командной строке и нажмите «Enter». Нажмите кнопку «Save» в открывшемся окне, напишите название и подтвердите. В будущем это имя должно использоваться в разделе «Индивидуальный ракурс» на Rendline.com (смотрите Рисунки 2, 3, 4)',
        ch:'首先在Rhinoceros中手动设置您的首选角度。 然后在命令行中键入“_NamedView”并按“Enter”。单击打开的窗口中的“保存”按钮，写下名称并确认。 将来，此名称应在Rendline.com的“个人视图”部分中使用（参见图2,3,4'
    },
    "You can add any number of Views.":{
        en: 'You can add any number of Views.',
        arm: 'Դուք կարող եք ավելացնել ցանկացած քանակությամբ ռակուրսներ:',
        ru:'Вы можете добавить любое количество ракурсов.',
        ch:'您可以添加任意数量的角度。'
    },
    'What is StyleBox? StyleBox is a necessary block':{
        en: 'What is StyleBox? StyleBox is a necessary block with the help of which you can set materials and views for one or more files.',
        arm: 'Ի՞նչ է սթայլբոքսը: Սթայլբոքսը բլոկ է, որի օգնությամբ դուք կարող եք ընտրել մատերիալներ ու անկյուններ՝ մի կամ մի քանի ֆայլերի համար։',
        ru:'Что такое стайлбокс? Стайлбокс - это необходимый блок, с помощью которого Вы сможете выбрать материалы и ракурсы для одного или более файлов.',
        ch:'什么是stylebox？（stylebox？是什么） 样式框是必要的块，您可以使用该块为一个或多个文件选择材质和视图。'
    },
    "In this block you can add one or some":{
        en: 'In this block you can add one or some of your uploaded files. The renderings for all these files will be with the same StyleBox.',
        arm: 'Այս բլոկում Դուք կարող եք ավելացնել մեկ կամ մի քանի վերբեռնված ֆայլեր: Ռենդերինգը այդ բոլոր ֆայլերի համար կլինի նույն Սթայլբոքսի կարգավորումներով:',
        ru:'В этом блоке Вы можете добавить один или несколько загруженных файлов. Рендеринги для всех этих файлов будут с настройками одного и того же стайлбокса.',
        ch:'在本节中，您可以添加一个或多个下载的文件。 所有这些文件的渲染将使用相同样式框的设置。'
    },
    "You can save the StyleBox and then use it again in other projects.":{
        en: 'You can save the StyleBox and then use it again in other projects.',
        arm: 'Դուք կարող եք պահպանել Սթայլբոքսը և այն կրկին օգտագործել այլ պրոեկտներում:',
        ru:'Вы можете сохранить стайлбокс и впоследствии использовать в других проектах.',
        ch:'您可以保存StyleBox，然后在其他项目中使用它。'
    },
    "In this block you choose a material for your layers. Please note":{
        en: 'In this block you choose a material for your layers. Please note that the layer name on the website should match the layer names in your 3D file (layer 01, layer 02, etc.)',
        arm: 'Այս բլոկում Դուք ընտրում եք մատերիալներ Ձեր layer-ների համար: Խնդրում ենք նկատի ունենալ, որ կայքում layer-ի անվանումը պետք է համապատասխանի Ձեր 3D ֆայլում layer-ի անվանմանը։ (layer 01, layer 02, և այլն)',
        ru:'В этом блоке Вы выбираете материалы для своих слоев. Обратите внимание на то, что имя слоя на веб-сайте должно соответствовать именам слоев в Вашем 3D файле (layer 01, layer 02, и т.д.).',
        ch:'在此块中，您可以选择图层的材质。 请注意，网站上的图层名称必须与3D文件中的图层名称匹配（图层01，图层02等）。'
    },
    "In this block you add views in which you would like to render all the models in the StyleBox.":{
        en: 'In this block you add views in which you would like to render all the models in the StyleBox.',
        arm: 'Այս բլոկում Դուք ավելացնում եք ռակուրսներ, որոնցով ցանկանում եք ռենդ անել Սթայլբոքսում գտնվող բոլոր մոդելները:',
        ru:'В этом блоке Вы добавляете ракурсы, с которых Вы бы хотели получить рендеринг всех моделей в стайлбоксе.',
        ch:'在此块中，您可以添加角度，您希望在其中接收样式框中所有模型的渲染(StyleBox).。'
    },
    "Select material":{
        en: 'Select material',
        arm: 'Ընտրեք մատերիալ',
        ru:'Выберите материал',
        ch:'选择材料'
    },
    "gotit!":{
        en: 'got it!',
        arm: 'հաստատել!',
        ru:'ПОДТВЕРДИТЬ',
        ch:'好的'
    },
    "id":{
        en: 'id',
        arm: 'id',
        ru:'id',
        ch:'ID'
    },
    "date":{
        en: 'date',
        arm: 'ամսաթիվ',
        ru:'Дата',
        ch:'号'
    },
    "view PDF":{
        en: 'view PDF',
        arm: 'նայել PDF',
        ru:'посмотреть PDF',
        ch:'看看PDF'
    },
    'Don’t have an account?':{
        en: 'Don’t have an account?',
        arm: 'Չունե՞ք ակաունտ։',
        ru:'Нет аккаунта?',
        ch:'没有帐户？'
    },
    'Select preferred views':{
        en: 'Select preferred views',
        arm: 'Ընտրեք նախընտրելի ռակուրսները',
        ru:'Выбрать предпочитаемые ракурсы',
        ch:'选择首选角度'
    },
    'Background':{
        en: 'Background',
        arm: 'Ֆոն',
        ru:'Фон',
        ch:'背景'
    },
    "invoices":{
        en: 'Invoices',
        arm: 'Հաշիվներ',
        ru:'Счета',
        ch:'发票, 单据'
    },
    "main-address":{
        en: '4, Northern Ave. Yerevan',
        arm: 'Երևան, Հյուսիսային պող. 4',
        ru:'Северный проспект 4, Ереван',
        ch:'北大街4号埃里温'
    },
    "My profile":{
        en: 'My profile',
        arm: 'Իմ էջը',
        ru:'Мой профайл',
        ch:'我的简历'
    },
    "settings":{
        en: 'Settings',
        arm: 'Կարգավորումներ',
        ru:'Настройки',
        ch:'设置'
    },
    /*steps guide*/

    "step-1":{
        en: 'Click on the button “Add Stylebox” in order to make the settings for your renderings.',
        arm: 'Սեղմեք "Add Stylebox" կոճակը՝ ռենդերինգի կարգավորումները անելու համար:',
        ru:'Нажмите на "Add Stylebox", чтобы сделать настройки для рендеринга.',
        ch:'单击“添加样式框”按钮以进行渲染设置。'
    },
    "step-2":{
        en: 'By clicking on the button “Add New Stylebox” you can make any number of Styleboxes in the future.',
        arm: 'Սեղմելով "Add New Stylebox" կոճակը՝ Դուք ապաքայում կկարողանաք ստեղծել ցանկացած քանակությամբ սթայլբոքսեր:',
        ru:'Нажав на "Add New Stylebox", Вы сможете создать любое количество стайлбоксов в будущем.',
        ch:'通过单击“添加新样式框”按钮，您可以在将来创建任意数量的样式框。'
    },
    "step-3":{
        en: 'Click here to attach the files to be rendered with the settings in this Stylebox.',
        arm: 'Կցեք ռենդերինգի համար նախատեսված ֆայլերը:',
        ru:'Прикрепите файлы для создания рендерингов с настройками данного стайлбокса.',
        ch:'单击此处附加要使用此样式框中的设置呈现的文件。'
    },
    "step-4":{
        en: 'Choose the preferred materials for the stone and metal.',
        arm: 'Ընտրեք քարի և մետաղի համար նախընտրած նյութերը: ',
        ru:'Выберите предпочитаемые материалы для камня и металла. ',
        ch:'选择石材和金属的首选材料。'
    },
    "step-5":{
        en: 'Choose the angles in which you would like your rendered images to be.',
        arm: 'Ընտրեք այն ռակուրսները, որտեղից կցանկանայիք տեսնել Ձեր ոսկերչական զարդը:',
        ru:'Выберите ракурсы, с которых Вы бы хотели видеть Ваше ювелирное изделие.',
        ch:'选择您希望渲染图像的角度。'
    },
    "Types of files allowed:.3dm .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd":{
        en: 'Types of files allowed: \n' +
            '                                   .3dm, .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd',
        arm: 'Թույլատրված ֆայլերի տեսակները` \n' +
            '                                   .3dm, .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd',
        ru: 'Разрешенные типы файлов: \n' +
            '                                   .3dm, .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd',
        ch: '允许的文件类型: \n' +
            '                                   .3dm, .jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .ogg, .wmv, .mpeg, .mov, .avi, .zip, .rar, .xls, .xlsx, .stl, .obj, .txt, .psd'
    },
    "Please, describe the model of a jewelry piece that you would like us to create and/or attach photos or files that will provide us with more detailed information.":{
        en: 'Please, describe the model of a jewelry piece that you would like us to create and/or attach photos or files that will provide us with more detailed information.',
        arm: 'Խնդրում ենք նկարագրել մի ոսկերչական կտոր մոդելը, որը ցանկանում եք ստեղծել եւ / կամ կցել լուսանկարներ կամ ֆայլեր, որոնք մեզ ավելի մանրամասն տեղեկատվություն կտրամադրեն:',
        ru:'Пожалуйста, опишите ювелирное изделие, 3D модель которого Вы бы хотели получить, и/или приложите фотографии или файлы, которые предоставят нам более подробную информацию о модели.',
        ch:'请描述您希望我们创建和/或附加照片或文件的珠宝首饰模型，以便为我们提供更详细的信息。'
    },
    "description":{
        en: 'Description',
        arm: 'Նկարագրություն',
        ru:'Описание',
        ch:'描述'
    },
    "Attach files":{
        en: 'Attach files',
        arm: 'Կցել ֆայլերը',
        ru:'Прикрепить файлы',
        ch:'附加文件'
    },
    "Request for a 3D model":{
        en: 'Request for a 3D model',
        arm: '3D մոդելի դիմում',
        ru:'Запрос на 3D модель',
        ch:'请求3D模型'
    },
    "Choose files":{
        en: 'Choose files',
        arm: 'Ընտրեք ֆայլեր',
        ru:'Выберите файлы',
        ch:'选择文件'
    },
    "Send request":{
        en: 'Send',
        arm: 'Ուղարկել',
        ru:'отправить',
        ch:'发送'
    },
    "Professional modelers certified by Rendline on the basis of an examination on testing jewelry production knowledge, 3D-printing skills and creating files according to Rendline's standards will contact you within 24 hours.":{
        en: 'Professional modelers certified by Rendline on the basis of an examination on testing jewelry production knowledge, 3D-printing skills and creating files according to Rendline\'s standards will contact you within 24 hours.',
        arm: 'Rendline- ի կողմից հավաստագրված մասնագիտական մոդելավորողներ, ոսկերչական արտադրության գիտելիքների փորձարկման, 3D- տպիչ հմտությունների եւ Rendline- ի չափանիշներին համապատասխան ֆայլեր ստեղծելու փորձի հիման վրա, 24 ժամվա ընթացքում կապ կհաստատեն ձեզ հետ:',
        ru:'Профессиональные специалисты, сертифицированные со стороны Rendline на основании проверки знаний в области ювелирного производства, навыков 3D-печати и создания файлов в соответствии со стандартами Rendline, свяжутся с вами в течение 24 часов.',
        ch:'Rendline认证的专业建模师根据Rendline标准测试珠宝生产知识，3D打印技能和创建文件，将在24小时内与您联系。'
    },
    "Don't you have a":{
        en: "Don't you have a",
        arm: 'Չունե՞ք',
        ru:'Нет',
        ch:'你没有'
    },
    "3d model":{
        en: "3d model?",
        arm: '3D մոդել',
        ru:'3D модели?',
        ch:'3d模型'
    },
    "order now":{
        en: "order now",
        arm: 'Պատվիրել հիմա',
        ru:'Заказать сейчас',
        ch:'现在下单'
    },
    'modelThankyouDescrption':{
        en: "Your order has been submitted! Certified by Rendline modeling specialists will contact you via email.",
        arm: 'Ձեր պատվերն ընդունված է։ Մեր կողմից հավաստագրված մասնագետները կկապնվեն ձեր հետ էլ. հասցեի միջոցով:',
        ru:'Ваш заказ принят! Сертифицированные от Rendline специалисты по моделированию свяжутся с Вами по электронной почте.',
        ch:'您的订单已提交！ Rendline建模专家认证将通过电子邮件与您联系。'
    },
    'yourPasswordRecoveryLinkHasBeen':{
        en: "Your password recovery link has been sent to your email address. Please, check it.",
        arm: 'Ձեր գաղտնաբառի վերականգնման հղումը ուղարկվել է ձեր էլ.փոստի հասցեին: Խնդրում ենք ստուգել այն:',
        ru:'Ссылка для восстановления пароля была отправлена на Ваш адрес эл. почты. Пожалуйста, проверьте.',
        ch:'您的密码恢复链接已发送到您的电子邮件地址。 请检查一下。'
    },
    'yourPasswordSuccessfullyChanged':{
        en: "Your password successfully changed",
        arm: 'Ձեր գաղտնաբառը հաջողությամբ փոխվել է',
        ru:'Ваш пароль изменен',
        ch:'您的密码已成功更改'
    },
    'thankYouForChoosingRendLine.com!':{
        en: "Thank you for choosing Rendline! Please, check your email to activate your account.",
        arm: 'Շնորհակալություն Rendline-ը ընտրելու համար: Խնդրում ենք ստուգել ձեր էլ.փոստը `ձեր հաշիվը ակտիվացնելու համար:',
        ru:'Благодарим Вас за использование услуг Rendline. Пожалуйста, проверьте Ваш адрес эл. почты, чтобы активировать свой аккаунт.',
        ch:'感谢您选择Rendline！ 请检查您的电子邮件以激活您的帐户。'
    },
    "Email already exist":{
        en: 'Email already exists',
        arm: 'Էլ․ հասցեն գոյություն ունի',
        ru:'Эл․ почта уже существует',
        ch:'电子邮件已经存在'
    },
    "Invalid Mail":{
        en: 'Invalid email address',
        arm: 'Սխալ էլ․ հասցե',
        ru:'Неверный адрес эл․ почты',
        ch:'无效的邮件地址'
    },
    "Dont Activate":{
        en: 'Not activated',
        arm: 'Ակտիվացված չէ',
        ru:'Не активирован',
        ch:'未激活'
    },
    "User Dont exist":{
        en: 'User doesn\'t exist',
        arm: 'Օգտատերը գոյություն չունի',
        ru:'Пользователь не существует',
        ch:'未激活'
    },
    "Token dont exist":{
        en: 'Token not found',
        arm: 'Token-ը չի գտնվել',
        ru:'Токен не найден',
        ch:'令牌未找到'
    },
    "Error Authentication":{
        en: 'Authentication error',
        arm: 'Նույնականացման սխալ',
        ru:'Ошибка аутентификации',
        ch:'授权错误'
    },
    "Account dont activate":{
        en: 'Account not activated',
        arm: 'Հաշիվը ակտիվացված չէ',
        ru:'Аккаунт не активирован',
        ch:'帐户未激活'
    },
    "files dont send":{
        en: 'Files are not sent',
        arm: 'Ֆայլեր չեն ուղարկվել',
        ru:'Файлы не отправлены',
        ch:'文件不会被发送'
    },
    "Dont save uploaded file":{
        en: 'Uploaded files are not saved',
        arm: 'Ներբեռնված ֆայլերը չեն պահպանվել',
        ru:'Загруженные файлы не сохранены',
        ch:'上传的文件不会保存'
    },
    "Dont save":{
        en: 'Not saved',
        arm: 'Չի պահպանվել',
        ru:'Не сохраняется',
        ch:'没保存'
    },
    "Dont Change Password":{
        en: 'Password is not changed',
        arm: 'Գաղտնաբառը չի փոխվել',
        ru:'Пароль не изменён',
        ch:'密码不会更改'
    },
    "Error Project":{
        en: 'Project error',
        arm: 'Գաղտնաբառը',
        ru:'Ошибка проекта',
        ch:'项目错误'
    },
    "Balance Error":{
        en: 'Balance error',
        arm: 'Բալանսի սխալ',
        ru:'Ошибка баланса',
        ch:'平衡错误'
    },
    "Wrong Token":{
        en: 'Wrong token',
        arm: 'Սխալ Token',
        ru:'Неправильный токен',
        ch:'错误的令牌'
    },
    "Project Name Exist":{
        en: 'Project name already exists',
        arm: 'Պրոեկտի անունը գոյություն ունի ',
        ru:'Название проекта уже существует',
        ch:'项目名称已存在'
    },
    "You can upload only 3dm file":{
        en: 'Only .3dm files can be uploaded',
        arm: 'Միայն .3dm ֆայլերը կարող են վերբեռնվել',
        ru:'Только .3dm файлы могут быть загружены',
        ch:'项目名称已存在'
    },
    "StyleBox name exist":{
        en: 'StyleBox name already exists',
        arm: 'StyleBox-ի անունը գոյություն ունի',
        ru:'Имя StyleBox-а уже существует',
        ch:'项目名称已存在'
    },
    "Describe the jewelry model...":{
        en: 'Describe the jewelry model...',
        arm: 'Նկարագրեք ոսկերչական մոդելը ...',
        ru:'Опишите ювелирную модель ...',
        ch:'描述珠宝模型......'
    },
    "name":{
        en: 'name',
        arm: 'անվանում',
        ru:'название',
        ch:'名称'
    },
    "my bonus gift":{
        en: 'my bonus gift',
        arm: 'իմ բոնուսային նվերը',
        ru:'мой бонусный подарок',
        ch:'我的奖金礼物'
    },
    "You have received a $100 gift card, which you can use by buying photo rendering packages. With each purchase you can use 10% of the chosen package price from the gift card. The gift card can be used within 60 days. ":{
        en: 'You have received a $100 gift card, which you can use by buying photo rendering packages. With each purchase you can use 10% of the chosen package price from the gift card. The gift card can be used within 60 days. ',
        arm: 'Դուք ստացել եք $100 նվեր քարտ, որը կարող եք օգտագործել ֆոտո-ռենդերինգի փաթեթները գնելիս։ Յուրաքանչյուր գնման դեպքում Դուք կարող եք օգտագործել ընտրված փաթեթի արժեքի 10%-ը նվեր քարտից։ Նվեր քարտը կարող է օգտագործվել 60 օրվա ընթացքում:',
        ru:'Вы получили подарочную карту суммой в 100 долларов, которую Вы можете использовать при покупке пакетов для фото-рендеринга.  При каждой покупкой Вы можете использовать 10% стоимости выбранного пакета с подарочной карты. Подарочную карту можно использовать в течение 60 дней.',
        ch:'您已收到100美元的礼品卡，您可以通过购买照片渲染套餐来使用。 每次购买，您可以使用礼品卡中所选套餐价格的10％。 礼品卡可在60天内使用。'
    },
    "Gift Card":{
        en: 'Gift Card',
        arm: 'Նվեր քարտ',
        ru:'Подарочная карта',
        ch:'礼物卡'
    },
    "Wait for download to complete":{
        en: 'Wait for download to complete',
        arm: 'Սպասեք բեռնման ավարտին',
        ru:'Подождите, пока загрузка завершится',
        ch:'等待下载完成'
    },
    "download":{
        en: 'download',
        arm: 'բեռնել',
        ru:'скачать',
        ch:'下载'
    },
    "Changes successfully saved":{
        en: 'Changes successfully saved',
        arm: 'Փոփոխությունները հաջողությամբ պահպանվել են ',
        ru:'Изменения успешно сохранены',
        ch:'更改已成功保存'
    },
    "File does not match":{
        en: 'File does not match',
        arm: 'Ֆայլը չի համապատասխանում ',
        ru:'Файл не соответствует',
        ch:'文件不匹配'
    },
    "File already exists": {
        en: 'File already exists',
        arm: 'Ֆայլը արդեն գոյություն ունի',
        ru:'Файл уже существует',
        ch:'文件已存在'
    },
    "Please add a Stylebox":{
        en: 'Please add a Stylebox',
        arm: 'Խնդրում ենք ավելացնել Stylebox',
        ru:'Пожалуйста добавьте Stylebox',
        ch:'请添加一个样式框'
    },
    "Please attach the files to the stylebox":{
        en: 'Please attach the files to the stylebox',
        arm: 'Խնդրում ենք կցել ֆայլերը Stylebox-ին',
        ru:'Пожалуйста, прикрепите файлы к Stylebox-у',
        ch:'请将文件附加到样式框'
    },
    "Please add views":{
        en: 'Please add views',
        arm: 'Խնդրում ենք ավելացնել ռակուրսներ',
        ru:'Пожалуйста добавьте ракурсы ',
        ch:'请添加视图'
    },
    "Please set materials for":{
        en: 'Please set materials for',
        arm: 'Խնդրում ենք ավելացնել ռակուրսներ',
        ru:'Пожалуйста установите материалы для',
        ch:'请设置材料'
    },
    "Are you sure you want to leave this page?":{
        en: 'Are you sure you want to leave this page?',
        arm: 'Արդյո՞ք ցանկանում եք լքել այս էջը։',
        ru:'Вы уверены, что хотите покинуть эту страницу?',
        ch:'你确定要离开这个页面吗？'
    },
    "Passwords does not match":{
        en: 'Passwords does not match',
        arm: 'Գաղտնաբառերը չեն համընկնում',
        ru:'Пароли не совпадают ',
        ch:'密码不匹配'
    }
}