import React, { Component } from 'react';
import './SelectScene.scss';
import Lightbox from "react-image-lightbox";
import  Slider from 'react-slick';
import PubSub from 'pubsub-js';
import Api from '../Api';
import { Translate } from 'react-translated';
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";

export default class SceneItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            loading:false,
        };
    }

    addScene=(scene)=>{
        if (this.props.projectId){
            this.setState({loading:true});
            Api.apiCall("post","projectScene",{scene:"Scene_"+scene,id:this.props.projectId}).then((resp)=>{
                this.setState({loading:false});
                if (resp.status !== 200) {
                    // console.log(resp.status);
                }
                if ('error' in resp.data) {
                    PubSub.publish('toast.error',resp.data.error);
                }else {
                    PubSub.publish("redirect.to","/project/photo/"+this.props.projectId+"/upload-rules-step");
                }
            })
        }
    };

    render(){
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            draggable:false
        };
        const { photoIndex, isOpen } = this.state;

        return(
            <div className="sceneitem">
                <div className="row align-justify align-middle sceneitem-top">
                    <div className="column">
                        <div className="sceneitem-title">
                            {this.props.scene === 8?
                                <span>
                                    <Translate text="Gemvy Scene" />
                                </span>
                                :this.props.scene === 9?
                                    <span>
                                        <Translate text="Beldiamond Scene" />
                                    </span>
                                    :this.props.scene === 10?
                                        <span>
                                        <Translate text="Droesser Scene" />
                                    </span>
                                        :

                                        <span><Translate text="Scene"/> {this.props.scene} </span>
                            }

                        </div>
                    </div>
                    <div className="column text-right">
                        <Button type="button"
                                theme="purple"
                                iconLeft="check"
                                iconLeftSize={11}
                                title="select this scene"
                                loading={this.state.loading}
                                onClick={()=>this.addScene(this.props.scene)}
                        />
                    </div>
                </div>
                <Slider {...settings}>
                    {[...Array(5)].map((x, i) =>
                        <div key={i}>
                            <div className="slider-item" onClick={() => this.setState({ isOpen: true, photoIndex:i+1,})} style={{backgroundImage:'url('+require("../../assets/img/scenes/scen_"+this.props.scene+"/"+(i+1)+".jpg")+')'}}>
                                {/*<img src={require("../../assets/img/scenes/scen_"+this.props.scene+"/"+(i+1)+".jpg")} alt="" className="img-responsive"/>*/}
                                <div className="item-hover">
                                    <Icon type="zoom"/>
                                    <span>
                                        <Translate text="see all views" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
                {isOpen && (
                    <Lightbox
                        mainSrc={require("../../assets/img/scenes/scen_"+this.props.scene+"/"+photoIndex+".jpg")}
                        nextSrc={photoIndex !== 4?require("../../assets/img/scenes/scen_"+this.props.scene+"/"+((photoIndex + 1) % 5)+".jpg"):require("../../assets/img/scenes/scen_"+this.props.scene+"/1.jpg")}
                        prevSrc={photoIndex !== 1?require("../../assets/img/scenes/scen_"+this.props.scene+"/"+((photoIndex + 4) % 5)+".jpg"):require("../../assets/img/scenes/scen_"+this.props.scene+"/5.jpg")}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: photoIndex === 1?5:(photoIndex + 4) % 5
                            })}
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: photoIndex === 4?1:(photoIndex + 1) % 5
                            })}
                    />
                )}
            </div>
        )
    }
}