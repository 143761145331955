import React, { PureComponent } from 'react';
import './UploadRulesStep.scss';
import StepTop from '../StepTop/StepTop';
import UploadRules from '../UploadRules/UploadRules';
import Modal from 'react-modal';
import {Translate} from "react-translated";
import Icon from "../global/Icon/Icon";
import Button from "../global/Button/Button";


export default class UploadRulesStep extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            modalIsOpen: true,
            checked:false,
        }
    }
    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };
    render() {
        return (
            <div className="uploadrulesstep">
                <StepTop title="file upload rules" step="2" projectId={this.props.match.params.id}/>
                <UploadRules match={this.props.match} />
                <div className="row">
                    <div className="column text-center">
                        <div className="privacy-check">
                            <input type="checkbox" id="privacy-checkbox" onChange={(e)=>{this.setState({checked:e.target.checked})}} checked={this.state.checked} value="on"/>
                            <label htmlFor="privacy-checkbox">
                               <Translate text="I confirm that I have read File Upload Rules of RendLine.com and accept them." />
                            </label>
                        </div>
                    </div>
                </div>
                <Modal
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(18, 25, 45, 0.8)',
                            zIndex:'8'     
                        },
                        content: {
                            width:500,
                            left:'calc(50% - 250px)',
                            bottom:'initial',
                            top:'calc(50% - 144px)',
                            backgroundColor:'#1c243e',
                            border:'0',
                            padding:'0'
                        }
                    }}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={true}
                    contentLabel="Example Modal"
                    >

                    <div className="modal-main">
                        <div className="attention-wrapper modal-attention text-center">
                            <Icon type="attention"/>
                            <div className="upload-title">
                                <Translate text="attention!" />
                            </div>
                            <div className="upload-description">
                                <Translate text='Please read "File Upload Rules" to learn about the file standards, otherwise the results of renderings can appear to be incorrect.!' />
                            </div>
                            <Button type="button"
                                    theme="purple"
                                    title="gotit!"
                                    onClick={this.closeModal}
                            />
                        </div>
                    </div>
                </Modal>
                <div className="row align-justify align-middle upload-step-actions">
                    <div className="column">
                        <Button type="button"
                                theme="dark"
                                iconLeft="arrow-left"
                                iconLeftSize={12}
                                title="back"
                                link={"/project/"+this.props.match.params.type+"/"+this.props.match.params.id+"/select-scene"}
                        />
                    </div>
                    <div className="column text-right">
                        <Button type="button"
                                theme="purple"
                                title="next"
                                iconRight="arrow-right"
                                iconRightSize={12}
                                disabled={!this.state.checked}
                                link={"/project/"+this.props.match.params.type+"/"+this.props.match.params.id+"/file-upload-step"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
