import React, { Component } from 'react';
import './Billing.scss';
import { Translate } from 'react-translated';
import PubSub from "pubsub-js";
import Api from "../Api";
import Global from '../Global';
import Loading from '../global/Loading/Loading'
import Button from "../global/Button/Button";
var moment = require('moment');


export default class Billing extends Component {
    constructor(props){
        super(props);
        this.state={
            project:{
                name:"",
                images:0,
                videos:0,
                price:0,
                loading:false,
                paid_images:0,
            },
            suggestion:{
              name:"",
            },
            loading:false,
            loadingBtn:false,
            type:false,
            balance:0,
            id:this.props.match.params.id || 0
        };
        this.loadData();
    }

    checkType=(event)=>{
        this.setState({
            type:event.target.value,
        })
    };

    loadData =()=>{
        Api.apiCall("get","getProject",{id:this.state.id}).then((resp)=>{

            if (resp.status !== 200) {
                //
            }
            if ('error' in resp.data) {
                //PubSub.publish("redirect.to","/");
            }else {
                //console.log(resp.data);
                if(resp.data.project.run === 2){
                    this.setState({
                        project:resp.data.project,
                        suggestion:resp.data.suggestion,

                    });
                    Api.apiCall("get","getMe", {})
                        .then(respx=>{
                            if (respx.status !== 200) {
                                // console.log(resp.status);
                            }
                            if ('error' in respx.data) {
                                // console.log(resp.data.error);
                            }else {
                                Global.me = respx.data;
                                this.setState({
                                    balance:Global.me.id === 366 || Global.me.id === 286 || Global.me.id === 317 || Global.me.id === 437 || Global.me.id === 456 || Global.me.id === 462 || Global.me.id === 463 || Global.me.id === 464 || Global.me.id === 470 || Global.me.id === 474 || Global.me.id === 475?0:resp.data.type?respx.data.video_count:respx.data.render_count,
                                    loading:true,
                                    type:(resp.data.paid_images - (resp.data.type?respx.data.video_count:respx.data.render_count)) > 0?false:true
                                })
                            }
                        });
                } else{
                   //PubSub.publish("redirect.to","/");
                }

            }
        })
    };
    purchase = ()=>{
        if(this.state.type){
            let peyImagex = this.state.project.images - this.state.balance;
            this.setState({loadingBtn:true});
            if(peyImagex>0){
                Api.apiCall("post","purchaseProject",{id:this.state.id,type:this.state.type}).then((resp)=>{
                    if (resp.status !== 200) {
                        // console.log(resp.status);
                        this.setState({loadingBtn:false});
                    }
                    if ('error' in resp.data) {
                        this.setState({loadingBtn:false});
                    }else {
                        window.location.replace('https://payments.ameriabank.am/forms/frm_paymentstype.aspx?lang=(Interface language)&paymentid='+resp.data.success);

                    }
                })
            } else{
                Api.apiCall("post","confirmProject",{id:this.state.id}).then((resp)=>{
                    if (resp.status !== 200) {
                        // console.log(resp.status);
                        this.setState({loadingBtn:false});
                    }
                    if ('error' in resp.data) {
                        this.setState({loadingBtn:false});
                    }else {
                        PubSub.publish('update.me');
                        PubSub.publish("redirect.to","/success-message/project");
                    }
                })
            }

        } else{
            PubSub.publish('toast.notif','Please select payment method');
        }

    };
    render(){
        let peyImage = this.state.project.images - this.state.balance;
        return(
            <div className="row align-spaced">
                <div className="column small-10">
                    <div className="row align-justify">
                        <div className="column billing-col">
                            <div className="text-center">
                                <div className="page-title">
                                    <Translate text="Purchase Confirmation" />
                                </div>
                            </div>
                            {!this.state.loading ?(
                                <Loading/>
                            ):(
                                <div>
                                    <div className="billing content-wrapper">
                                        <div className="billing-main">
                                            <div className="billing-table">
                                                <div className="table-in">
                                                    <div className="table-a">
                                                        <div>
                                                            {this.state.project.type?
                                                                <Translate text="videos" />
                                                                :
                                                                <Translate text="images" />
                                                            }
                                                        </div>
                                                        <div>
                                                            <Translate text="current balance" />
                                                        </div>
                                                        <div>
                                                            {this.state.project.type?
                                                                <Translate text="paid videos" />
                                                                :
                                                                <Translate text="paid images" />
                                                            }
                                                        </div>
                                                        <div>
                                                            <Translate text="total" />
                                                        </div>
                                                    </div>
                                                    <div className="table-b">
                                                        <div>
                                                            {this.state.project.images}
                                                        </div>
                                                        <div>
                                                            {this.state.balance}
                                                        </div>
                                                        <div>
                                                            {(this.state.project.images - this.state.balance)>0?(this.state.project.images - this.state.balance):0}
                                                        </div>
                                                        <div>
                                                            ${((this.state.project.images - this.state.balance)>0?(this.state.project.images - this.state.balance):0)*(this.state.project.type?18:3)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {peyImage > 0?
                                                <div className="payment-method">
                                                    <div className="payment-title">
                                                        <Translate text="payment method" />
                                                    </div>
                                                    <div className="payment-types">
                                                        <label className="payment-type-item">
                                                            <input type="radio" name="payment" value="1" onChange={this.checkType}/>
                                                            <span className="checkmark"/>
                                                            <div className="label-main">
                                                                <i>
                                                                    <img src={require("../../assets/img/credit-card.svg")} alt=""/>
                                                                </i>
                                                                <span>
                                                                    <Translate text="credit & debit cards" />
                                                                </span>
                                                            </div>
                                                        </label>
                                                        <label className="payment-type-item">
                                                            <input type="radio" name="payment" value="2" onChange={this.checkType} />
                                                            <span className="checkmark"/>
                                                            <div className="label-main">
                                                                <img src={require("../../assets/img/paypal.png")} alt=""/>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>:null
                                            }
                                        </div>
                                        {peyImage>0?
                                            <div className="billing-total-info">
                                                <div className="billing-date">
                                                    <i><img src={require("../../assets/img/date-icon.svg")} alt=""/></i>
                                                    <span>
                                                        {moment().lang('de').format("D MMM YYYY")}
                                                    </span>
                                                </div>
                                                <div className="total-billing-price">
                                                    <div className="text">
                                                        <Translate text="total price" />:
                                                    </div>
                                                    <div className="main">
                                                        ${peyImage*(this.state.project.type?18:3)}
                                                    </div>
                                                    <div className="price-per-image">
                                                        ${(this.state.project.type?18:3)}
                                                        /
                                                        {this.state.project.type?<Translate text="video" />:<Translate text="image" />}
                                                    </div>
                                                </div>
                                            </div>:null
                                        }
                                    </div>
                                    <div className="billing-controls">
                                        <Button type="button"
                                                theme="purple"
                                                iconLeft="arrow-left"
                                                title="back"
                                                link="/"
                                        />
                                        <Button type="button"
                                                theme="purple"
                                                title={peyImage > 0? "proceed to checkout" : "confirm project"}
                                                onClick={this.purchase}
                                                loading={this.state.loadingBtn}
                                                iconRight="arrow-right"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="column recommend-wrap">
                            <aside className="recommend-block">
                                <div className="recommend-title">
                                    <Translate text="recommended for you" />
                                </div>
                                <div className="plan-block text-center">
                                    <div className="plan-icon">
                                        <img src={require("../../assets/img/plan-mini.png")} alt=""/>
                                    </div>
                                    <div className="plan-block-main">
                                        <div className="plan-block-title">
                                            <Translate text={this.state.suggestion.name} />
                                        </div>
                                        <div className="plan-block-desc">
                                            {/*<Translate text="RendLine gives you an exceptional opportunity to" />*/}
                                        </div>
                                        <div className="plan-block-info">
                                            {this.state.suggestion.type === 1 ? this.state.suggestion.images : this.state.suggestion.videos}

                                            <Translate text={this.state.suggestion.type === 1 ? "   images" : "   videos"} />
                                        </div>
                                        <div className="plan-block-price">
                                            <sup>
                                                $
                                            </sup>
                                            {this.state.suggestion.price}
                                        </div>
                                        {/*<div className="saled-plan">*/}
                                            {/*+10 free renderings*/}
                                        {/*</div>*/}
                                        <Button type="button"
                                                theme="white-back"
                                                rounded={true}
                                                title="add project"
                                                link="/choose-plan"
                                        />
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}