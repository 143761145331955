import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './Button.scss';
import Icon from "../Icon/Icon";
import { Translate } from 'react-translated';


const Button = (props) => {
    return(
        <Fragment>
            {props.link ?
                <Link to={props.link} className={`btn btn-${props.theme} ${props.responsive ? "btn-responsive":""} ${props.disabled ? "btn-disabled":""} ${props.rounded ? "btn-rounded":""}`}>
                    {props.iconLeft ? (
                        <Icon type={props.iconLeft} size={props.iconLeftSize}/>
                    ):null}
                    {props.title ? (
                        <span>
                            <Translate text={props.title} />
                        </span>
                    ):null}
                    {props.iconRight ? (
                        <Icon type={props.iconRight} size={props.iconRightSize}/>
                    ):null}
                </Link>

                : props.file ?
                    <div className={`btn btn-${props.theme} ${props.responsive ? "btn-responsive":""}`}>
                        {props.loading ? (
                            <Icon type="loading"/>
                        ):(
                            <Fragment>
                                <input name={props.name} type="file" onChange={(e)=>props.onChange(e)}/>

                                {props.iconLeft ? (
                                    <Icon type={props.iconLeft} size={props.iconLeftSize}/>
                                ):null}
                                {props.title ? (
                                    <span>
                                        <Translate text={props.title} />
                                    </span>
                                ):null}
                                {props.iconRight ? (
                                    <Icon type={props.iconRight} size={props.iconRightSize}/>
                                ):null}
                            </Fragment>
                        )}
                    </div>
                    :
                    <button
                        className={`btn btn-${props.theme} ${props.responsive ? "btn-responsive":""} ${props.rounded ? "btn-rounded":""}`}
                        type={props.type}
                        onClick={props.type === "button" ? ()=> props.onClick() : ()=>{}}
                        disabled={props.disabled}>

                        {props.loading ? (
                            <Icon type="loading"/>
                        ):(
                            <Fragment>
                                {props.iconLeft ? (
                                    <Icon type={props.iconLeft} size={props.iconLeftSize}/>
                                ):null}
                                {props.title ? (
                                    <span>
                                        <Translate text={props.title} />
                                    </span>
                                ):null}
                                {props.iconRight ? (
                                    <Icon type={props.iconRight} size={props.iconRightSize}/>
                                ):null}
                            </Fragment>
                        )}
                    </button>
            }
        </Fragment>
    )
};

Button.propTypes = {
    link: PropTypes.string,
    type: PropTypes.string,
    theme: PropTypes.string.isRequired,
    file: PropTypes.bool,
    responsive: PropTypes.bool,
    rounded: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    iconLeft: PropTypes.string,
    iconLeftSize: PropTypes.number,
    iconRight: PropTypes.string,
    iconRightSize: PropTypes.number,
};

export default Button;